import { ExtraModelsFromLoading } from '@rematch/loading';
import {
	Models,
	RematchDispatch,
	RematchRootState
} from '@rematch/core';

import { app } from './App';
import { auth, Status } from './Auth';
import { admin } from './Admin';
import { athletes } from './Athletes';
import { requests } from './Requests';
import { notify } from './Notify';
import { data } from './Data';
import { tenants } from './Tenants';
import { rosters } from './Rosters';

export namespace Store {

	export interface General extends Models<General> {
		app: typeof app,
		auth: typeof auth,
		admin: typeof admin,
		athletes: typeof athletes,
		requests: typeof requests,
		notify: typeof notify,
		data: typeof data,
		tenants: typeof tenants,
		rosters: typeof rosters,
	};

	export type Full = ExtraModelsFromLoading<General>
	export type Root = RematchRootState<General, Full>
	export type Dispatch = RematchDispatch<General>

}

export const models: Store.General = {
	app,
	auth,
	admin,
	athletes,
	requests,
	notify,
	data,
	tenants,
	rosters,
};

export {
	Status
};

export * from './App';
