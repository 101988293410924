import { useCallback, useEffect, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useEscapeKeydown } from '@radix-ui/react-use-escape-keydown';

import { PhotoWrap, SquareIcon, VideoWrap } from '~/components';
import { getFileType } from '~/utils';
import { createPortal } from 'react-dom';

interface GalleryControlProps {
	onClick?: () => void,
	disabled?: boolean,
	direction?: 'prev' | 'next',
}

export const GalleryControl: React.FC<GalleryControlProps> = (props) => {

	const {
		onClick,
		disabled,
		direction = 'prev',
	} = props;

	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={`app-gallery__control control_${direction}`}>
			{/* <Icon name="leftChevron" /> */}
		</button>
	);

}

export interface GalleryProps {
	uri: string | File,
	visible?: boolean,
	onDismiss?: () => void,
}

export const Gallery: React.FC<GalleryProps> = (props) => {

	const { uri, visible, onDismiss } = props;

	const closeModal = useCallback(
		() => {
			onDismiss?.();
		},
		[ onDismiss ]
	);

	useEscapeKeydown(closeModal);

	useEffect(() => {

		document.body.style.overflow = visible ? 'hidden' : 'auto';

		return () => {
			document.body.style.overflow = 'auto';
		}

	}, [ visible ]);

	const [ type ] = useMemo(
		() => getFileType(typeof uri === 'string' ? uri : uri.type),
		[ uri ]
	);

	return createPortal(
		<CSSTransition
			in={visible}
			timeout={200}
			mountOnEnter
			unmountOnExit>
			<div className="app-gallery__modal">

				<SquareIcon
					icon="cross"
					plain
					onClick={closeModal}
					className="app-square-icon clickable app-gallery__minimize-button" />

				{type === 'image' &&
				<PhotoWrap
					source={uri} />
				}

				{type === 'video' &&
				<VideoWrap
					source={{ uri }} />
				}

			</div>
		</CSSTransition>,
		document.querySelector('#over')!
	);

}
