import { useCallback } from 'react';
import cn from 'classnames';

import { App } from '~/types';
import { pluralize } from '~/utils';

import { Icon } from './Icon';
import { Flag } from './Flag';
import { SquareIcon } from './SquareIcon';
import { IconParam } from './IconParam';

import colors from '~/assets/scss/vars.module.scss';

export interface RosterProps {
	data: App.Rosters.Roster,
	onClick?: (data: App.Rosters.Roster, e: React.MouseEvent) => void,
	selectable?: boolean,
	isSelected?: boolean,
}

export const Roster: React.FC<RosterProps> = (props) => {

	const { data, onClick, selectable, isSelected, } = props;

	const onRosterClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClick?.(data, e),
		[ data, onClick ]
	);

	const className = cn(
		'app--roster',
		{
			'app--roster_selected': isSelected,
		},
	);

	return (
		<div
			onClick={onRosterClick}
			className={className}>
			<div className="app--roster__inner">
				<SquareIcon
					icon="Whistle" />
				<div className="roster-content">
					<h4>{data.name}</h4>
					<IconParam icon={{ name: 'ServiceTrainingSession' }} text={pluralize(data.athletes_count, 'athlete')} />
				</div>
				<div className="roster-arrow">
					{!!selectable ?
					<Flag isSelected={isSelected} /> :
					<Icon
						fill={colors.darkGrey}
						name="arrowNext" />
					}
				</div>
			</div>
		</div>
	);

}
