import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { HTMLButton } from '~/types';

import { Icon } from './Icon';

type Direction = 'prev' | 'next'

interface PaginationButtonProps extends HTMLButton {
	label: string,
	primary?: boolean,
	loading?: boolean,
	direction?: Direction,
}

const PaginationButton: React.FC<PaginationButtonProps> = (props) => {

	const {
		label,
		primary,
		loading,
		direction,
		...button
	} = props;

	return (
		<button
			{...button}
			disabled={button.disabled || loading}
			className={cn('app--pagination-button', { [direction || '']: !!direction, 'disabled': !label })}>
			{direction === 'prev' && <Icon name="arrowBack" />}
			{!!label && <p children={loading ? 'Loading...' : label} />}
			{direction === 'next' && <Icon name="arrowNext" />}
		</button>
	);

}

type StepChangePayload = {
	prevented?: 'first' | 'last'
}

interface PaginationProps<T> {
	step: T,
	stepChange: (step: T, payload: StepChangePayload) => void,
	steps: T[],
	nextLabel?: string,
	prevLabel?: string,
	nextDisabled?: boolean,
	prevDisabled?: boolean,
	lastNextLabel?: string,
	firstPrevLabel?: string,
	beforeStepChange?: () => Promise<boolean | void>
}

export const Pagination = <T,>(props: PaginationProps<T>) => {

	const {
		step,
		stepChange,
		steps,
		prevLabel = 'Back',
		nextLabel = 'Next',
		lastNextLabel,
		firstPrevLabel,
		nextDisabled,
		prevDisabled,
		beforeStepChange,
	} = props;

	const [ loading, setLoading ] = useState<Direction>();

	const key = useMemo(() => steps.indexOf(step), [ steps, step ]);

	const isFirst = useMemo(
		() => key === 0,
		[ key ]
	);

	const isLast = useMemo(
		() => key === steps.length - 1,
		[ key, steps ]
	);

	const onChange = useCallback(async (dir: Direction) => {

		const _key = steps.indexOf(step);

		if (dir === 'next' && beforeStepChange) {

			setLoading(dir);

			const res = await beforeStepChange();

			if (typeof res === 'boolean' && !res) {
				return void setLoading(undefined);
			} else if (!res) {
				return;
			}

			setLoading(undefined);

		}

		if (
			(dir === 'prev' && isFirst) ||
			(dir === 'next' && isLast)
		) {
			return stepChange(steps[_key], {
				prevented: isLast ? 'last' : 'first'
			});
		}

		stepChange(steps[dir === 'next' ? _key + 1 : _key - 1], {});

	}, [
		beforeStepChange,
		stepChange,
		isFirst,
		isLast,
		steps,
		step,
	]);

	const Dots = useMemo(
		() => {
			return new Array(steps.length).fill(0).map((_, i) => (
				<div
					key={i}
					className={cn('app--pagination-dot', { selected: key === i })} />
			));
		},
		[ steps, key ]
	);

	return (
		<div className="app--pagination">
			<div className="app--pagination--inner">
				<PaginationButton
					label={isFirst && typeof firstPrevLabel !== 'undefined' ? firstPrevLabel : prevLabel}
					primary={isLast}
					onClick={() => onChange('prev')}
					loading={loading === 'prev'}
					disabled={!!loading || prevDisabled}
					direction="prev" />
				<div
					className="app--pagination-dots"
					children={Dots} />
				<PaginationButton
					label={isLast && typeof lastNextLabel !== 'undefined' ? lastNextLabel : nextLabel}
					primary={isLast}
					onClick={() => onChange('next')}
					loading={loading === 'next'}
					disabled={!!loading || nextDisabled}
					direction="next" />
			</div>
		</div>
	);

}
