import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useEscapeKeydown } from '@radix-ui/react-use-escape-keydown';

import { Arrayable } from '~/types';
import { asArray, fromArray } from '~/utils';

import { Title } from './Title';
import { Icon, IconArgs, asIcon } from './Icon';
import { SquareIcon } from './SquareIcon';
import { Loader } from './Loader';

export interface ModalProps {
	title?: Arrayable<string>,
	icon?: Arrayable<IconArgs>,
	layer?: number,
	visible?: boolean,
	lockHide?: boolean,
	noPadding?: boolean,
	className?: Arrayable<string>,
	isLoading?: boolean,
	isEnhanced?: boolean,
	onHide?: () => void,
	setLayer?: (layer?: number) => void,
}

export const Modal: React.FC<ModalProps> = (props) => {

	const {
		icon,
		title,
		layer = 0,
		visible,
		children,
		className,
		isLoading,
		isEnhanced,
		onHide,
		setLayer,
	} = props;

	useEscapeKeydown(() => {

		visible && onHide?.();

	});

	useEffect(() => {

		document.body.style.overflow = visible ? 'hidden' : 'auto';

		return () => {
			document.body.style.overflow = 'auto';
		}

	}, [ visible ]);

	const classNames = cn(
		'app-modal',
		asArray(className)[layer],
		{
			'app-modal_enhanced': isEnhanced,
		},
	);

	return createPortal(
		<CSSTransition
			in={visible}
			timeout={200}
			mountOnEnter
			unmountOnExit>
			<div onClick={(e) => e.stopPropagation()} className={classNames}>
				<div className="app-modal--wrap">
					{(!!title && !isEnhanced) &&
					<Title label={asArray(title)[layer]} />
					}
					<div className="app-modal--content">
						{(icon || onHide) && (
						<div className="app-modal--header">
							{!!onHide &&
							<SquareIcon
								icon={layer === 0 ? 'cross' : 'arrowBack'}
								onClick={layer === 0 ? onHide : setLayer?.bind(null, layer - 1)} />
							}
							{!!icon &&
							<Icon {...asIcon(asArray(icon)[layer])} />
							}
							{(!!title && isEnhanced) &&
							<h4>{asArray(title)[layer]}</h4>
							}
						</div>
						)}
						<div children={fromArray(children, layer)} />
						<Loader
							variant="absolute"
							loading={isLoading} />
					</div>
				</div>
				<div
					onClick={onHide}
					className="app-modal--bg" />
			</div>
		</CSSTransition>,
		document.querySelector('#over')!
	);

}
