import { toast } from 'react-toastify';

// eslint-disable-next-line
type Parameter<T> = T extends (arg: infer T) => any ? T : never;

export function toastConfirm<F extends (arg: any) => any>(
	fn: F,
	args: Parameter<F>,
	text: {
		pending: string,
		success: string,
	}
// ): ReturnType<F> {
) {

	return new Promise((res) => {

		toast.promise(
			new Promise(async (resolve, reject) => {

				const message = await fn(args);

				if (typeof message === 'string') {
					reject(message);
				}

				resolve(true);

				res(true);

			}),
			{
				error: { render: ({ data }) => data },
				...text,
			},
		);

	});

}
