import { useParams } from 'react-router';

import { Profile } from '~/containers/Athlete';

export const Athlete: React.FC = () => {

	const { id } = useParams<{ id: string }>();

	return <Profile athleteId={id} />

}
