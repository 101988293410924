import { useState } from 'react';

import { useAuth } from '~/store';
import { useBusinessStats } from '~/hooks';
import { Button, Loader } from '~/components';
import { BusinessStatus, BusinessStatusArray, REQUEST_TYPE } from '~/services';

import {
	PageContent,
	PageTitle,
	StatsHead,
	StatsBlock,
	BusinessTabs,
	ExportStatsModal,
} from '~/containers';

export const Stats: React.FC = () => {

	const { account } = useAuth();

	const { data, dataByType, loading, loaded } = useBusinessStats();

	const [ reportModal, setReportModal ] = useState(false);

	return (
		<>

			<PageTitle title="Toolbox" />

			<PageContent
				loading={!loaded && loading}
				loadingComponent={<Loader loading variant="absolute" />}
				pageContentHeader={<BusinessTabs />}
				pageContentClassName="page--stats">

				<StatsHead
					account={account} />

				<StatsBlock
					label="Total spent"
					amount={data.spent_total}
					amounts={[
						['Total accepted requests', data.accepted_rejected_total.accepted],
						['Total rejected requests', data.accepted_rejected_total.rejected],
					]} />

				<StatsBlock
					label="Requests by status"
					amounts={
						BusinessStatusArray.map((i) => [
							`${BusinessStatus[i]} requests`,
							`${data.requests_by_status[i]}`
						])
					} />

				<StatsBlock
					label="Requests by type"
					amounts={[
						['Request Type', 'Spent (Accepted/Rejected)'],
						...data.spent_by_request_types.map(({ type, amount }) => [
							`${REQUEST_TYPE[type][0]}`,
							`$${amount} (${dataByType[type][0]}/${dataByType[type][1]})`
						]) as [ string, string ][]
					]} />

				<StatsBlock
					label="Requests by athletes"
					amounts={
						data.spent_by_athletes.map(({ first_name, last_name, amount }) => [
							`${first_name} ${last_name}`,
							`$${amount}`
						])
					} />

				<Button
					label="Export report"
					onClick={setReportModal.bind(null, true)}
					loading={loading}
					variant="primary" />

			</PageContent>

			<ExportStatsModal
				onHide={setReportModal.bind(null, false)}
				account={account}
				visible={reportModal} />

		</>
	);

}
