import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { App } from '~/types';
import { getRelativeTime } from '~/utils';
import { Avatar } from '~/components';

import { ChatTicketManage } from './ChatTicketManage';

export interface TicketProps {
	index: number,
	ticket: Omit<App.Chat.Ticket, 'messages' | 'user_profiles'>,
}

export const Ticket: React.FC<TicketProps> = (props) => {

	const { index, ticket } = props;

	const [ visible, setVisible ] = useState(false);

	useEffect(
		() => {
			const to = setTimeout(
				() => setVisible(true),
				index * 10
			);

			return () => clearTimeout(to);
		},
		[ index ]
	);

	return (
		<CSSTransition
			in={visible}
			timeout={200}
			mountOnEnter
			unmountOnExit>
			<Link
				to={`/manage/chat/${ticket.id}`}
				className={cn('users-list--item users-list--ticket animated', { 'has-new-messages': ticket.badge > 0 })}>
				<Avatar
					src={ticket.profile_photo}
					alt={`${ticket.first_name} ${ticket.last_name}`}
					count={ticket.badge}
					countTitle="Unread messages count" />
				<div className="user-content">
					<h6 className="push-name">{ticket.id}</h6>
					<h4>{`${ticket.first_name} ${ticket.last_name}`}</h4>
					<p>{ticket.subject}</p>
					<div className="promo-tags">
						<div className="promo-tag used">{App.Chat.TICKET_STATUS_TEXT[ticket.status]}</div>
						<div className="promo-tag">{ticket.tenant}</div>
						{!!ticket.last_message_at &&
						<div className="promo-tag">{getRelativeTime(ticket.last_message_at)}</div>
						}
					</div>
				</div>
				<div className="user-actions">
					<ChatTicketManage
						ticket={ticket} />
				</div>
			</Link>
		</CSSTransition>
	);

}
