import { TenantConfig } from '~/services';

const FontQuicksand = {
	swap: true,
	weights: [400, 500, 600, 700],
	fontFamily: 'Quicksand',
}

const FontOpenSans = {
	...FontQuicksand,
	fontFamily: 'Open Sans',
}

const description = (name: string) =>
	`The ${name} APP allows fans, brands, and small businesses to connect with collegiate athletes to request video messages, live-chats, appearances and endorsements with athletes.`

const tenants: TenantConfig[] = [
	{
		domain: [ /localhost/i, /^pb\./i, /playbooked\.app$/i ],
		apiURL: [ 'pb.entersec.net', 'pb.entersec.net', 'api.playbooked.app' ],
		chatURL: [ 'pb.entersec.net', 'pb.entersec.net', 'api.playbooked.app' ],
		logo: {
			folder: 'pb',
			logoName: 'logo.svg',
		},
		font: FontQuicksand,
		appId: 1579552103,
		title: 'PlayBooked',
		colors: {
			bg: '#181818',
			primary: '#cc6202',
			secondary: '#036379',
		},
		iconSet: 0,
		features: {
			pushes: true,
			syncAll: true,
			promoCodes: true,
			tenantsPage: true,
			adminProfileDelete: true,
		},
		description: description('PlayBooked'),
		supportEmail: 'info@playbooked.com',
	},
	{
		domain: [ /^pb-(sm|stadium)\./i, /stadiumandmain.org$/i ],
		apiURL: [ 'pb-stadium.entersec.net', 'app.stadiumandmain.org' ],
		chatURL: [ 'pb.entersec.net', 'api.playbooked.app' ],
		logo: {
			folder: 'pb-sm',
			logoName: 'logo.svg',
		},
		font: FontOpenSans,
		skin: [
			'isRound',
			'isProminent',
			'isCenteredTabs',
			'isReducedBorders',
			'isReducedShadows',
			'isFloatingLegendTitle',
		],
		appId: 1579552103,
		title: 'Stadium Main',
		colors: {
			bg: '#0d0f11',
			primary: '#fecd39',
			secondary: '#04386a',
		},
		iconSet: 1,
		features: {
			pushes: true,
			promoCodes: true,
		},
		description: description('Stadium Main'),
		supportEmail: 'info@stadiumandmain.org',
	},
	{
		domain: [ /^(pb-gt|stage\.goldentouch)\./i, /goldentouch.world$/i ],
		apiURL: [ 'pb-gt.entersec.net', 'app.goldentouch.world' ],
		chatURL: [ 'pb.entersec.net', 'api.playbooked.app' ],
		logo: {
			folder: 'pb-gt',
			logoName: 'logo.svg',
		},
		font: FontOpenSans,
		skin: [
			'isSquare',
			'isProminent',
			'isJointInput',
			'isCenteredTabs',
			'isReducedBorders',
			'isReducedShadows',
			'isJointRequestCard',
			'isFloatingLegendTitle',
		],
		appId: 1579552103,
		title: 'Golden Touch',
		colors: {
			bg: '#0e0e0e',
			primary: '#bf862f',
			secondary: '#1c3150',
		},
		iconSet: 1,
		features: {
			pushes: true,
			promoCodes: true,
		},
		description: description('Golden Touch'),
		supportEmail: 'info@goldentouch.world',
	},
	{
		domain: [ /^pb-(cl|cal)\./i, /calegends.com$/i ],
		apiURL: [ 'pb-cal.entersec.net', 'app.calegends.com' ],
		chatURL: [ 'pb.entersec.net', 'api.playbooked.app' ],
		logo: {
			folder: 'pb-cl',
			logoName: 'logo.png',
		},
		font: FontOpenSans,
		skin: [
			'isSquare',
			'isSkewed',
			'isProminent',
			'isCenteredTabs',
			'isReducedShadows',
			'isReducedBorders',
			'isSkewedInput',
			'isJointRequestCard',
			'isFloatingLegendTitle',
		],
		title: 'Cal Legends',
		colors: {
			bg: '#11171d',
			primary: '#ffc90c',
			secondary: '#003b75',
		},
		iconSet: 1,
		features: {
			promoCodes: true,
		},
		description: description('Cal Legends'),
		supportEmail: 'info@calegends.com',
	},
	{
		domain: [ /^(pb-mc|stage\.minnesotanilmarketplace)\./i, /minnesotanilmarketplace.com$/i ],
		apiURL: [ 'stage.minnesotanilmarketplace.com', 'app.minnesotanilmarketplace.com' ],
		chatURL: [ 'pb.entersec.net', 'api.playbooked.app' ],
		logo: {
			folder: 'pb-mc',
			logoName: 'logo.svg',
			faviconName: 'favicon.jpeg',
		},
		font: FontOpenSans,
		skin: [
			'isSquare',
			'isProminent',
			'isCenteredTabs',
			'isReducedShadows',
			'isReducedBorders',
			'isSkewedInput',
			'isJointRequestCard',
			'isFloatingLegendTitle',
		],
		title: 'Minnesota NIL Marketplace',
		colors: {
			bg: '#0f0f0f',
			primary: '#fcb93f',
			secondary: '#da9a15',
			buttonPrimary: ['#fcb93f','#da9a15'],
			buttonSecondary: ['#4c0318','#83062a'],
		},
		iconSet: 1,
		features: {
			promoCodes: true,
		},
		description: description('Minnesota Collective'),
		supportEmail: 'info@minnesotanilmarketplace.com',
	},
	{
		domain: [ /^(pb-otv|stage\.ontovictory)\./i, /ontovictory.com$/i ],
		apiURL: [ 'stage.ontovictory.com', 'app.ontovictory.com' ],
		chatURL: [ 'pb.entersec.net', 'api.playbooked.app' ],
		logo: {
			folder: 'pb-otv',
			logoName: 'logo.png',
		},
		font: FontOpenSans,
		skin: [
			'isSquare',
			'isProminent',
			'isCenteredTabs',
			'isReducedShadows',
			'isReducedBorders',
			'isSkewedInput',
			'isJointRequestCard',
			'isFloatingLegendTitle',
		],
		title: 'On To Victory',
		colors: {
			bg: '#0f0f0f',
			primary: '#ff6a04',
			secondary: '#da9a15',
			buttonPrimary: ['#ff6a04','#bf3a01'],
			buttonSecondary: ['#02244d','#011d3d'],
		},
		iconSet: 1,
		features: {
			promoCodes: true,
		},
		supportEmail: 'info@ontovictory.com',
	},
	{
		domain: [ /^(pb-vl|stage\.vicktorylegends)\./i, /vicktorylegends.com$/i ],
		apiURL: [ 'stage.vicktorylegends.com', 'app.vicktorylegends.com' ],
		chatURL: [ 'pb.entersec.net', 'api.playbooked.app' ],
		logo: {
			folder: 'pb-vl',
			logoName: 'logo.png',
		},
		font: FontOpenSans,
		skin: [
			'isSquare',
			'isProminent',
			'isCenteredTabs',
			'isReducedShadows',
			'isReducedBorders',
			'isJointInput',
			'isJointRequestCard',
			'isFloatingLegendTitle',
		],
		title: 'Vicktory Legends',
		colors: {
			bg: '#141414',
			primary: '#ff6a04',
			secondary: '#83052a',
			buttonPrimary: ['#ff6a04','#bf3b01'],
			buttonSecondary: ['#83052a','#4c0418'],
		},
		iconSet: 1,
		features: {
			promoCodes: true,
		},
		description: description('Vicktory Legends'),
		supportEmail: 'info@victorylegends.com',
	},
	{
		domain: [ /^(pb-i1|stage\.theicon1901)\./i, /theicon1901.com$/i ],
		apiURL: [ 'stage.theicon1901.com', 'app.theicon1901.com' ],
		chatURL: [ 'pb.entersec.net', 'api.playbooked.app' ],
		logo: {
			folder: 'pb-i1',
			logoName: 'logo.png',
		},
		font: FontOpenSans,
		skin: [
			'isSquare',
			'isProminent',
			'isCenteredTabs',
			'isReducedShadows',
			'isReducedBorders',
			'isSkewedInput',
			'isJointRequestCard',
			'isFloatingLegendTitle',
		],
		title: 'Icon1901',
		colors: {
			bg: '#141313',
			primary: '#998357',
			secondary: '#4f4825',
			buttonPrimary: ['#4f4825','#998357'],
			buttonSecondary: ['#221d1f','#5a5a5a'],
		},
		iconSet: 1,
		features: {
			promoCodes: true,
		},
		description: description('Icon1901'),
		supportEmail: 'info@theicon1901.com',
	},
];

export default tenants;
