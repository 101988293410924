import { LocationType } from '~/services';

import { SquareIcon } from './SquareIcon';

interface MapLocationProps extends LocationType {
	loading?: boolean,
	onRemove?: () => void
}

export const MapLocation: React.FC<MapLocationProps> = (props) => {

	const { name, radius, loading, onRemove } = props;

	return (
		<div className="app--map-location">
			<SquareIcon
				icon="mapPin" />
			<div className="app--map-location-content">
				<p className="name" children={name} />
				<p className="radius" children={`${radius} mile radius`} />
			</div>
			{onRemove && (
			<SquareIcon
				icon="cross"
				loading={loading}
				onClick={onRemove} />
			)}
		</div>
	);

}
