import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { App } from '~/types';
import { lang, Route } from '~/constants';
import { useRosters } from '~/store';
import { Button, Input, Modal, Roster } from '~/components';

interface CreateCampaignModalProps {
	isVisible: boolean,
	setVisile: (state: boolean) => void
}

enum Step {
	'initial',
	'roster',
}

export const CreateCampaignModal: React.FC<CreateCampaignModalProps> = (props) => {

	const { isVisible, setVisile } = props;

	const { push } = useHistory();

	const [ step, setStep ] = useState(Step.initial);

	const { data, loading } = useRosters();

	const [ roster, setRoster ] = useState<App.Rosters.Roster>();

	const [ rosterName, setRosterName ] = useState('');

	const onCreate = useCallback(
		() => {

			setVisile(false);

			push('/request-form/b/endorsement_campaign');

		},
		[ setVisile, push ]
	);

	const onRoster = useCallback(
		() => setStep(Step.roster),
		[]
	);

	const onRosterSelected = useCallback(
		() => {
			roster && push(`/request-form/${roster.id}/endorsement_campaign`);
		},
		[ roster, push ]
	);

	return (
		<Modal
			icon={[ 'fancyQuestionMark', 'fancyQuestionMark' ]}
			title={[ 'Create campaign', 'Select a roster' ]}
			layer={step}
			onHide={setVisile.bind(null, false)}
			visible={isVisible}
			setLayer={setStep as any}
			isLoading={step === Step.roster && loading.readRosters}
			className={[ 'campaign--modal', 'campaign--modal--roster' ]}>

			<>
				<p
					className="message"
					children={lang.CAMPAIGN_MODAL_TEXT} />
				<Button
					label="Create"
					onClick={onCreate}
					variant="primary" />
				<Button
					label="Select a roster"
					onClick={onRoster}
					variant="secondary" />
				<Button
					label="Cancel"
					onClick={setVisile.bind(null, false)} />
			</>

			{!!data.length ?
			<div>
				{data.map((item, i) =>
				<Roster
					key={i}
					data={item}
					onClick={setRoster}
					selectable
					isSelected={item.id === roster?.id} />
				)}
				<div className="roster--confirm">
					<Button
						label="Continue"
						variant="primary"
						onClick={onRosterSelected}
						disabled={!roster}
						disabledDeep/>
				</div>
			</div> :
			<div>
				<p className="message">{lang.ROSTERS_EMPTY_LIST}</p>
				<p className="message">{lang.ROSTER_CREATE_TEXT}</p>
				<Input
					icon="Whistle"
					value={rosterName}
					onChange={({ target }) => setRosterName(target.value)}
					placeholder={lang.ROSTERS_NAME_PLACEHOLDER} />
				<Button
					label={lang.ROSTERS_CREATE_BUTTON}
					variant="primary"
					onClick={() => push(Route.BusinessRosterCreate, { name: rosterName })}
					disabled={!rosterName.length}
					disabledDeep />
			</div>
			}

		</Modal>
	);

}
