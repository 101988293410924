import cn from 'classnames';

import { Title } from './Title';
import { Icon, IconArgs } from './Icon';
import { asIcon } from '../utils';

interface WrapperProps {
	icon?: IconArgs,
	title: string,
	className?: string
}

export const Wrapper: React.FC<WrapperProps> = (props) => {

	const { icon, title, className, children } = props;

	return (
		<div className={cn('app--wrapper', className)}>
			<Title label={title} />
			<div className="app--wrapper-inner">
				{icon && (
				<div className="inner-head">
					<Icon {...asIcon(icon)} />
				</div>
				)}
				{children}
			</div>
		</div>
	);

}
