import cn from 'classnames';
import React from 'react';

import { IAthlete } from '~/services';
import { iconResolver, mediaLink } from '~/utils';
import { Icon } from './Icon';

interface AthleteCardProps {
	athlete: IAthlete,
	isLarge?: boolean,
	hideRate?: boolean,
	isSelected?: boolean,
	onClick?: () => void,
	onSelect?: (athlete: IAthlete, e: React.MouseEvent) => void
}

export const AthleteCard: React.FC<AthleteCardProps> = (props) => {

	const { athlete, hideRate, isSelected, onClick, onSelect } = props;

	const classNames = cn(
		'app--athlete-card',
		{
			selected: isSelected,
			featured: athlete.featured,
		},
	);

	return (
		<div onClick={onClick} className={classNames}>
			<div className="__photo"
				style={{
					backgroundImage: `url(${mediaLink(athlete.profile_photo, 'M')})`
				}} />
			<div className="__sport">
				<i children={iconResolver({
					id: athlete.sports_played,
					key: 'sport',
				})} />
			</div>
			{onSelect && (
			<>
				{!hideRate &&
				<div className={cn('__price', { 'is-legend': !!athlete.legend })}>
					<p>
						<span>${athlete.endorsement_rate}</span>
						{!!athlete.legend &&
						<Icon name="legend" />
						}
					</p>
				</div>
				}
				<button
					onClick={(e) => onSelect(athlete, e)}
					className={cn('__check', { checked: isSelected })}>
					<Icon name="flag" />
				</button>
			</>
			)}
			{!onSelect && athlete.legend &&
			<div className="__legend">
				<Icon
					name="legend" />
			</div>
			}
			<div className="__inner">
				<div>
					<h4>{athlete.first_name} {athlete.last_name}</h4>
					<p className="athlete-school">{athlete.school_name}</p>
				</div>
			</div>
		</div>
	);

}
