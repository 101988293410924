import { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useChats } from '~/store';
import { Avatar } from '~/components';
import { Container, ChatFrame, ChatTicketManage, ContainerError } from '~/containers/manage';

export const Chat: React.FC = () => {

	const { id } = useParams<{ id: string }>();

	const messagesRef = useRef<HTMLDivElement>(null);

	const { ticket } = useChats(id);

	if (!ticket) {
		return (ticket === null) ?
			<ContainerError
				title="The ticket is not found"
				content="The ticket you've requested is not found"
				onGoBack="/manage/chats" /> :
			null
	}

	return (
		<Container
			title={
				<>
					<Avatar src={ticket.profile_photo} />
					<p>Conversation with</p>
				</>
			}
			refBody={messagesRef}
			onGoBack="/manage/chats"
			className="chat"
			titleContent={[
				`/manage/user/${ticket.user_id}`,
				`${ticket.first_name} ${ticket.last_name}`
			]}
			pageControls={<ChatTicketManage ticket={ticket} />}>
			<ChatFrame
				ticket={ticket}
				messagesRef={messagesRef} />
		</Container>
	);

}
