export const langManage = {
	pushes: {
		TEXT_LEGEND: 'Fill push',
		DEST_LEGEND: 'Destination',
		DEST_PROMPT: 'Choose how would you like to select users',
		DEST_ROLES: 'Select roles which users will get the notification',
		DEST_CONFIRM: 'You are going to send push notification to',
		ROLE_LEGEND: 'Select by role',
		DEST_CONFIRM_ROLES: 'users with following roles:',
		PLACEHOLDER_NAME: 'Push name',
		PLACEHOLDER_SUBJECT: 'Push subject',
		PLACEHOLDER_MESSAGE: 'Push message',
		SELECT_ROLES: 'Select by roles',
		SELECT_CERTAIN: 'Select certain users',
		SELECT_MIN_USERS: 'Select users first',
		SELECT_MIN_ROLES: 'Select at least one role',
		PUSH_PREVIEW_LEGEND: 'Push preview',
		PUSH_DESTINATION_LEGEND: 'Push destination',
	},
	promoCodes: {
		BLANK: '',

		NOTE_CODE: 'Unique promo code text',
		PLACEHOLDER_CODE: 'Code',

		NOTE_DISCOUNT_TYPE: 'Discount type: flat or percentage',
		PLACEHOLDER_DISCOUNT_TYPE: 'Select discount type',

		NOTE_DISCOUNT_AMOUNT: 'Promo code amount (flat or percentage)',
		PLACEHOLDER_DISCOUNT_AMOUNT: 'Discount amount or percentage value',

		NOTE_DISCOUNT_EXPIRATION_DATE: 'Promo code expiration date',
		PLACEHOLDER_DISCOUNT_EXPIRATION_DATE: 'Expiration date',

		NOTE_TOTAL_LIMIT_AMOUNT: 'Promo code total usage amount limit',
		PLACEHOLDER_TOTAL_LIMIT_AMOUNT: 'Total usage amount limit',

		NOTE_USAGE_LIMIT: 'Usage count limit',
		PLACEHOLDER_USAGE_LIMIT: 'Usage count limit',

		NOTE_USAGE_LIMIT_PER_USER: 'Usage count limit per user',
		PLACEHOLDER_USAGE_LIMIT_PER_USER: 'Usage count limit per user',
	},
} as const;
