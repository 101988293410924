export namespace User {

	export const user = 'USER';

	export interface Athlete {
		id: string,
		sports: string,
		legend: boolean,
		featured: boolean,
		username: string,
		school_id: string,
		last_name: string,
		first_name: string,
		school_name: string,
		profile_photo: string,
		sports_played: string,
		endorsement_rate: number,
		endorsement_enabled: boolean,
	}

}
