import { useCallback } from 'react';
import cn from 'classnames';

import { App } from '~/types';
import { getRelativeTime } from '~/utils';

import { Icon, IconKey } from './Icon';
import { IconParam } from './IconParam';
import { SquareIcon } from './SquareIcon';

import colors from '~/assets/scss/vars.module.scss';

const statusMap: Record<App.Chat.TicketStatus, IconKey> = {
	new: 'sandClock',
	open: 'sandClock',
	closed: 'flag',
	waiting_response: 'sandClock',
}

export interface TicketProps {
	data: App.Chat.TicketStripped,
	onClick?: (data: App.Chat.TicketStripped, e: React.MouseEvent) => void,
}

export const Ticket: React.FC<TicketProps> = (props: TicketProps) => {

	const { data, onClick, } = props;

	const onTicketClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClick?.(data, e),
		[ data, onClick ]
	);

	const className = cn(
		'app--ticket',
		{
			'app--ticket_has-unread': !!data.badge,
			[`app--ticket_${data.status}`]: data.status,
		}
	);

	return (
		<div
			onClick={onTicketClick}
			className={className}>
			<div className="app--ticket__inner">
				<SquareIcon
					icon="MenuRequests" />
				<div className="ticket-content">
					{!!data.badge &&
					<p className="unread-count">{data.badge} unread message{data.badge > 1 ? 's' : ''}</p>
					}
					<h4>{data.subject}</h4>
					<IconParam icon={{ name: statusMap[data.status] }} text={App.Chat.TICKET_STATUS_TEXT[data.status]} />
					<IconParam icon={{ name: 'calendar' }} text={getRelativeTime(data.last_message_at || data.created_at)} />
				</div>
				<div className="ticket-icon" title={App.Chat.TICKET_STATUS_TEXT[data.status]}>
					<Icon name={statusMap[data.status]} />
				</div>
				<div className="ticket-arrow">
					<Icon
						fill={colors.darkGrey}
						name="arrowNext" />
				</div>
			</div>
		</div>
	);

}
