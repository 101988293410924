import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { toTitleCase } from './titleCase';

dayjs.extend(relativeTime);

export const daysLeft = (val: number): string => {

	return toTitleCase(
		dayjs().to(dayjs(val * 1000), true),
		true
	);

}
