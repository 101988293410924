import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useDebounce } from 'use-debounce';
import { validate } from 'uuid';

import { App, } from '~/types';
import { Admin } from '~/services';
import { Avatar, Loader, Search, SquareIcon } from '~/components';

const emailRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'i');

type Action =
	{ field: 'user_id', value: App.Chat.User } |
	{ field: 'subject', value: string } |
	{ field: 'ticket_id', value: string }

type SearchResult =
	App.Chat.User |
	App.Chat.TicketStripped

export interface ChatManageSearchProps {
	onChange?: (action: Action) => void,
}

const initialField = {
	value: '',
	isFocused: false,
	isValueUUID: false,
	isValueEmail: false,
}

export const ChatManageSearch: React.FC<ChatManageSearchProps> = (props) => {

	const { onChange } = props;

	const [ isLoading, setIsLoading ] = useState(false);

	const [ searchResult, setSearchResult ] = useState<SearchResult[]>([]);

	const [ field, setField ] = useState(initialField);

	const [ fieldValue, fieldValueControls ] = useDebounce(field.value, 250);

	const isGlobalLoading = useMemo(
		() => isLoading || fieldValueControls.isPending(),
		[ isLoading, fieldValueControls ]
	);

	const search = useCallback(
		async (value: string) => {

			if (!value) {
				return;
			}

			setIsLoading(true);

			const isUUID = validate(value);

			if (isUUID) {
				const { tickets } = await Admin.Chats.readManage({
					ticket_id: value,
					archived: true,
				}).promise;
				setSearchResult(tickets);
			}

			if (!isUUID) {
				const { users } = await Admin.Chats.readUsers(
					value
				).promise;
				setSearchResult(users);
			}

			setIsLoading(false);

		},
		[]
	);

	useEffect(
		() => void search(fieldValue),
		[ search, fieldValue ]
	);

	const onSearch = useCallback(
		(value: string) => {

			const isUUID = validate(value);
			const isEmail = emailRegex.test(value);

			setSearchResult([]);

			setField((val) => ({
				...val,
				value,
				isValueUUID: isUUID,
				isValueEmail: isEmail,
			}));

		},
		[],
	);

	const onItemClick = useCallback(
		(value: string | SearchResult) => {

			setField({ ...initialField, });

			if (typeof value === 'string') {
				return onChange?.({ field: 'subject', value, });
			}

			if ('phone' in value) {
				return onChange?.({ field: 'user_id', value, });
			}

		},
		[ onChange ]
	);

	return (
		<div className="app--manage-search">

			<div className="app--manage-search__inner">
				<Search
					value={field.value}
					onBlur={() => setField((val) => ({ ...val, isFocused: false, }))}
					onFocus={() => setField((val) => ({ ...val, isFocused: true, }))}
					loading={isGlobalLoading}
					onSearch={onSearch}
					placeholder="Search by user, ticket topic or ID" />
			</div>

			<CSSTransition
				in={field.isFocused}
				timeout={200}
				mountOnEnter
				unmountOnExit>
				<div className="app--manage-search__results">

					{!field.value &&
					<p className="empty-result">Start typing....</p>
					}

					{
						((field.isValueUUID || field.isValueEmail) &&
						!searchResult.length &&
						!isGlobalLoading) &&
						<p className="empty-result">No search result</p>
					}

					{((field.isValueUUID || field.isValueEmail) && isGlobalLoading) &&
					<Loader
						loading
						variant="absolute" />
					}

					{(!!field.value && !field.isValueUUID && !field.isValueEmail) &&
					<div
						onClick={onItemClick.bind(null, field.value)}
						className="result-option">
						<SquareIcon icon="arrowNext" />
						<div className="option-content">
							<h4>Get tickets with "<b>{field.value}</b>"</h4>
							<p>Click here to open search results</p>
						</div>
					</div>
					}

					{(!!searchResult.length && !!field.value && !field.isValueEmail && !field.isValueUUID) &&
					<div className="results-separator" />
					}

					{!!field.value && searchResult.map((item) =>
						'phone' in item ?
						<div
							key={item.id}
							onClick={onItemClick.bind(null, item)}
							className="result-option">
							<Avatar
								src={item.profile_photo} />
							<div className="option-content">
								<h4 title={`${item.first_name} ${item.last_name}`}>{item.first_name} {item.last_name}</h4>
								<p title={item.email}>{item.email}</p>
							</div>
						</div> :
						<Link
							to={`/manage/chat/${item.id}`}
							key={item.id}
							className="result-option">
							<Avatar
								src={item.profile_photo}
								count={item.badge} />
							<div className="option-content">
								<h4>Open ticket "<b>{item.subject.slice(0, 50)}{item.subject.length >= 50 ? '...' : ''}</b>"</h4>
								<p>Click here to open ticket</p>
							</div>
						</Link>
					)}

				</div>
			</CSSTransition>

		</div>
	);

}
