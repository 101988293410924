import { useCallback, useEffect, useRef } from 'react';
import { EventSourcePolyfill as EventSource, EventListener } from 'event-source-polyfill';
import Cookies from 'universal-cookie';

import { config } from '~/constants';

export interface UseSSEConfig<T> {
	url: string,
	skip?: boolean,
	onEvent?: (data: T) => void,
}

const cookies = new Cookies();

export function useSSE <T>({ url, skip, onEvent }: UseSSEConfig<T>) {
// export function useSSE <T>(
// 	url: string,
// 	skip?: boolean,
// 	onEvent?: (data: T) => void,
// ) {

	const src = useRef<EventSource | null>(null);

	const listener = useCallback<EventListener>(
		(e) => {

			if (e.type !== 'message') {
				return;
			}

			const message = e as MessageEvent;

			try {

				const data = JSON.parse(message.data) as T;

				onEvent?.(data);

			} catch {

			}

		},
		[ onEvent ]
	);

	const subscribe = useCallback(
		() => {

			// FIXME:
			function unsubscribe () {
				src.current?.removeEventListener('open', listener);
				src.current?.removeEventListener('error', listener);
				src.current?.removeEventListener('message', listener);
				src.current?.close();
				src.current = null;
			}

			const sid = cookies.get(config.API_COOKIE);

			if (!url || skip || !sid || sid.includes('GUEST')) {
				return unsubscribe;
			}

			const _src = src.current = new EventSource(url, {
				heartbeatTimeout: 180 * 1000,
				headers: {
					[config.API_TOKEN]: sid,
				},
			});

			_src.addEventListener('open', listener);
			_src.addEventListener('error', listener);
			_src.addEventListener('message', listener);

			return unsubscribe;

		},
		[ url, skip, listener ]
	);

	useEffect(
		() => {

			const unsubscribe = subscribe();

			return () => unsubscribe();

		},
		[ subscribe ]
	);

	return {}

}
