import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input';
import cn from 'classnames';

import { IconArgs } from './Icon';
import { SquareIcon } from './SquareIcon';

interface InputCodeProps extends ReactCodeInputProps {
	icon?: IconArgs | null,
	error?: boolean | string,
}

export const InputCode: React.FC<InputCodeProps> = (props) => {

	const { icon, error, fields = 6,  ...input } = props;

	return (
		<div className={cn('app--input-code', { error: !!error })}>
			{icon && (
			<SquareIcon icon={icon} />
			)}
			<div className="app--input-code-container">
				<ReactCodeInput
					type="password"
					fields={fields}
					className="app--input-code-element"
					{...input} />
				{!!error && typeof error === 'string' && <p className="error">{error}</p>}
			</div>
		</div>
	);

}
