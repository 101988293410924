import { useState } from 'react';
import { clipboardCopy } from '~/utils';

export interface ProfileUrlNoticeProps {
	username: string,
	isValid: boolean,
}

const TEXT = 'Personalize your profile with unique personal URL';

const { protocol, host } = window.location;

export const ProfileUrlNotice: React.FC<ProfileUrlNoticeProps> = (props) => {

	const { username, isValid } = props;

	const link = `${host}/@${username}`;

	const [ isCopied, setIsCopied ] = useState(false);

	const copy = async () => {

		await clipboardCopy(`${protocol}//${link}`);

		setIsCopied(true);

	}

	return (
		<p className="profile-url-notice">
			{!isValid ? TEXT : (
			<>
				{TEXT} <a target="_blank" rel="noreferrer" href={`${protocol}//${link}`}>{link}</a>
				<button onClick={copy} className="">{isCopied ? 'Copied!' : 'Copy'}</button>
			</>
			)}
		</p>
	);

}
