import cn from 'classnames';

import { Icon } from './Icon';

interface RatingProps {
	value: number,
	large?: boolean,
	onChange?: (value: number) => void,
}

export const Rating: React.FC<RatingProps> = (props) => {

	const { value, large, onChange } = props;

	const size = large ? { width: 45, height: 45 } : {}

	return (
		<div className={cn('app--rating', { dynamic: !!onChange })}>
			{new Array(5).fill(null).map((_, i) => (
			<div
				key={i}
				className={cn('rating--star', { selected: (i + 1) <= value })}>
				<Icon
					{...size}
					name="star"
					onClick={() => onChange && onChange(i + 1)} />
			</div>
			))}
		</div>
	);

}
