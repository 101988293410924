import { createModel } from '@rematch/core';

import { Store } from './';

import {
	Athlete,
	IAccountAthlete,
	IReview,
	IShoutout
} from '~/services';

interface AthleteStored {
	athlete: IAccountAthlete,
	reviews: IReview[],
	shoutouts: IShoutout[],
}

interface AthletesState {
	athletesById: Record<string, AthleteStored>,
}

const state: AthletesState = {
	athletesById: {},
};

export const athletes = createModel<Store.General>()({
	state,
	reducers: {
		insert: (state, payload: AthleteStored) => {

			state.athletesById[payload.athlete.id] = payload;

			return state;

		},
		clear: () => {
			return {
				athletesById: {},
			};
		},
	},
	effects: (dispatch) => ({
		retrieve: async ({ id, force }: { id: string, force?: boolean }, state) => {

			// if (!force && state.athletes.athletesById[id]) {
			// 	return;
			// }

			try {

				const payload = await Athlete.read({ id }).promise;

				dispatch.athletes.insert(payload);

			} catch (e) {}

		},
	}),
});
