import { App } from '~/types';
import { api } from '../';

const path = `/rosters`;

export const Rosters = {

	create: (data: App.Rosters.Endpoints.Create[0]) => api.post<App.Rosters.Endpoints.Create[1]>(path, data, { type: 'JSON' }),

	read: () => api.get<App.Rosters.Endpoints.Read[1]>(`${path}/list`),

	readById: (data: App.Rosters.Endpoints.ReadById[0]) => api.get<App.Rosters.Endpoints.ReadById[1]>(path, data),

	update: (data: App.Rosters.Endpoints.Update[0]) => api.put<App.Rosters.Endpoints.Update[1]>(path, data),

	delete: ({ id }: App.Rosters.Endpoints.Delete[0]) => api.del<App.Rosters.Endpoints.Delete[1]>(`${path}?id=${id}`),

	export: (data: App.Rosters.Endpoints.Export[0]) => api.get<App.Rosters.Endpoints.Export[1]>(`${path}/export`, data, { raw: true, }),

}
