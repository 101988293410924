import React, { useCallback, useMemo, useState } from 'react';
import { confirm } from '~/services';
import { Asset, getFileType } from '~/utils';
import { Gallery } from './Gallery';

import { PhotoWrap } from './PhotoWrap';
import { SquareIcon } from './SquareIcon';
import { VideoWrap } from './VideoWrap';

export interface AssetViewProps {
	asset: Asset,
	onPress?: (asset: Asset) => void,
	isLoading?: boolean,
	isDownloading?: boolean,
	onAssetDelete?: (asset: File) => void,
	onAssetDownload?: (asset: string) => void,
}

export const AssetView: React.FC<AssetViewProps> = (props) => {

	const {
		asset,
		onPress,
		isLoading,
		isDownloading,
		onAssetDelete,
		onAssetDownload,
	} = props;

	const [ type ] = useMemo(
		() => getFileType(
			typeof asset === 'string' ? asset : asset.type
		),
		[ asset ]
	);

	const [ preview, setPreview ] = useState(false);

	const onAssetPress = useCallback(
		() => {

			if (isLoading) {
				return;
			}

			setPreview(true);

			onPress?.(asset);

		},
		[ isLoading, asset, onPress ]
	);

	const onAssetRemove = useCallback(
		async () => {

			if (isLoading || typeof asset === 'string') {
				return;
			}

			if (await confirm({
				title: 'Action confirmation',
				message: 'Are you sure want to remove the file?',
			})) {
				onAssetDelete?.(asset);
			}

		},
		[ isLoading, asset, onAssetDelete ]
	);

	const onAssetLoad = useCallback(
		async () => {

			if (isLoading || isDownloading || typeof asset !== 'string') {
				return;
			}

			if (await confirm({
				title: 'Action confirmation',
				message: 'Are you sure want to download the file?',
			})) {
				onAssetDownload?.(asset);
			}

		},
		[ isLoading, isDownloading, asset, onAssetDownload ]
	);

	return (
		<>

			<Gallery
				uri={asset}
				visible={preview}
				onDismiss={setPreview.bind(null, false)} />

			<div className="app--grid-item app--asset-view">

				{!!onAssetDelete &&
				<SquareIcon
					icon="bin"
					onClick={onAssetRemove}
					loading={isLoading} />
				}

				{(!onAssetDelete && !!onAssetDownload) &&
				<SquareIcon
					icon="download"
					onClick={onAssetLoad}
					loading={isLoading} />
				}

				{type === 'image' &&
				<PhotoWrap
					source={asset}
					onClick={onAssetPress} />
				}

				{type === 'video' &&
				<VideoWrap
					source={{ uri: asset }}
					onClick={onAssetPress} />
				}

			</div>

		</>
	);

}

