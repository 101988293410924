import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

export const useSentPayouts = (page: number = 0) => {

	const [ sentPayouts, loading ] = useSelector(
		({ admin, loading }: Store.Root) => [
			admin.sentPayouts,
			loading.effects.admin.readSentPayouts
		] as const
	);

	const { admin } = useDispatch<Store.Dispatch>();

	useEffect(() => {

		admin.readSentPayouts({ page });

	}, [ admin, page ]);

	return {
		sentPayouts,
		...admin,
		loading,
	};

}
