import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { useAthlete } from '~/hooks';
import { useAuth } from '~/store';
import { lang } from '~/constants';
import { getActiveServices, RequestType } from '~/services';
import { AthleteHeader, AthleteReview, AthleteService, Legend, NotFound, PhotoWrap, Tabs, VideoWrap } from '~/components';
import { PageContent, PageTitle } from '../Page';
import { ModalAuthPrompt } from '../Modals';
import { getFileType } from '~/utils';

interface ProfileProps {
	athleteId: string,
	onGoBack?: () => void
}

const profileTabs = (name: string) => [
	{ label: 'Info' },
	{ label: 'Reviews' },
	{ label: 'History' },
];

export const Profile: React.FC<ProfileProps> = (props) => {

	const { athleteId, onGoBack } = props;

	const { push, goBack } = useHistory();

	const { isLoading, athlete, reviews, shoutouts } = useAthlete({ id: athleteId });

	const { account } = useAuth();

	const [ authPrompt, setAuthPrompt ] = useState(false);

	const [ tab, setTab ] = useState(0);

	const onServiceBuy = useCallback((type: RequestType) => {

		if (!athlete) {
			return null;
		}

		if (!account) {
			return setAuthPrompt(true);
		}

		push(`/request-form/${athlete.id}/${type}`);

	}, [ athlete, account, push ]);

	if (!isLoading && !athlete) {
		return (
			<NotFound
				title={lang.NOT_FOUND_ATHLETE_TITLE}
				message={lang.NOT_FOUND_ATHLETE_MESSAGE} />
		);
	}

	if (!athlete) {
		return null;
	}

	const { athlete_info: info } = athlete;
	const { athlete_services: services } = info;

	const Services = getActiveServices(services)
		.map(({ key, keys, amount, ...val }, i) => (
			<AthleteService
				key={i}
				amount={services[keys[1]]}
				onPress={account?.user_type !== 2 ? () => onServiceBuy(key) : undefined}
				disabled={!!onGoBack}
				{...val} />
		));

	const Reviews = reviews.map((review, i) => (
		<AthleteReview
			key={i}
			date={review.submitted_at}
			name={review.reviewer_name}
			photo={review.reviewer_image}
			rating={review.rate} />
	));

	const Shoutouts = shoutouts.map(({ video }, i) => {
		const [ type ] = getFileType(video);
		return type === 'video' ? (
			<VideoWrap
				key={i}
				source={{ uri: video }}
				watermark />
		) : (
			<PhotoWrap
				key={i}
				source={video} />
		);
	});

	return (
		<>
			<PageTitle
				title={`${athlete.first_name} ${athlete.last_name}`}
				onBack={onGoBack || goBack} />
			<PageContent
				pageContentClassName="page--athlete-profile">
				<AthleteHeader
					athlete={athlete} />
				<Tabs
					tab={tab}
					tabs={profileTabs(athlete.first_name)}
					onChange={setTab}>
					<div className="page--container">
						<Legend
							label={lang.SERVICES_PRICING}
							children={Services} />
					</div>
					<div
						className="page--container"
						children={
							Reviews.length ?
							Reviews :
							<p className="empty">{lang.NO_REVIEWS(athlete.first_name)}</p>
						} />
					<div
						className="page--container"
						children={
							Shoutouts.length ?
							Shoutouts :
							<p className="empty">{lang.NO_HISTORY(athlete.first_name)}</p>
						} />
				</Tabs>
			{!account && (
			<ModalAuthPrompt
				visible={authPrompt}
				onHide={() => setAuthPrompt(false)} />
			)}
			</PageContent>
		</>
	);

}
