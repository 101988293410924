import cn from 'classnames';
import { Link } from 'react-router-dom';

import { useGoBack } from '~/services';
import { Loader, SquareIcon } from '~/components';

export interface ContainerProps {
	className?: string | string[] | Record<string, boolean>,
	onGoBack?: string | boolean | (() => void),
	ref?: React.RefObject<HTMLDivElement>,
	refBody?: React.RefObject<HTMLDivElement>,
	title?: string | React.ReactNode,
	titleContent?: string | [string, string],
	pageMiddle?: React.ReactNode,
	pageControls?: React.ReactNode,
	isLoading?: boolean,
	loadingText?: string | [string, string],
}

export const Container: React.FC<ContainerProps> = (props) => {

	const {
		className,
		onGoBack,
		ref,
		refBody,
		title,
		titleContent,
		children,
		pageMiddle,
		pageControls,
		isLoading,
		loadingText,
	} = props;

	const goBack = useGoBack();

	const GoBack = onGoBack ?
		<SquareIcon
			icon="arrowBack"
			onClick={() => {

				if (typeof onGoBack === 'function') {
					return onGoBack();
				}

				if (typeof onGoBack === 'string') {
					return goBack(onGoBack);
				}

				return goBack();

			}} /> :
		undefined;

	const TitleContent = titleContent ?
		(
			Array.isArray(titleContent) ?
				<Link to={titleContent[0]} children={titleContent[1]} /> :
				<span>{titleContent}</span>
		) :
		undefined;

	return (
		<div ref={ref} className={cn('manage-content', { manage_overlay: !!isLoading }, className)}>
			<div className="page-header">
				<h1>{GoBack} {title} {TitleContent}</h1>
				{!!pageControls &&
				<div
					children={pageControls}
					className="page-controls" />
				}
			</div>
			{!!pageMiddle &&
			<div children={pageMiddle} className="page-middle" />
			}
			<div ref={refBody} children={children} className="page-body" />
			{typeof isLoading !== 'undefined' &&
			<div className="manage-overlay">
				<div className="content">
					<Loader loading />
					{Array.isArray(loadingText) ?
					<>
						<h2>{loadingText[0]}</h2>
						<h6>{loadingText[1]}</h6>
					</> :
					<>
						{!!loadingText && <h2>{loadingText}</h2>}
					</>
					}
				</div>
			</div>
			}
		</div>
	);

}
