import { api } from '../api';

import { RequestType } from './Request';

export interface IBusinessInfo {
	readonly user_id: string,
	name: string,
	email: string,
	about: string,
	phone: string,
	trusted: boolean,
	website: string,
	picture: string,
	description: string,
	category_list: string,
	single_line_address: string
}

export const BusinessStatus = {
	pending: 'Pending',
	accepted: 'Accepted',
	finished: 'Finished',
	cancelled: 'Cancelled',
	in_progress: 'In progress',
	pending_review: 'Pending review',
} as const;

export type BusinessStatusKeys = keyof typeof BusinessStatus;

export const BusinessStatusArray = Object.keys(BusinessStatus) as BusinessStatusKeys[];

type Athlete = {
	amount: number,
	first_name: string,
	last_name: string,
	athlete_id: string
}

export interface StatsScheme {
	counters: {
		spent_total: number,
		spent_by_athletes: Athlete[],
		requests_by_status: Record<keyof typeof BusinessStatus, number>,
		spent_by_request_types: { type: RequestType, amount: number }[],
		accepted_rejected_total: { type: 'total', accepted: number, rejected: number },
		accepted_rejected_by_type: { type: RequestType, accepted: number, rejected: number }[],
	},
	filename: string
}

export const Business = {

	status: {
		pending: 'Pending',
		accepted: 'Accepted',
		finished: 'Finished',
		cancelled: 'Cancelled',
		in_progress: 'In progress',
		pending_review: 'Pending review',
	} as const,

	getStats: (params: {
		send: boolean,
		email?: string,
		export?: 'csv' | 'pdf',
	}) => api.get<StatsScheme>('/booking/request/counters-business-export', params),

};
