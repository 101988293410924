import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { routes } from '~/constants';
import { ProtectedRouteProps } from '~/services';

export const useRoute = () => {

	const { pathname } = useLocation();

	const route = useMemo<ProtectedRouteProps | null>(
		() => {

			const _route = routes.filter(
				(data) => matchPath(pathname, data)
			)[0];

			return _route || null;

		},
		[ pathname ]
	);

	return { route };

}
