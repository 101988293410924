import { Control, Controller, FieldValues, Path, PathValue, UnpackNestedValue } from 'react-hook-form';
import { Switch } from './Switch';

export interface CSwitchProps<T> {
	name: Path<T>,
	note?: string,
	label: string,
	control: Control<T>,
	defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>,
}

export const CSwitch = <T extends FieldValues>(props: CSwitchProps<T>) => {

	const { name, note, label, control, defaultValue } = props;

	return (
		<div className="app-switch">
			<div className="app-switch--text">
				<p className="label">{label}</p>
				{!!note && <p className="note">{note}</p>}
			</div>
			<div className="app-switch--switch">
				<Controller
					name={name}
					render={({ field }) => (
						<Switch
							onClick={() => field.onChange(!field.value)}
							selected={field.value} />
					)}
					control={control}
					defaultValue={defaultValue} />
			</div>
		</div>
	);

}
