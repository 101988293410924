import cn from 'classnames';

import { HTMLButton } from '~/types';

import { Icon } from './Icon';

interface FlagProps extends HTMLButton {
	label?: string,
	className?: string,
	isVertical?: boolean,
	isSelected?: boolean
}

export const Flag: React.FC<FlagProps> = (props) => {

	const {
		label,
		className,
		isSelected,
		isVertical,
		children,
		...button
	} = props;

	const classNames = cn(
		'app--flag',
		className,
		{
			isSelected,
			'is-vertical': isVertical,
			text:
				(typeof children === 'string') ||
				(Array.isArray(children) && typeof children?.[0] === 'string'),
		},
	);

	return (
		<button
			className={classNames}
			{...button}>
			<div className="app--flag-icon">
				<div
					className="app--flag-check"
					children={<Icon name="flag" />} />
				<div
					className="app--flag-content"
					children={children} />
			</div>
			{label && (
			<p
				children={label}
				className="app--flag-label" />
			)}
		</button>
	);

}
