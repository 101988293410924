import * as Steps from '~/containers/SignUp';

import { StateResponse, DeepWritableObject } from '~/types';

import {
	DataType,
	LocationType,
	AppearanceType,
	ServiceType,
	IBusinessInfo,
	UserType,
	IAccount,
	SocialLinksKeys
} from '~/services';

export type StepType = keyof typeof Steps;

export const useSignUpSteps = (step: StepType, role?: UserType) => {

	if (!role) {
		return {
			steps: [],
			title: '',
			next: null,
			prev: null,
		};
	}

	const steps: [ StepType, string ][] = [
		[ 'SignUpPhoto', 'Setup profile' ],
		[ 'SignUpServices', 'Services' ],
	];

	if (role !== 12) {
		steps.pop();
	}

	const item = steps.filter(([i]) => i === step)[0];
	const key = steps.indexOf(item);

	return {
		steps,
		title: item[1],
		next: steps[key + 1]?.[0] || null,
		prev: steps[key - 1]?.[0] || null,
	};

}

export type SignUpFormProps = {
	step: StateResponse<StepType>,
	steps: ReturnType<typeof useSignUpSteps>,
	account: IAccount,
}

export type FormControl = {
	onSubmit?: () => Promise<boolean | void>,
	nextDisabled?: boolean,
}

export type StepProps = {
	data: StateResponse<SignUpData>,
	step: StateResponse<StepType>,
	form: StateResponse<FormControl>,
	steps: ReturnType<typeof useSignUpSteps>,
	account: IAccount,
}

export namespace SurveyType {
	export type Keys = Pick<SignUpData, 'school' | 'sport'>
	export type Item = NonNullable<Keys[keyof Keys]>[number]
	export type Step = keyof Keys;
	export type TextMap = Record<Step, {
		label: string,
		header: string,
		message: string
	}>
}

export type MediaState = {
	file?: string | File,
	error?: string
}

export type MediaStateArray = {
	file?: string | File[],
	error?: string
}

export interface SignUpData {
	email: string,
	first_name: string,
	last_name: string,
	profile_photo: string,
	intro_video: string,
	description: string,
	legend: boolean,
	gender: DataType.Gender[],
	school: DataType.School[],
	sport: DataType.Sport[],
	links: Record<SocialLinksKeys, string>,
	business: DeepWritableObject<IBusinessInfo>,
	appearance: Record<AppearanceType, boolean>,
	locations: LocationType[],
	services: Record<ServiceType, {
		enabled: boolean,
		rate: number
	}>
}

export const signUpData: SignUpData = {
	email: '',
	first_name: '',
	last_name: '',
	profile_photo: '',
	intro_video: '',
	description: '',
	legend: false,
	gender: [],
	school: [],
	sport: [],
	links: {
		tiktok_link: '',
		twitter_link: '',
		snapchat_link: '',
		facebook_link: '',
		instagram_link: '',
	},
	business: {
		name: '',
		email: '',
		about: '',
		phone: '',
		trusted: false,
		picture: '',
		website: '',
		description: '',
		category_list: '',
		single_line_address: '',
	},
	appearance: {
		sing: false,
		visit_churches: false,
	},
	locations: [],
	services: {
		speaking_engagement: {
			enabled: false,
			rate: 20,
		},
		personal_appearance: {
			enabled: false,
			rate: 200,
		},
		training_session: {
			enabled: false,
			rate: 20,
		},
		endorsement: {
			enabled: false,
			rate: 20,
		},
		video_chat: {
			enabled: false,
			rate: 100,
		},
		shoutout: {
			enabled: false,
			rate: 10,
		},
		camp: {
			enabled: false,
			rate: 20,
		},
	},
};
