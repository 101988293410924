import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';

import { Area } from '~/utils';
import { getCroppedImg } from '../utils';

import { Button } from './Button';

export interface CropProps {
	src: string,
	ratio: number,
	isAthlete?: boolean,
	onCancel: () => void
	onComplete?: (file: File[]) => void,
}

const RATIO = {
	square: [
		1 / 1,
		{ width: 1024, height: 1024, ratio: 1 },
		'Your profile photo',
	],
	rectangle: [
		8 / 11,
		{ width: 320, height: 440, ratio: 1.375 },
		'Dashboard photo',
	],
} as const;

type State = keyof typeof RATIO

export const Crop: React.FC<CropProps> = (props) => {

	const { src, ratio, isAthlete, onCancel, onComplete } = props;

	const [ loading, setLoading ] = useState(false);

	const [ zoom, setZoom ] = useState(1);

	const [ crop, setCrop ] = useState({ x: 0, y: 0 });

	const [ target, setTarget ] = useState<State>(
		isAthlete ? 'rectangle' : 'square'
	);

	const [ area, setArea ] = useState<Area | null>(null);

	const [ img, setImg ] = useState<File[]>([]);

	const onCropComplete = useCallback(
		(_, croppedAreaPixels) => {
			setArea(croppedAreaPixels);
		},
		[]
	);

	const onSubmit = useCallback(
		async () => {

			if (!area) {
				return;
			}

			const { width, height } = RATIO[target][1];

			setLoading(true);

			const file = await getCroppedImg({
				src,
				area,
				name: 'photo.jpg',
				dimension: { width, height },
			});

			setLoading(false);

			if (!file) {
				return;
			}

			if (!isAthlete) {
				return (onComplete && onComplete([ file ]));
			}

			if (!img.length) {

				setImg([ file ]);

				setZoom(1);

				setCrop({ x: 0, y: 0 });

				return setTarget('square');

			}

			return (onComplete && onComplete([ file, ...img ]));

		},
		[ src, area, isAthlete, target, img, onComplete ]
	);

	return (
		<div className="crop-modal">
			<p className="crop-message">{RATIO[target][2]}</p>
			<div className="crop-wrap">
				<Cropper
					image={src}
					crop={crop}
					zoom={zoom}
					aspect={RATIO[target][0]}
					objectFit={ratio > 1 ? 'horizontal-cover' : 'vertical-cover'}
					onZoomChange={setZoom}
					onCropChange={setCrop}
					onCropComplete={onCropComplete} />
				<i
					style={{ padding: ratio > 1 ?
						`${(ratio - 0.1) * 100}% 0 0` :
						`${(RATIO[target][1].ratio - 0.1) * 100}% 0 0` }}
					className="ar" />
			</div>
			<Button
				label={isAthlete && img.length === 0 ? 'Next' : 'Finish'}
				loading={loading}
				onClick={onSubmit}
				variant="primary" />
			<Button
				label="Cancel"
				onClick={onCancel} />
		</div>
	);

}
