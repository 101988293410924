import { createModel } from '@rematch/core';

import { Store } from './';

import { Notifications, INotification } from '~/services';

interface NotifyState {
	data: INotification[],
	cached: boolean,
}

const state: NotifyState = {
	data: [],
	cached: false,
};

export const notify = createModel<Store.General>()({
	state,
	reducers: {
		insert: (state, data: INotification[]) => {
			return {
				...state,
				cached: true,
				data: data.sort((a, b) => a.created_at > b.created_at ? -1 : 1),
			};
		},
		_append: (state, notification: INotification) => {
			return {
				...state,
				data: [ notification, ...state.data ],
			};
		},
		clear: () => {
			return {
				...state,
				data: [],
			};
		},
		remove: (state, id: number) => {
			return {
				...state,
				data: state.data.filter(
					(i) => i.id !== id
				),
			};
		},
		setRead: (state, id: number) => {
			return {
				...state,
				data: state.data.map(
					(i) => i.id !== id ? i : { ...i, is_read: true }
				),
			};
		},
		setAllRead: (state) => {
			return {
				...state,
				data: state.data.map(
					(i) => ({ ...i, is_read: true })
				),
			};
		},
	},
	effects: (dispatch) => ({
		retrieve: async ({ force }: { force?: boolean }, state) => {

			if (!force && state.notify.cached) {
				return;
			}

			const { notifications } = await Notifications.read().promise;

			dispatch.notify.insert(notifications);

		},
		removeAsync: async (id: number) => {

			await Notifications.delete([ id ]).promise;

			dispatch.notify.remove(id);

		},
		setReadAsync: async (id: number) => {

			await Notifications.setRead([ id ]).promise;

			dispatch.notify.setRead(id);

		},
		setAllReadAsync: async (_, state) => {

			const ids = state.notify.data.map(
				({ id }) => id,
			);

			await Notifications.setRead(ids).promise;

			dispatch.notify.setAllRead();

		},
	}),
});
