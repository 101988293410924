import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

export function useAppDispatch () {

	return useDispatch<Store.Dispatch>();

}

export function useAppSelector () {

	return useSelector(
		(store: Store.Root) => store
	);

}
