import cn from 'classnames';

interface LoaderProps {
	loading?: boolean,
	variant?: 'absolute'
}

export const Loader: React.FC<LoaderProps> = (props) => {

	const { loading, variant } = props;

	return (
		<div className={cn('loader', {
			'loader-loading': loading,
			[`loader-${variant}`]: variant
		})}>
			<i></i>
		</div>
	);

}
