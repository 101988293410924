import heic2any from 'heic2any';
import { asArray } from './arrays';

export const normalizePhoto = async (file: File, quality: number): Promise<File> => {

	if (file.type !== 'image/heic') {
		return file;
	}

	const blob = await heic2any({
		blob: file,
		toType: 'image/jpeg',
		quality,
	});

	return new File([ asArray(blob)[0] ], file.name);

}
