import { BigAmount, Legend, OptionView } from '~/components';

export type StatsBlockAmount =
	[ string, number | string ] |
	{
		label: string,
		value: number | string,
	}

export interface StatsBlockProps {
	label: string,
	amount?: number,
	amounts?: StatsBlockAmount[],
}

export const StatsBlock: React.FC<StatsBlockProps> = (props) => {

	const { label, amount, amounts = [], } = props;

	return (
		<Legend label={label}>
			{typeof amount !== 'undefined' &&
			<BigAmount amount={amount} />
			}
			{!!amounts.length &&
			<>
				{amounts.map((data, i) =>
				<OptionView
					key={i}
					label={Array.isArray(data) ? data[0] : data.label}
					value={Array.isArray(data) ? data[1] : data.value} />
				)}
			</>
			}
		</Legend>
	);

}
