import { api } from '../';
import { App } from '~/types';
import { config } from '~/constants';

import {
	BusinessStatusKeys,
	GenericServices,
	ILocation,
	LocationType,
	RequestType,
	SocialLinksForm
} from './';

type Pending<T> = {
	type: T,
	paid: number,
	pending: number
}

export type RType = Exclude<RequestType, 'endorsement_campaign'>

export type StatUserType = 'fans' | 'business' | 'athletes' | 'total' | 'admins';

export type StatsOutput = {
	users_by_type: Record<StatUserType, number>,
	requests_total: number,
	requests_by_type: Record<RType, number>,
	uncompleted_profiles_by_type: Record<Exclude<StatUserType, 'admins'>, number>,
	requests_by_status: Record<BusinessStatusKeys, number>,
	paid_pending_total: Pending<'total'>,
	paid_pending_by_request_type: Pending<`payment_${RType}`>[],
}

export type UserUpdateServices = GenericServices & {
	athlete_locations: (LocationType | ILocation)[],
}

export type UserUpdateInfo = {
	school_name: string,
	school_id: string,
	school_email: string,
	sports_played: string,
	sports: string,
	legend: boolean,
	athlete_services: UserUpdateServices,
}

export type UserUpdateInput = SocialLinksForm & {
	id: string,
	email: string,
	first_name: string,
	last_name: string,
	gender: string,
	profile_photo: string,
	intro_video: string,
	phone: string,
	profile_description: string,
	athlete_info: UserUpdateInfo,
}


const promo_codes_path = `/admin/promo-codes` as const;

export const discountTypeOptions = [
	{ label: 'Flat', value: 'flat' },
	{ label: 'Percent', value: 'percent' },
];

const PromoCodes = {
	create: (body: App.Endpoints.CreatePromoCode[0]) => api.post<App.Endpoints.CreatePromoCode[1]>(promo_codes_path, body),
	read: (query: App.Endpoints.ReadPromoCode[0]) => api.get<App.Endpoints.ReadPromoCode[1]>(`${promo_codes_path}/list`, query),
	update: (body: App.Endpoints.UpdatePromoCode[0]) => api.put<App.Endpoints.UpdatePromoCode[1]>(promo_codes_path, body),
	delete: (body: App.Endpoints.DeletePromoCode[0]) => api.del<App.Endpoints.DeletePromoCode[1]>(promo_codes_path, body),
};


const pushes_path = `/admin/custom-push` as const;

const Pushes = {
	create: (data: App.Endpoints.CreatePush[0]) => api.post<App.Endpoints.CreatePush[1]>(pushes_path, data),
	read: (query: App.Endpoints.ReadPush[0]) => api.get<App.Endpoints.ReadPush[1]>(`${pushes_path}/search`, query),
	update: (data: App.Endpoints.UpdatePush[0]) => api.put<App.Endpoints.UpdatePush[1]>(pushes_path, data),
	delete: (id: App.Endpoints.DeletePush[0]) => api.del(`${pushes_path}?id=${id}`),
	send: (data: App.Endpoints.SendPush[0]) => api.post(`${pushes_path}/send`, data),
};


const ChatsParams = {
	customURL: `${config.URL_CHAT}/chat-service`,
}

const Chats = {
	create: (data: App.Endpoints.CreateTicket[0]) => api.post<App.Endpoints.CreateTicket[1]>(
		`/tickets`,
		data,
		ChatsParams,
	),
	read: (params: App.Endpoints.ReadTickets[0]) => api.get<App.Endpoints.ReadTickets[1]>(
		`/tickets/list`,
		params,
		ChatsParams
	),
	readManage: (params: App.Endpoints.ReadTicketsManage[0]) => api.get<App.Endpoints.ReadTicketsManage[1]>(
		`/tickets/dashboard/list`,
		{
			// ...params,
			archived: params.archived,
			...(params.subject ? { field: 'subject', value: params.subject, } : undefined),
			...(params.user_id ? { field: 'user_id', value: params.user_id, } : undefined),
			...(params.ticket_id ? { field: 'ticket_id', value: params.ticket_id.toLowerCase(), } : undefined),
			...(params.order === 'new-tickets-first' ?
				{ order_by: 'created_at', order: 'desc', } :
				{ order_by: 'updated_at', order: 'desc', }
			)
		},
		ChatsParams
	),
	readUsers: (filter: string) => api.get<App.Endpoints.ReadChatUsers[1]>(
		`/tickets/dashboard/users?filter=${encodeURIComponent(filter)}`,
		undefined,
		ChatsParams
	),
	readTicket: (id: string) => api.get<App.Endpoints.ReadTicket[1]>(
		`/tickets?id=${id}`,
		undefined,
		ChatsParams
	),
	setTicketStatus: (params: App.Endpoints.SetTicketStatus[0]) => api.put<App.Endpoints.SetTicketStatus[1]>(
		`/tickets/status`,
		params,
		ChatsParams
	),
	createMessage: (params: App.Endpoints.CreateMessage[0]) => api.post<App.Endpoints.CreateMessage[1]>(
		`/tickets/messages`,
		params,
		ChatsParams
	),
	createAsset: (data: App.Endpoints.CreateAsset[0]) => api.post<App.Endpoints.CreateAsset[1]>(
		'/assets',
		data,
		{ ...ChatsParams, type: 'DATA', }
	),
	readMeta: () => api.get<App.Endpoints.ReadMeta[1]>(
		'/tickets/meta',
		undefined,
		ChatsParams,
	),
	setMessageStatus: (params: App.Endpoints.SetMessageStatus[0]) => api.put<App.Endpoints.SetMessageStatus[1]>(
		'/tickets/messages/set-read',
		params,
		ChatsParams,
	),
};


export const Admin = {

	readUsers: (params: App.Endpoints.UsersRead[0]) => api.get<App.Endpoints.UsersRead[1]>(
		`/admin/user/${params.request ? 'search' : 'list-by-type'}`,
		params
	),

	readUser: (params: App.Endpoints.UserRead[0]) => api.get<App.Endpoints.UserRead[1]>(
		`/admin/user`,
		params
	),

	blockUser: (params: App.Endpoints.UserRead[0]) => api.post(
		`/admin/user/block`,
		params,
		{ type: 'FORM' }
	),

	readRequests: (params: App.Endpoints.ReadUsersRequests[0]) => api.get<App.Endpoints.ReadUsersRequests[1]>(
		`/admin/requests/filter`,
		params
	),

	readRequest: (params: App.Endpoints.ReadUsersRequest[0]) => api.get<App.Endpoints.ReadUsersRequest[1]>(
		`/admin/requests/by-id`,
		params,
	),

	removeUser: (params: App.Endpoints.UserRead[0]) => api.post(
		`/admin/user/delete`,
		params,
		{ type: 'FORM' }
	),

	readStats: () => api.get<{ counters: StatsOutput }>(
		`/admin/counters`
	),

	createUser: (params: App.Endpoints.UserCreate[0]) => api.post<App.Endpoints.UserCreate[1]>(
		`/admin/user/add`,
		params,
		{ type: 'JSON' }
	),

	togglePaidStatus: ({ id, status }: App.Endpoints.SetPayoutStatus[0]) => api.put(
		`/admin/payments/payout-status?id=${id}&status=${status}`,
	),

	getPayouts: () => api.get<App.Endpoints.ReadPayouts[1]>(`/admin/payments/payout-list`),

	sendPayouts: (payout_items: App.Endpoints.SendPayouts[0]) => api.post('/admin/payments/payout-send', { payout_items }),

	userUpdate: (data: UserUpdateInput) => api.post('/admin/user/update', data),

	uploadMedia: (type: 'photo' | 'video', file: File | File[], id: string) => api.post<{ path: string }>(
		`/admin/user/upload-${type}?athlete_id=${id}`,
		Array.isArray(file) ? {
			[type]: file[0],
			second_photo: file[1],
		} : {
			[type]: file,
		},
		{
			type: 'DATA',
		}
	),

	readSentPayouts: ({ page = 0, order = 'desc' }: App.Endpoints.ReadSentPayouts[0]) => api.get<
		App.Endpoints.ReadSentPayouts[1]
	>(
		`/admin/payments/payout-sent-list?page=${page}&order=${order}`
	),

	PromoCodes,

	Pushes,

	Chats,

	syncUser: (data: App.Endpoints.SyncUser[0]) => api.post('/mt/sync_by_id', data, { type: 'FORM' }),

	syncUsers: () => api.post('/mt/sync'),

	setTrutsted: (data: App.Endpoints.SetTrusted[0]) => api.put('/admin/user/business-trusted', data, { type: 'JSON' }),

	setFeatured: (data: App.Endpoints.SetFeatured[0]) => api.put('/admin/user/athlete-featured', data, { type: 'JSON' }),

};
