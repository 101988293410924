import _ from 'lodash';
import { v4 } from 'uuid';
import { App } from '~/types';
import { IAccount } from '../modules';

export type GetEmptyMessageArgs = App.Endpoints.CreateMessage[0] & {
	sent_id?: string,
	user?: IAccount,
}

export function getEmptyMessage (args: GetEmptyMessageArgs): App.Chat.Message {

	const { user, ...data } = args;

	const created_at = Math.floor(_.now() / 1000);

	const message: App.Chat.Message = {
		id: _.random(1, 100 * 100),
		user_id: user?.id || '',
		sent_id: v4(),
		sent_by: user?.user_type === 4 ? 'admin' : 'user',
		is_read: false,
		email_sent: false,
		created_at,
		updated_at: created_at,
		assets: '',
		...data,
	}

	return message;

}

export async function createAssetMeta (id: string, file: File): Promise<App.Chat.AssetMeta> {

	const dimensions = await getImageDimensions(file);

	const { name, size, } = file;

	const [ type, extension ] = file.type.split('/', 2) as [
		App.Chat.AssetType,
		string,
	]

	const meta: App.Chat.AssetMeta = {
		id,
		name,
		size,
		type,
		mime: file.type,
		extension,
		dimensions,
	}

	return meta;

}

export function getAssetMeta (meta?: string): App.Chat.AssetMeta | undefined {

	try {
		return JSON.parse(meta || '') as App.Chat.AssetMeta;
	} catch {
		return undefined;
	}

}

export function normalizeSize (size: number, decimals = 2): string {

	if (size === 0) {
		return '0';
	}

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = [ 'byte', 'KB', 'MB', 'GB', 'TB' ];

	const i = Math.floor(Math.log(size) / Math.log(k));

	return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

}

export function downloadAsset (href: string, name: string) {

	const link = document.createElement('a');
				link.download = name;
				link.target = '_blank';
				link.href = href;

	document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);

}

export async function getImageDimensions (file: File): Promise<App.Chat.Dimensions | undefined> {

	return new Promise(
		(resolve) => {

			const reader = new FileReader();

			const [ type ] = file.type.split('/', 2);

			if (type === 'application') {
				return resolve(undefined);
			}

			reader.onload = () => {

				if (type === 'video') {
					const video = document.createElement('video');
					video.addEventListener('loadedmetadata', () => {
						const {
							videoWidth: width = 0,
							videoHeight: height = 0,
						} = video;
						resolve({
							width,
							height,
						});
					});
					if (typeof reader.result === 'string') {
						video.src = reader.result;
					}
					return;
				}

				if (type === 'image') {
					const image = new Image();
					image.onload = () => {
						const { width = 0, height = 0 } = image;
						resolve({
							width,
							height,
						});
					}
					if (typeof reader.result === 'string') {
						image.src = reader.result;
					}
				}

			}

			reader.onerror = () => {
				resolve(undefined);
			}

			reader.readAsDataURL(file);

		}
	);

}
