import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useData } from '~/store';
import { lang, phoneRegexr, } from '~/constants';
import { EditProfileForm, EditProfileMedia, UserEditComponent } from '../modals/types';

import {
	Admin,
	IAccountAthlete,
	ResponseFail
} from '~/services';

import {
	Button,
	Input,
	InputPhone,
	Legend,
	PhotoButton,
	VideoButton,
	CSwitch,
	RSelect
} from '~/components';

type MediaState = {
	photo: {
		file?: string | File[],
		error?: string
	},
	video: {
		file?: string | File,
		error?: string
	}
}

export const UserEditGeneral: React.FC<UserEditComponent<IAccountAthlete>> = (props) => {

	const { loading, data: account, onSubmit, updated } = props;

	const [ , setIsUpdated ] = updated;

	const [ uploading, setUploading ] = useState(false);

	const { intro_video, options, sportOptions, schoolOptions, loading: dataLoading } = useData();

	const { control, handleSubmit, watch, setValue } = useForm<EditProfileForm>({
		defaultValues: {
			email: account.email,
			phone: account.phone,
			legend: account.athlete_info.legend,
			first_name: account.first_name,
			last_name: account.last_name,
		},
	});

	useEffect(() => {

		const { sports, school_id } = account.athlete_info;
		const _sports = sports.split(',');

		setValue('gender', options.genders.filter(
			({ value }) => value === account.gender
		)[0] as { label: string, value: string });

		setValue('sport', sportOptions.filter(
			({ value }) => _sports.includes(value)
		));

		setValue('school', schoolOptions.filter(
			({ value }) => value === school_id
		)[0]);

		setMedia((media) => ({
			...media,
			video: {
				file: account.intro_video || intro_video,
			},
		}));

	}, [ account, sportOptions, options.genders, schoolOptions, setValue, intro_video ]);

	const [ media, setMedia ] = useState<MediaState>({
		photo: {
			file: account.profile_photo,
		},
		video: {},
	});

	useEffect(() => {

		const subscription = watch(() => {
			setIsUpdated(true);
		});

		return () => subscription.unsubscribe();

	}, [ watch, setIsUpdated ]);

	const updateMedia = useCallback(
		(type: EditProfileMedia, file: File | File[] | string) => {

			setIsUpdated(true);

			setMedia((_media) => ({
				..._media,
				[type]: typeof file !== 'string' ? { file } : { error: file },
			}));

		},
		[ setIsUpdated ]
	);

	const uploadMedia = useCallback(
		async (type: EditProfileMedia, id: string) => {

			const file = media[type]?.file;

			if (!file || typeof file === 'string') {
				return file || '';
			}

			try {

				setUploading(true);

				const { path } = await Admin.uploadMedia(type, file, id).promise;

				return path;

			} catch (e) {

				updateMedia(type, (e as ResponseFail).message);

				return false;

			} finally {

				setUploading(false);

			}

		},
		[ media, updateMedia ]
	);

	const submit = useCallback(
		async (data: EditProfileForm) => {

			const intro_video = await uploadMedia('video', account.id);
			const profile_photo = await uploadMedia('photo', account.id);

			if (!intro_video) {
				return setMedia((media) => ({ ...media, video: { error: lang.VIDEO_EMPTINESS, } }))
			}

			if (!profile_photo) {
				return setMedia((media) => ({ ...media, photo: { error: lang.PHOTO_EMPTINESS, } }))
			}

			onSubmit({
				tab: 'general',
				data: {
					...data,
					intro_video,
					profile_photo,
				},
			});

		},
		[ uploadMedia, account, onSubmit ]
	);

	return (
		<>
			<PhotoButton
				photo={media.photo?.file}
				error={media.photo?.error}
				onPhotoChange={(file) => updateMedia('photo', file)}
				isCrop
				isAthlete />
			<Legend label={lang.GENERAL_INFO_LABEL}>
				<div className="field--wrapper">
					<Input
						name="email"
						icon="mail"
						rules={{ required: true }}
						control={control}
						placeholder="Email" />
					<InputPhone
						name="phone"
						icon="phone"
						control={control}
						rules={{
							required: true,
							validate: (val) => phoneRegexr.test(val) ?
								true :
								lang.INVALID_PHONE,
						}} />
					<Input
						name="first_name"
						icon="user"
						rules={{ required: true }}
						control={control}
						placeholder="First name" />
					<Input
						name="last_name"
						icon={null}
						rules={{ required: true }}
						control={control}
						placeholder="Last name" />
					<RSelect
						name="gender"
						icon="user"
						rules={{ required: true }}
						control={control}
						options={options.genders}
						placeholder="Gender" />
					<CSwitch
						name="legend"
						note={lang.LEGENDARY_NOTE}
						label={lang.LEGENDARY_LABEL}
						control={control} />
				</div>
			</Legend>
			<Legend label={lang.GENERAL_INFO_SURVEY_LABEL}>
				<div className="field--wrapper">
					<RSelect
						name="sport"
						icon="ServiceTrainingSession"
						rules={{ required: true }}
						isMulti
						control={control}
						options={sportOptions}
						placeholder={lang.SELECT_SPORT} />
					<RSelect
						name="school"
						icon="ServiceCamp"
						note={lang.SELECT_SCHOOL_SEARCH}
						rules={{ required: true }}
						control={control}
						options={schoolOptions}
						placeholder={lang.SELECT_SCHOOL} />
				</div>
			</Legend>
			<VideoButton
				label={lang.GENERAL_INFO_MEDIA_LABEL}
				video={media.video?.file}
				error={media.video?.error}
				onError={(error) => updateMedia('video', error)}
				onVideoChange={(file) => updateMedia('video', file)} />
			<Button
				label={lang.GENERAL_INFO_SAVE}
				onClick={handleSubmit(submit)}
				variant="primary"
				loading={loading || uploading || dataLoading.retrieve}
				disabledDeep />
		</>
	);

}
