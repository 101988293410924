import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

export const useTenants = () => {

	const [ tenants, loading ] = useSelector(
		({ tenants, loading }: Store.Root) => [
			tenants.tenants,
			loading.effects.tenants,
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	const fetch = useCallback(
		async () => void await dispatch.tenants.retrieve(),
		[ dispatch.tenants ]
	);

	useEffect(() => {

		fetch()

	}, [ fetch ]);

	return {
		tenants,
		...dispatch.tenants,
		loading,
	};

}
