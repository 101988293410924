import { useParams } from 'react-router-dom';

import { PageContent, PageTitle } from '~/containers';
import { VideoChat } from '~/containers';
import { useAuth, useRequest2 } from '~/store';
import { isRequest } from '~/services';

export const Chat: React.FC = () => {

	const { id } = useParams<{ id: string }>();

	const { account } = useAuth();

	const { request } = useRequest2(id, false);

	if (!request || !account || !isRequest.videoChat(request) || !('profile' in request)) {
		return null;
	}

	const { profile } = request;

	const {
		app_id,
		channel_name,
		fan_uid,
		fan_token,
		athlete_uid,
		athlete_token,
	} = request.video_chat;

	return (
		<>
			<PageTitle title="Video Chat" />
			<PageContent pageContentClassName="video--chat">
				<VideoChat
					uid={account.user_type === 2 ? athlete_uid : fan_uid}
					token={account.user_type === 2 ? athlete_token : fan_token}
					appId={app_id}
					profile={profile}
					requestId={request.id}
					channelName={channel_name}/>
			</PageContent>
		</>
	);

}
