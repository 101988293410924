import cn from 'classnames';
import { SquareIcon } from '~/components';
import { useAppState } from '~/store';

interface PageTitleProps {
	title: string,
	onBack?: () => void,
	content?: React.ReactElement,
}

export const PageTitle: React.FC<PageTitleProps> = (props) => {

	const { title, onBack, content, } = props;

	const [ , setMenuVisibility ] = useAppState();

	return (
		<div className={cn('app--page-title', { 'with-back': !!onBack })}>
			<div className="app--page-title__inner">
				{!!onBack && (
				<SquareIcon
					icon="arrowBack"
					onClick={onBack} />
				)}
				<h1>{title}</h1>
				<button
					onClick={() => setMenuVisibility(true)}
					className="menu">
					<i></i>
				</button>
			</div>
			{!!content &&
			<div
				children={content}
				className="app--page-title__content" />
			}
		</div>
	);

}
