import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
	handleError,
	RequestType,
	REQUEST_TYPE,
	RequestForm as RF,
	Request,
	IRequest,
	IAccount,
	requestInputTransform,
	findAssociatedProfile,
	PaymentSheet,
	RequestScheme,
	Stripe
} from '~/services';

import { useLoading } from '~/hooks';
import { useAthlete, useAuth } from '~/store';
import { PageContent, PageTitle, StripeModal } from '~/containers';

import * as RequestForms from '~/containers/RequestForm';

type PaymentInfo = {
	data: RequestScheme.Input,
	paid_id: string,
	requests: {
		request: IRequest,
		profile: IAccount
	}[]
}

const getRedirectURL = (info: PaymentInfo | null, isCampaign?: boolean) => {

	if (!info) {
		return '';
	}

	const { paid_id } = info;

	return `${window.location.origin}/request/${isCampaign ? '@' : ''}${paid_id}`;

}

export const RequestForm: React.FC = () => {

	const { id, type } = useParams<{ id: string, type: RequestType }>();

	const { goBack, replace } = useHistory();

	const { account } = useAuth();

	const { athlete } = useAthlete({ id: type === 'endorsement_campaign' ? '' : id, });

	const [ loading, setLoading ] = useLoading<'create' | 'checkout'>();

	const [ sheet, setSheet ] = useState<PaymentSheet | null>(null);

	const [ paymentInfo, setPaymentInfo ] = useState<PaymentInfo | null>(null);

	const initSheet = useCallback(
		(id: string, promoCode?: string) => new Promise<PaymentSheet>(async (resolve, reject) => {

			if (sheet) {
				return resolve(sheet);
			}

			try {

				const data = await Stripe.paymentSheet.get(id, type, promoCode).promise;

				setSheet(data);

				resolve(data);

			} catch (e) {

				reject(e);

			}

		}),
		[ type, sheet ],
	);

	const createRequest = useCallback(
		(form: RF.Scheme) => new Promise<PaymentInfo>(async (resolve, reject) => {

			try {

				if (paymentInfo) {
					return resolve(paymentInfo);
				}

				const data = requestInputTransform(
					id,
					type,
					form,
				);

				const response = await Request.create(data, type).promise;

				const stash: PaymentInfo = 'request' in response ? {
					data,
					paid_id: response.request.id,
					requests: [{
						request: response.request,
						profile: response.account,
					}],
				} : {
					data,
					paid_id: response.endorsement_campaign_id,
					requests: response.requests.map((request) => {
						return {
							request,
							profile: findAssociatedProfile(
								request,
								response.accounts,
								account!.user_type
							),
						};
					}),
				};

				if (!('as_trusted' in data) || !data.as_trusted) {
					setPaymentInfo(stash);
				}

				resolve(stash);

			} catch (e) {

				reject(e);

			}

		}),
		[ type, id, paymentInfo, account ]
	);

	const sendRequest = useCallback(
		async (form: RF.Scheme, callback: () => void) => {

			setLoading('create', true);

			try {

				const { paid_id, data } = await createRequest(form);

				if (!('as_trusted' in data) || !data.as_trusted) {
					setLoading('checkout', true);
					await initSheet(paid_id, form.promo_code);
				} else {
					replace(`/request/${type === 'endorsement_campaign' ? '@' : ''}${paid_id}`);
				}

				setLoading('create', false);

			} catch (e) {

				setLoading('create', false);

				handleError(e);

			}

		},
		[ type, createRequest, initSheet, replace, setLoading ]
	);

	const submit = useCallback(
		async (form: RF.Scheme, reset: () => void) => {

			try {

				sendRequest(form, reset);

			} catch (e) {

				handleError(e);

			}

		},
		[ sendRequest ]
	);

	const update = useCallback(
		(form: RF.Scheme) => {

			if (!sheet && !paymentInfo) {
				return;
			}

			setSheet(null);
			setPaymentInfo(null);

		},
		[ sheet, paymentInfo ]
	);

	const [ title, , ,form ] = REQUEST_TYPE[type];

	const Form = RequestForms[form];

	return (
		<>
			{type !== 'endorsement_campaign' && (
			<PageTitle
				title={title}
				onBack={goBack} />
			)}
			<PageContent
				pageContentClassName={`request--form type--${type.replace('_', '-')}`}>
				<Form
					athlete={athlete}
					onSubmit={submit}
					onUpdate={update}
					rosterId={type === 'endorsement_campaign' && id !== 'b' ? id : ''}
					processes={!!loading.create} />
			</PageContent>
			<StripeModal
				onHide={() => setLoading('checkout', false)}
				redirect={getRedirectURL(paymentInfo, type === 'endorsement_campaign')}
				isVisible={!!loading.checkout}
				paymentSheet={sheet} />
		</>
	);

}
