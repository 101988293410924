import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

export const useNotifications = () => {

	const [ state, loading ] = useSelector(
		({ notify, loading }: Store.Root) => [
			notify,
			loading.effects.notify
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	const unreadCount = useMemo(
		() => {

			return state.data
				.filter(({ is_read }) => !is_read).length;

		},
		[ state.data ]
	);

	return {
		...state,
		...dispatch.notify,
		loading,
		unreadCount,
	};

}
