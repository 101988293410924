import { Button } from './Button';
import { Icon } from './Icon';

import { mediaLink } from '../utils';
import { IRequestEndorsementExtended, REQUEST_STATUS, statusMap } from '~/services';

interface EndoresmentAthleteProps {
	request: IRequestEndorsementExtended,
	onReview: (athlete: IRequestEndorsementExtended) => void
}

export const EndoresmentAthlete: React.FC<EndoresmentAthleteProps> = (props) => {

	const { request, onReview } = props;

	const { profile } = request;

	const icon = statusMap[request.status];

	return (
		<div className="endorsement--athlete">
			<img src={mediaLink(profile.profile_photo, 'S')} alt="" />
			<div className="content">
				<h6 children={`${profile.first_name} ${profile.last_name}`}/>
				<p children={REQUEST_STATUS[request.status][0]} />
			</div>
			<div>
				{request.status === 4 || request.status === 5 ? (
				<Button
					label="View"
					variant="primary"
					onClick={() => onReview(request)} />
				) : (
				<div className={`status-icon status--${REQUEST_STATUS[request.status][0].toLowerCase()}`}>
					<Icon name={icon.icon} />
				</div>
				)}
			</div>
		</div>
	);

}
