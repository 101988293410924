import { useMemo } from 'react';

export const useRequestId = (id: string) => {

	return useMemo(
		() => {

			const isCampaign = id.substring(0, 1) === '@';
			const originalId = isCampaign ? id.substring(1) : id;

			return {
				isCampaign,
				originalId
			};

		},
		[ id ],
	);

}
