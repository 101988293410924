import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { App } from '~/types';
import { Store } from '../models';

export const USER_ROLES = [
	{ value: undefined, label: 'Any type', },
	{ value: 'fan', label: 'Fan', },
	{ value: 'athlete', label: 'Athlete', },
	{ value: 'athlete-legend', label: 'Legend athletes', },
	{ value: 'athlete-featured', label: 'Featured Athletes', },
	{ value: 'business', label: 'Business', },
	{ value: 'administrator', label: 'Administrator', },
]

export type UserTypes = typeof USER_ROLES[number]['value']

function roleSwitch (type?: UserTypes | null): [
	(App.Endpoints.UsersRead[0]['user_type'] | undefined),
	(App.Endpoints.FilterFeature | undefined)
] {
	switch (type) {
		case 'fan': return [1, undefined];
		case 'athlete': return [2, undefined];
		case 'business': return [3, undefined];
		case 'administrator': return [4, undefined];

		case 'athlete-legend': return [2, 'legend']
		case 'athlete-featured': return [2, 'featured'];

		default:
			return [undefined, undefined];
	}
}

function completenessSwitch (value?: number): App.Endpoints.FilterCompleteness | undefined {
	switch (value) {
		case 0: return 0;
		case 1: return 1;
		case 2: return 2;
		default: return undefined;
	}
}

export type UseUsersParams = Omit<
	App.Endpoints.UsersRead[0],
	'page_size' |
	'user_type' |
	'profile_completed'
> & {
	type?: UserTypes | null,
	completeness?: number,
}

export const useUsers = (params: App.Endpoints.UsersRead[0] | UseUsersParams) => {

	const [ state, loading ] = useSelector(
		({ admin, loading }: Store.Root) => [
			admin,
			loading.effects.admin,
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	useEffect(() => {

		if ('page_size' in params) {
			return void dispatch.admin.readUsers(params);
		}

		const { type, completeness, ...rest } = params;

		const [
			user_type,
			feature_filter,
		] = roleSwitch(type);

		dispatch.admin.readUsers({
			...rest,
			user_type,
			page_size: 20,
			feature_filter,
			profile_completed: completenessSwitch(completeness),
		});

	}, [ dispatch.admin, params ]);

	return {
		...state,
		...dispatch.admin,
		loading,
	};

}
