import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { toTitleCase } from './titleCase';

dayjs.extend(relativeTime);

export const getRelativeTime = (
	unix: number,
	format = 'ddd h:mm a'
): string => {

	unix *= 1000;

	const now = dayjs()
	const then = dayjs(unix);
	const diff = now.diff(unix, 'days');

	if (diff >= 1) {
		if (diff >= 6) format = 'ddd D, h:mm a';
		if (then.get('month') !== now.get('month')) format = 'ddd D, MMM h:mm a';
		if (then.get('year') !== now.get('year')) format = 'ddd D, MMM h:mm a YYYY';
		return dayjs(unix).format(format);
	}

	return toTitleCase(
		dayjs().to(dayjs(unix)),
		true
	);

}
