import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

export const useRequest = (id?: string) => {

	const [ empty, setEmpty ] = useState(false);

	const [ request, loading ] = useSelector(
		({ admin, loading }: Store.Root) => [
			id ? admin.requestsById[id] : null,
			loading.effects.admin,
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	const fetch = useCallback(
		async (id: string) => {

			const resp = await dispatch.admin.readRequest(id);

			if (resp === null) {
				setEmpty(true);
			}

		},
		[ dispatch.admin ]
	);

	useEffect(() => {

		if (!id) {
			return;
		}

		fetch(id)

	}, [ fetch, id ]);

	return {
		request,
		empty,
		...dispatch.admin,
		loading,
	};

}
