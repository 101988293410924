import { useParams } from 'react-router';

import { Preview } from '~/containers/Athlete';

export const AthletePreview: React.FC = () => {

	const { id } = useParams<{ id: string }>();

	return <Preview athleteId={id} />

}
