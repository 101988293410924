import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

import { useEffectOnce } from '~/hooks';
import { useRequest2 } from '~/store';
import { PageContent, PageTitle } from '~/containers';

import { RequestCard, Tabs } from '~/components';
import { getRequestTitle, IRequestExtra, RequestStatus } from '~/services';

const tabs = [
	{ label: 'Pending', emptyText: 'You don\'t have\n pending requests yet' },
	{ label: 'Accepted', emptyText: 'You don\'t have\n accepted requests yet' },
	{ label: 'Finished', emptyText: 'You don\'t have\n finished requests yet' },
];

const _tabs: Record<number, RequestStatus[]> = {
	0: [1],
	1: [2, 4, 6],
	2: [5],
}

export const Requests: React.FC = () => {

	const { push } = useHistory();

	const [ tab, setTab ] = useQueryParam('tab', withDefault(NumberParam, 0));

	const changeTab = (val: number) => {
		setTab(val, 'replace');
	}

	const { requests, loading, retrieveList } = useRequest2();

	useEffectOnce(() => {

		retrieveList({ force: true });

	});

	const Header = (
		<Tabs
			tab={tab}
			tabs={tabs}
			onChange={changeTab} />
	);

	const data = useMemo(
		() => requests.filter(
			({ status }) => _tabs[tab].includes(status),
		),
		[ tab, requests ]
	);

	const renderItem = (request: IRequestExtra) => (
		<RequestCard
			key={request.id}
			type={request.type}
			title={getRequestTitle(request)}
			status={request.status}
			params={[{
				type: 'requestor',
				value: `${request.first_name} ${request.last_name}`,
			}, {
				type: 'expireTs',
				value: request.expiration_time,
			}]}
			onClick={() => push(`/request/${request.type === 'endorsement_campaign' ? '@' : ''}${request.id}`)} />
	);

	const EmptyComponent = (
		<p className="content--empty">{tabs[tab].emptyText}</p>
	);

	return (
		<>
			<PageTitle
				title="Requests" />
			<PageContent
				data={data}
				loading={loading.retrieveList}
				renderItem={renderItem}
				pageContentHeader={Header}
				pageEmptyComponent={EmptyComponent} />
		</>
	);

}
