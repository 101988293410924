import cn from 'classnames';

import { Title } from './Title';

interface BottomBarProps {
	title?: string,
	innerClassName?: string
}

export const BottomBar: React.FC<BottomBarProps> = (props) => {

	const { title, innerClassName, children } = props;

	return (
		<div className="app--bottom-bar">
			{title && (
			<div className="app--bottom-bar-title">
				<Title label={title} />
			</div>
			)}
			<div className={cn('app--bottom-bar-inner', innerClassName)}>
				{children}
			</div>
		</div>
	);

}
