import { Icon, IconProps } from './Icon';

interface IconParamProps {
	icon: IconProps,
	text: string,
	hidden?: boolean
}

export const IconParam: React.FC<IconParamProps> = (props) => {

	const { icon, text, hidden } = props;

	if (hidden) {
		return null;
	}

	return (
		<div className="app--icon-param">
			<Icon {...icon} />
			<p children={text} />
		</div>
	);

}
