import { useHistory, useLocation } from 'react-router-dom';

export function useGoBack () {

	const { goBack, push } = useHistory();

	const location = useLocation();

	return function (fallback?: string) {
		if (!fallback) {
			return goBack();
		}
		return location.key ? goBack() : push(fallback);
	}

}
