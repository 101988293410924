import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { useData } from '~/store';
import { lang } from '~/constants';
import { UserEditComponent } from '../modals/types';

import {
	formatServices,
	ILocation,
	IServices,
	LocationType,
	parseProducts,
	PayableKeys,
	RequestType,
	REQUEST_TYPE,
	servicesMap,
} from '~/services';

import {
	Button,
	Collapsable,
	Legend,
	MapLocation,
	MapWrap,
	QuantitySelector
} from '~/components';

export const UserEditServices: React.FC<UserEditComponent<IServices>> = (props) => {

	const { loading, onSubmit, updated } = props;

	const [ services, setServices ] = useState(props.data);

	const [ map, setMap ] = useState<RequestType | undefined>();

	const [ , setIsUpdated ] = updated;

	const { iap_apple_products } = useData();

	const locations = useMemo(
		() => {

			const _locations = {} as Record<RequestType, (LocationType | ILocation)[]>

			services.athlete_locations.forEach((item) => {

				if (!_locations[item.location_type]) {
					_locations[item.location_type] = [];
				}

				_locations[item.location_type].push(item);

			});

			return _locations;

		},
		[ services ]
	);

	const isValid = useMemo(
		() => formatServices(services).isValid,
		[ services ]
	);

	const updateLocations = useCallback(
		(location: LocationType | string) => {

			setIsUpdated(true);

			setServices((_services) => {

				const athlete_locations = typeof location !== 'string' ?
					[..._services.athlete_locations, {
						...location,
						athlete_id: _services.athlete_id,
					}] :
					_services.athlete_locations.filter(
						({ id }) => id !== location
					);

				return {
					..._services,
					athlete_locations,
				};

			});

		},
		[ setIsUpdated ]
	);

	const change = useCallback(
		(key: PayableKeys, val?: number) => {

			setIsUpdated(true);

			setServices((_services) => ({
				..._services,
				...(val ?
					{ [`${key}_rate`]: val } :
					{ [`${key}_enabled`]: !_services[`${key}_enabled`] }
				),
			}));

		},
		[ setIsUpdated ]
	);

	const submit = useCallback(
		() => {

			onSubmit({
				tab: 'services',
				data: services
			});

		},
		[ services, onSubmit ]
	);

	return (
		<>
			<Legend label={lang.SERVICES_LABEL}>
				{servicesMap.map(({ label, key, note, location }, i) => (
				<Collapsable
					key={i}
					title={label}
					selected={services[`${key}_enabled`]}
					setSelected={() => change(key)}>
					<QuantitySelector
						{...REQUEST_TYPE[key][4]}
						label={note}
						array={key === 'shoutout' ? parseProducts(iap_apple_products).shoutout?.amount.usd || [] : undefined}
						prefix="$"
						amount={services[`${key}_rate`]}
						onChange={(val) => change(key, val)} />
					{location && (
					<Legend label={lang.TRAVEL_PARAMS}>
						{locations[key]?.map((location, i) => (
						<MapLocation
							{...location}
							key={i}
							onRemove={() => updateLocations(location.id)} />
						))}
						<Button
							label={lang.TRAVEL_PARAMS_ADD(!!locations[key]?.length)}
							variant="secondary"
							onClick={() => setMap(key)} />
					</Legend>
					)}
				</Collapsable>
				))}
			</Legend>
			<Button
				label={lang.SERVICES_SAVE}
				onClick={submit}
				variant="primary"
				loading={loading}
				disabled={!isValid}
				disabledDeep />
			<p
				children={lang.SERVICES_REQUIRE}
				className={cn('message', { active: !isValid })} />
			<MapWrap
				onClose={() => setMap(undefined)}
				isVisible={!!map}
				locationType={map}
				onLocationCreate={(data) => {
					updateLocations(data);
					setMap(undefined);
				}} />
		</>
	);

}
