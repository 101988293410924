import { Button } from '~/components';
import { useGoBack } from '~/services';

export interface ContainerErrorProps {
	title: string,
	content?: string,
	onGoBack?: string | boolean | (() => void),
}

export const ContainerError: React.FC<ContainerErrorProps> = (props) => {

	const { title, content, onGoBack } = props;

	const goBack = useGoBack();

	return (
		<div className="manage-content container-error">
			<div className="container-error__message">
				<h1>{title}</h1>
				{!!content && <p>{content}</p>}
				{!!onGoBack &&
				<Button
					label="Go Back"
					onClick={() => {

						if (typeof onGoBack === 'function') {
							return onGoBack();
						}

						if (typeof onGoBack === 'string') {
							return goBack(onGoBack);
						}

						return goBack();

					}} />
				}
			</div>
		</div>
	);

}
