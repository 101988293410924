export const PageParam = {
	encode: (str: number | undefined): string | undefined => {
		if (typeof str === 'undefined') {
			return undefined;
		}
		return str.toString();
	},
	decode: (str: string | (string | null)[] | null | undefined): number => {
		if (str === null || typeof str !== 'string') {
			return 0;
		}
		return + str;
	},
};
