import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Tabs, TabsProps } from './Tabs';

export interface TabsRouterProps extends Omit<TabsProps, 'tab' | 'onChange'> {}

export const TabsRouter: React.FC<TabsRouterProps> = (props) => {

	const { tabs, ...args } = props;

	const { push } = useHistory();

	const { pathname } = useLocation();

	const tab = useMemo(
		() => tabs.findIndex(({ value }) => value === pathname),
		[ tabs, pathname ]
	);

	return (
		<Tabs
			{...args}
			tab={tab}
			tabs={tabs}
			onChange={(i) => tabs[i].value && push(tabs[i].value!)} />
	);

}
