import { ChangeEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { IAthlete } from '~/services';
import { AthleteListed, Button, Input, Legend } from '~/components';
import { PageContent, PageTitle } from '~/containers';
import { AthletesPicker, _pushOrRemove } from '~/containers/AthletesPicker';
import { useRosters } from '~/store';
import { lang } from '~/constants';

export const RosterForm: React.FC = () => {

	const { location: { state }, replace, goBack } = useHistory<{ name: string }>();

	const inputRef = useRef<HTMLInputElement>(null);

	const { createRoster, loading } = useRosters();

	const [ picker, setPicker ] = useState(true);

	const [ name, setName ] = useState(state.name);

	const [ athletes, setAthletes ] = useState<IAthlete[]>([]);

	const onInputChange = useCallback<
		ChangeEventHandler<HTMLInputElement>
	>(
		(e) => {
			setName(e.target.value);
		},
		[]
	);

	const isValid = useMemo(
		() => !!name.length && !!athletes.length,
		[ name, athletes ]
	);

	const onSubmit = useCallback(
		async () => {

			if (!isValid) {
				return inputRef.current?.focus();
			}

			const athletes_id = athletes.map(({ id }) => id).join(',');

			const result = await createRoster({ name, athletes_id, });

			if ('error' in result) {
				return alert(result.error?.message || lang.ROSTER_CREATE_ERROR);
			}

			replace(`/business/roster/${result.roster.id}`);

		},
		[ isValid, inputRef, name, athletes, createRoster, replace ]
	);

	const onAthleteClick = useCallback(
		(athlete: IAthlete, e: React.MouseEvent) => {

			e.preventDefault();

			e.stopPropagation();

			setAthletes((val) => _pushOrRemove(val, athlete));

		},
		[]
	);

	if (picker) {
		return (
			<AthletesPicker
				note={lang.ROSTER_CREATE_NOTE}
				shown={picker}
				onHide={setPicker.bind(null, false)}
				selected={athletes}
				hideRates
				onAthleteClick={onAthleteClick} />
		);
	}

	return (
		<>

			<PageTitle
				title={lang.ROSTER_CREATE_TITLE}
				onBack={goBack} />

			<PageContent
				pageContentClassName="roster-form">

				<Input
					icon="Whistle"
					value={name}
					innerRef={inputRef}
					onChange={onInputChange}
					placeholder="Roster name" />

				<Legend
					label="Athletes">
					{!!athletes.length && athletes.map((athlete, i) => (
					<AthleteListed
						key={i}
						athlete={athlete}
						onRemove={onAthleteClick} />
					))}
					<div className="legend-extender">
						<Button
							variant="secondary"
							label={`Add ${athletes.length > 0 ? 'more ' : ''}athletes`}
							onClick={setPicker.bind(null, true)} />
					</div>
				</Legend>

				<Button
					label="Create roster"
					onClick={onSubmit}
					loading={loading.createRoster}
					variant="primary"
					disabled={!isValid || loading.createRoster}
					disabledDeep />

			</PageContent>

		</>
	);

}
