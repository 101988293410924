import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

export const useStats = () => {

	const [ stats, loading ] = useSelector(
		({ admin, loading }: Store.Root) => [
			admin.stats,
			loading.effects.admin,
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	useEffect(() => {

		dispatch.admin.readStats();

	}, [ dispatch.admin ]);

	return {
		stats,
		...dispatch.admin,
		loading,
	};

}
