import { useCallback } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useRequest } from '~/store';
import { ModalRequest } from '~/containers/manage';

import { Button, SquareIcon, useOverlay } from '~/components';
import { confirm, IRequest, REQUEST_STATUS, REQUEST_TYPE } from '~/services';

export const Request: React.FC = () => {

	const { id } = useParams<{ id: string }>();

	const { empty, request, togglePaidStatus } = useRequest(id);

	const { Over, OverItem, ...over } = useOverlay<'request', IRequest>({
		request: {
			title: 'Request',
			className: 'request',
		},
	});

	const _togglePaidStatus = useCallback(
		async (id: string, user_id: string, amount: number, status: boolean, campaign_id?: string) => {

			if (!await confirm({
				title: 'Request status update',
				message: 'Are you sure want update payout status?',
			})) {
				return;
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await togglePaidStatus({ id, user_id, campaign_id, amount, status });

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

				}),
				{
					pending: 'Updating the status',
					success: 'Status has been changed',
					error: { render: ({ data }) => data },
				},
			);

		},
		[ togglePaidStatus ]
	);

	if (!request) {
		if (empty) {
			return <Redirect to="/manage/users" />
		}
		return null;
	}

	const RequestsList = request.requests.map((request, i) => {

		const { type, paid, status, order_number, payment } = request;
		const [ title, , icon ] = REQUEST_TYPE[type];

		if (!payment) {
			return null;
		}

		const {
			amount,
			athlete_id,
			athlete_name,
			sender,
			sender_name,
			payout_status
		} = payment;

		return (
			<div key={i} className="request-list--item" onClick={() => over.show('request', request)}>
				<SquareIcon icon={{ name: icon }} />
				<div className="request-item--content">
					<h4>{title} #{order_number} <span className={`request-status request-s${status}`}>{REQUEST_STATUS[status][0]}</span></h4>
					<p>To <Link onClick={e => e.stopPropagation()} to={`/manage/user/${athlete_id}`}>{athlete_name}</Link> from <Link onClick={e => e.stopPropagation()} to={`/manage/user/${sender}`}>{sender_name}</Link></p>
				</div>
				<div className="request-item--amount">${amount}</div>
				{(status === 5 && paid) && (
				<Button
					disabled={payout_status}
					onClick={payout_status ? undefined : (e) => {
						e.stopPropagation();
						_togglePaidStatus(request.id, id, request.payment?.amount || 0, true, id);
					}}
					variant={payout_status ? undefined : 'primary'}
					label={payout_status ? 'Paid' : `Set paid`} />
				)}
			</div>
		);

	});

	return (
		<div className="manage-content">
			<div className="page-header">
				<h1>{request.type === 'request' ? 'Request' : 'Endorsement campaign'}</h1>
			</div>
			<div
				className="page-body"
				children={RequestsList} />
			<Over {...over}>
				<OverItem
					{...over}
					name="request"
					render={(request) => <ModalRequest request={request} />} />
			</Over>
		</div>
	);

}
