import mimeHelper from 'mime/lite';

export type Asset = File | string

export function getFileType (mime: string): [ string, string, string ] {

	if (!/^[-\w]+\/[-\w]+(\.[-\w]+)*([+][-\w]+)?$/i.test(mime)) {
		mime = mimeHelper.getType(mime) || '';
	}

	const [ type = '', extension = '' ] = mime.split('/', 2);

	return [ type, extension, mime ];

}
