import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { store } from '~/store';
import { AppRoutes } from '~/containers';

import './assets/scss/index.scss';

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<QueryParamProvider ReactRouterRoute={Route}>
				<AppRoutes />
			</QueryParamProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
