import { useCallback, useEffect, useState } from 'react';
import { random } from 'lodash';
import { useHistory } from 'react-router';
import Agora from 'agora-rtc-react';

import { Button, Modal, Rating } from '~/components';
import { SupportModal } from '../Modals';

import {
	handleError,
	REQUEST_TYPE,
	RequestActionsProps,
	isRequest,
	_confirm,
} from '~/services';

type RatingType = {
	value: number,
	error?: string | true | null
	modal?: boolean
}

export const RequestActionsFan: React.FC<RequestActionsProps> = (props) => {

	const {
		request: { profile, ...request },
		onUpdate
	} = props;

	const { push } = useHistory();

	const { status } = request;

	const [ inappropriate, setInappropriate ] = useState(false);

	const [ supportModal, setSupportModal ] = useState(false);

	const [ rating, setRating ] = useState<RatingType>({
		value: 0,
	});

	const [ loading, setLoading ] = useState(false);

	const [ hasCamera, setHasCamera ] = useState(false);

	useEffect(() => {

		if (request.type !== 'video_chat') {
			return;
		}

		const getCameras = async () => {

			const cameras = await Agora.getCameras();

			setHasCamera(!!cameras.length);

		}

		getCameras();

	}, [ request.type ]);

	const setCanceled = useCallback(async () => {

		try {

			if (!await _confirm.requestCancel(request.type)) {
				return;
			}

			setLoading(true);

			await onUpdate({ id: request.id, type: 'CANCEL_REQUEST', });

		} catch (e) {

			handleError(e);

		} finally {

			setLoading(false);

		}

	}, [ request, onUpdate ]);

	const setApproved = useCallback(async () => {

		try {

			setLoading(true);

			setRating((val) => ({ ...val, modal: true }));

			await onUpdate({ id: request.id, type: 'APPROVE_REQUEST', });

		} catch (e) {

			handleError(e);

		} finally {

			setLoading(false);

		}

	}, [ request, onUpdate ]);

	const setReviewed = useCallback(async (rate: number) => {

		try {

			setLoading(true);

			await onUpdate({ id: request.id, type: 'REVIEW_REQUEST', rate, campaign: '' });

			setRating({
				value: 0,
				modal: false,
			});

		} catch (e) {

			handleError(e);

		} finally {

			setLoading(false);

		}

	}, [ onUpdate, request ]);

	// const saveVideo = useCallback(async () => {

	// 	try {

	// 		const { type } = request;

	// 		if (type !== 'shoutout' && type !== 'endorsement') {
	// 			return;
	// 		}

	// 		const entity = request[type];

	// 		if (!entity) {
	// 			return;
	// 		}

	// 		const url = config.URL_BASE + entity.video;

	// 		const extRegex = url.match(/\.\w{3,4}($|\?)/) || [];

	// 		const appendExt = extRegex[0].slice(1) || 'mp4';

	// 		FileSaver.saveAs(url, `${type}-video.${appendExt}`);

	// 		setRating((val) => ({ ...val, modal: false }));

	// 	} catch (e) {

	// 		handleError(e);

	// 	}

	// }, [ request ]);

	// const downloadType = useMemo(
	// 	() => {

	// 		if (
	// 			request.type !== 'shoutout' &&
	// 			request.type !== 'endorsement'
	// 		) {
	// 			return '';
	// 		}

	// 		return parseMediaLink(request[request.type]?.video || '').type;

	// 	},
	// 	[]
	// );

	const RatingModal = (
		<Modal
			icon="starCircle"
			title={request.reviewed ? `Share ${request.type}` : 'Leave a review'}
			onHide={() => setRating((val) => ({ ...val, modal: false }))}
			visible={!!rating.modal}
			className="rating--modal"
			children={(
				<>
					{!request.reviewed && (
					<>
						<p children={`Please rate your ${REQUEST_TYPE[request.type][0].toLowerCase()}`} />
						<div style={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: 30,
						}}>
							<Rating
								large
								value={rating.value}
								onChange={(value) => setRating((val) => ({ ...val, value }))} />
						</div>
						<Button
							disabled={!request.reviewed && !rating.value}
							disabledDeep
							onClick={() => setReviewed(rating.value)}
							variant="primary"
							label="Submit" />
					</>
					)}
					{/* {['shoutout', 'endorsement'].includes(request.type) && (
					<>
						<Button
							disabled={!request.reviewed}
							onClick={saveVideo}
							variant="secondary"
							label={`Download ${downloadType}`} />
						<Button
							disabled={!request.reviewed}
							disabledDeep
							onClick={async () => {
								try {
									if (
										request.type !== 'shoutout' &&
										request.type !== 'endorsement'
									) {
										return;
									}
									await navigator.share({
										title: `Share your ${request.type}`,
										text: config.URL_BASE + request[request.type]?.video,
										url: config.URL_BASE + request[request.type]?.video,
									});
								} catch (e) {}
							}}
							label="Share" />
					</>
					)} */}
				</>
			)} />
	);

	const SupportButton = (
		<>
			<SupportModal
				title="Conact customer support"
				onHide={() => setSupportModal(false)}
				message={`Request ID: ${request.id}`}
				visible={supportModal} />
			<Button
				variant="secondary"
				label="Contact Customer Support"
				onClick={() => setSupportModal(true)} />
		</>
	);

	if (status === 1) {
		return (
			<Button
				variant="primary"
				loading={loading}
				onClick={setCanceled}
				label="Cancel request" />
		);
	}

	if (status === 2 && request.is_apple_iap && !request.paid) {
		return (
			<>
				<Button
					label="Pay"
					disabled
					disabledDeep
					variant="primary" />
			</>
		);
	}

	if (status === 2 && !isRequest.withTimestamp(request)) {
		return (
			<Button
				label="Awaiting completion" />
		);
	}

	if (status === 2 && isRequest.withTimestamp(request)) {
		return (
			<>
				{/* <Button
					variant="primary"
					label="Add to calendar"
					onClick={() => {}} /> */}
				<Button
					label={`Awaiting ${REQUEST_TYPE[request.type][0]}`} />
			</>
		);
	}

	if (status === 4) {
		return (
			<>
				{RatingModal}
				<Button
					loading={loading}
					onClick={setApproved}
					variant="primary"
					label={request.type === 'shoutout' ? 'Accept' : 'Approve'} />
				{request.type === 'shoutout' && (
				<Button
					label="Flag as inappropriate"
					loading={inappropriate}
					onClick={() => {
						setInappropriate(true);
						setTimeout(() => {
							alert('Marked as inappropriate. Thanks for your feedback.');
							setInappropriate(false);
						}, random(100, 1000));
					}} />
				)}
				{SupportButton}
			</>
		);
	}

	if (status === 5 && !request.reviewed) {
		return (
			<>
				{RatingModal}
				<Button
					onClick={() => setRating((val) => ({ ...val, modal: true }))}
					variant="primary"
					label="Review" />
				{SupportButton}
			</>
		);
	}

	if (status === 5 && request.reviewed) {
		return (
			<>
				{RatingModal}
				{/* {['shoutout', 'endorsement'].includes(request.type) && (
				<Button
					onClick={() => setRating((val) => ({ ...val, modal: true }))}
					variant="primary"
					label="Share" />
				)} */}
				{SupportButton}
			</>
		);
	}

	if (status === 6 && isRequest.videoChat(request) && request.video_chat.ready) {
		if (!hasCamera) {
			return (
				<Button
					label="Camera is not recognized" />
			);
		}
		return (
			<Button
				variant="primary"
				label="Join chat"
				onClick={() => push(`/chat/${request.id}`)} />
		);
	}

	return null;

}
