import { useCallback, useRef, useState } from 'react';

import { App } from '~/types';
import { ButtonInput, SquareIcon } from '~/components';
import { Admin } from '~/services';
import { createAssetMeta } from '~/services/chat';
import { ChatAsset } from './ChatAsset';

export interface ChatFormProps {
	ticket: App.Chat.Ticket,
	isLoading?: boolean,
	onMessage?: (text: string, assets?: App.Chat.AssetMeta) => Promise<void>,
}

export const ChatForm: React.FC<ChatFormProps> = (props) => {

	const { ticket, isLoading, onMessage } = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const [ text, setText ] = useState('');

	const [ file, setFile ] = useState<File>();

	const [ isAssetLoading, setIsAssetLoading ] = useState(false);

	const onFileChange = useCallback(
		({ target }: React.ChangeEvent<HTMLInputElement>) => {

			try {
				setFile(target.files?.[0]);
			} catch {}

		},
		[]
	);

	const onMessageSend = useCallback(
		async () => {

			if (isLoading) {
				return;
			}

			if (!text.length && !file) {
				return void inputRef.current?.focus();
			}

			let asset: App.Chat.Asset | undefined = undefined;
			let assetMeta: App.Chat.AssetMeta | undefined = undefined;
			if (file) {

				setIsAssetLoading(true);

				asset = (await Admin.Chats.createAsset({ file }).promise).asset;
				assetMeta = await createAssetMeta(asset.id, file);

				setIsAssetLoading(false);

				setFile(undefined);

			}

			setText('');

			await onMessage?.(
				asset ? asset.url : text,
				assetMeta,
			);

			inputRef.current?.focus();

		},
		[
			text,
			file,
			inputRef,
			isLoading,
			onMessage,
		]
	);

	const onInputKeyUp = useCallback(
		(e: React.KeyboardEvent) => e.key === 'Enter' &&
			onMessageSend(),
		[ onMessageSend ]
	);

	return (
		<div className="app--chat-form">

			{ticket.status === 'closed' &&
			<div className="app--chat-form__inner close-message">
				<div>
					<h4>Conversation is closed</h4>
					{!!ticket.comment &&
					<p><b>Reason:</b> {ticket.comment}</p>
					}
				</div>
			</div>
			}

			{ticket.status !== 'closed' &&
			<div className="app--chat-form__inner">
				<ButtonInput
					key={file?.name}
					icon="attachClip"
					accept="all"
					disabled={isLoading || isAssetLoading}
					onChange={onFileChange} />
				{file ?
				<div className="app--chat-form__asset">
					<ChatAsset
						name={file.name}
						size={file.size}
						type={file.type.split('/', 2)[0] as App.Chat.AssetType}
						onFileRemove={setFile.bind(null, undefined)} />
				</div> :
				<input
					ref={inputRef}
					type="text"
					value={text}
					onKeyUp={onInputKeyUp}
					onChange={({ target }) => setText(target.value)}
					disabled={isLoading || isAssetLoading}
					autoFocus
					className="app--chat-form__input"
					placeholder="Type your message..." />
				}
				<SquareIcon
					icon="submitArrow"
					size={48}
					plain
					loading={isLoading || isAssetLoading}
					onClick={onMessageSend}
					disabled={isLoading || isAssetLoading} />
			</div>
			}

		</div>
	);

}
