export namespace Manage {

	export const manage = 'manage';

	/* Pushes
	 */
	export interface Push {
		name: string,
		subject: string,
		message: string,
		readonly id: string,
		readonly created_at: number,
		readonly updated_at: number
	}


	/* Promo Codes
	 */
	export type DiscountType = 'flat' | 'percent';

	export interface PromoCode {
		code: string,
		enabled: boolean,
		archived: boolean,
		usage_limit: number,
		discount_type: DiscountType,
		discount_amount: number,
		expiration_time: number,
		usage_per_user_limit: number,
		total_discount_amount_limit: number,
		readonly id: string,
		readonly created_at: number,
		readonly updated_at: number,
		readonly usage_count: number,
		readonly total_discount_amount: number,
	}


	/* Payouts
	 */
	export type PayoutAthlete = {
		id: string,
		email: string,
		mobile: string,
		past_due: number,
		last_name: string,
		first_name: string,
		current_due: number,
		last_payment_date: number,
		last_payment_amount: number,
	}

	export type PayoutAthletePriced = PayoutAthlete & {
		amount: number
	}

	export interface PayoutTarget {
		id: string,
		note: string,
		amount: number,
		receiver: string,
		payout_id: string,
		athlete_id: string,
		created_at: number,
		athlete_name: string,
		athlete_email: string,
		sender_item_id: string,
		recipient_type: 'PHONE',
		recipient_wallet: 'VENMO',
	}

	export interface Payout {
		id: string,
		subject: string,
		message: string,
		created_at: number,
		created_by: string,
		items_view?: PayoutTarget[],
		total_amount: number,
		pay_pal_payout_id: string,
	}


}
