import { api } from '../api';

import { RequestType } from './Request';

export type LocationType = {
	id: string,
	name: string,
	radius: number,
	latitude: number,
	longitude: number,
	location_type: RequestType
}

export interface ILocation extends LocationType {
	readonly athlete_id: string,
	readonly created_at: number,
	readonly updated_at: number
}

const path = '/athlete/locations';

export const Location = {

	create: (data: LocationType) => api.post<{ location: ILocation }>(path, data, { type: 'JSON' }),

	read: () => api.get<{ locations: ILocation[] }>(`${path}/list`),

	delete: (location_id: string) => api.del(path, { location_id }, { type: 'DATA' }),

};
