import React, { useCallback, useState } from 'react';

import { PartialRecord } from '~/types';
import { Button } from '~/components';
import { CampaignActionsProps, handleError, _confirm } from '~/services';

type LoadingState = PartialRecord<'cancel', boolean>

export const RequestActionsBusiness: React.FC<CampaignActionsProps> = (props) => {

	const { request, onUpdate } = props;

	const [ loading, setLoading ] = useState<LoadingState>({});

	const cancelCampaign = useCallback(
		(id: string) => new Promise(async (resolve, reject) => {

			try {

				setLoading((val) => ({ ...val, cancel: true }));

				if (!await _confirm.campaignCancel()) {
					return;
				}

				await onUpdate({ id, type: 'CANCEL_CAMPAIGN' });

				resolve(true);

			} catch (e) {

				reject(e);

				handleError(e);

			} finally {

				setLoading((val) => ({ ...val, cancel: false }));

			}

		}),
		[ onUpdate ]
	);

	const RequestCancel = (
		<Button
			label="Cancel Campaign"
			loading={loading.cancel}
			onClick={() => cancelCampaign(request.id)}
			variant="primary" />
	);

	const RequestCanceled = (
		<Button
			disabled
			label="Campaign Canceled" />
	);

	const Awaiting = (
		<Button
			disabled
			label="Awaiting Completion" />
	);

	const Finished = (
		<Button
			disabled
			label="Campaign is finished" />
	);

	return (
		<>
			{request.status === 1 && RequestCancel}
			{request.status === 3 && RequestCanceled}
			{request.status === 5 && Finished}
			{[2, 4, 6].includes(request.status) && Awaiting}
		</>
	);

}
