import { SquareIcon } from '~/components';

export interface ChatSearchChipProps {
	value: string,
	label?: string,
	textLimit?: number,
	onRemove?: () => void,
}

const VALUE_TEXT_LIMIT = 20;

export const ChatSearchChip: React.FC<ChatSearchChipProps> = (props) => {

	const { label, value, textLimit = VALUE_TEXT_LIMIT, onRemove, } = props;

	return (
		<div className="search-chip">
			{!!label && <b>{label}:</b>}
			<p title={value}>
				{textLimit ?
				`${value.slice(0, textLimit)}${value.length >= textLimit ? '...' : ''}` :
				value
				}
			</p>
			{!!onRemove &&
			<SquareIcon
				icon="cross"
				plain
				onClick={onRemove} />
			}
		</div>
	);

}
