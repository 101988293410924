import { useRef } from 'react';
import { useHistory, useParams } from 'react-router';

import { useChats } from '~/store';
import { PageTitle } from '~/containers';
import { ChatFrame, ChatHead } from '~/containers/manage';
import { NotFound } from '~/components';
import { lang } from '~/constants';

export const Support: React.FC = () => {

	const { id } = useParams<{ id: string }>();

	const { goBack } = useHistory();

	const messagesRef = useRef<HTMLDivElement>(null);

	const { ticket } = useChats(id);

	if (!ticket) {
		return ticket === null ?
			<NotFound
				title={lang.NOT_FOUND_TICKET_TITLE}
				message={lang.NOT_FOUND_TICKET_MESSAGE} /> :
			null;
	}

	const Header = (
		<ChatHead
			ticket={ticket}
			isUserView />
	);

	return (
		<>
			<PageTitle
				title="Conversation"
				onBack={goBack}
				content={Header} />
			<ChatFrame
				ticket={ticket}
				isUserView
				messagesRef={messagesRef} />
		</>
	);

}
