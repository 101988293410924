import { Controller } from 'react-hook-form';

import { ControlledProps } from '../types';
import { InputButton, InputButtonProps } from './InputButton';

export type CInputButtonProps = InputButtonProps & ControlledProps;

export const CInputButton: React.FC<CInputButtonProps> = (props) => {

	const { name, rules, control, ...args } = props;

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			render={({ field, fieldState }) => {
				return (
					<InputButton
						state={fieldState}
						value={field.value}
						onChange={field.onChange}
						{...args} />
				);
			}} />
	);

}
