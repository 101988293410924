import tenants from '~/constants/tenants';
import { getCurrentTenant } from '~/services/tenants';

const [ CONFIG, apiURLKey ] = getCurrentTenant(tenants);
const { apiURL, chatURL, supportEmail: SUPPORT_EMAIL } = CONFIG;
const URL_BASE = `https://${apiURL[apiURLKey]}`;
const URL_CHAT = `https://${chatURL[apiURLKey]}`;

export const config = {
	URL_BASE,
	URL_REST: `${URL_BASE}/api/v1`,
	URL_CHAT,
	MAPS_KEY: process.env.REACT_APP_MAPS_KEY!,
	API_TOKEN: 'x-access-token',
	API_COOKIE: '@session_id',
	VIDEO_TYPE_LIMIT: ['mp4','quicktime'],
	IMAGE_TYPE_LIMIT: ['jpg','jpeg','png'],
	VIDEO_SIZE_LIMIT: 2044,
	VIDEO_DURATION_LIMIT: 30,
	SUPPORT_EMAIL,
	DESCRIPTION_LIMIT: 230,
	ENDORSEMENT_CONFIG: {
		MIN: 1.5,
		MAX: 99999999,
	},
	CONFIG,
};
