import { useCallback, useState } from 'react';
import FileSaver from 'file-saver';

import { config } from '~/constants';
import { Business, handleError, IAccount } from '~/services';
import { Button, Flag, Modal, ModalProps } from '~/components';

interface ReportState {
	type: 'pdf' | 'csv',
	sending?: boolean,
	loading?: boolean
}

export interface ExportStatsModalProps extends Omit<ModalProps, 'title'> {
	account?: IAccount,
}

export const ExportStatsModal: React.FC<ExportStatsModalProps> = (props) => {

	const { account, onHide, ...args } = props;

	const [ report, setReport ] = useState<ReportState | null>({
		type: 'csv',
	});

	const load = useCallback(
		async () => {

			if (!report?.type) {
				return;
			}

			setReport((val) => val && ({
				...val,
				loading: true,
			}));

			try {

				const { filename } = await Business.getStats({
					send: false,
					export: report?.type,
				}).promise;

				FileSaver.saveAs(
					config.URL_BASE + filename,
					`${report.type}-stat.${report.type}`
				);

			} catch (e) {

				handleError(e);

			} finally {

				setReport((val) => val && ({
					...val,
					loading: false,
				}));

			}

		},
		[ report?.type ]
	);

	const send = useCallback(
		async () => {

			if (!report?.type) {
				return;
			}

			setReport((val) => val && ({
				...val,
				sending: true,
			}));

			try {

				await Business.getStats({
					send: true,
					export: report?.type,
				}).promise;

				alert('You report has been sent');

			} catch (e) {

				handleError(e);

			} finally {

				setReport((val) => val && ({
					...val,
					sending: false,
				}));

			}

		},
		[ report?.type ]
	);

	if (!account) {
		return null;
	}

	return (
		<Modal
			icon="bigArrowRight"
			title="Export report"
			onHide={onHide}
			className="export--modal"
			{...args}>
			<>

				<div className="flags">
					<Flag
						onClick={setReport.bind(null, { type: 'csv' })}
						children="CSV"
						disabled={report?.loading || report?.sending}
						isSelected={report?.type === 'csv'} />
					<Flag
						onClick={setReport.bind(null, { type: 'pdf' })}
						children="PDF"
						disabled={report?.loading || report?.sending}
						isSelected={report?.type === 'pdf'} />
				</div>

				<Button
					label="Download report"
					onClick={load}
					variant="primary"
					loading={report?.loading} />

				<Button
					label="Send report to my email"
					onClick={send}
					variant="secondary"
					loading={report?.sending}
					disabled={!account.email}
					disabledDeep />

				{!!account.email &&
				<p
					children={`The report will be sent to address ${account.email}`}
					className="message" />
				}

				<Button
					label="Cancel"
					onClick={onHide} />

			</>
		</Modal>
	);

}
