import { useCallback, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';

import { useAuth } from '~/store';

import { Button } from './Button';
import { Input } from './Input';
import { Modal } from './Modal';

export interface ProfileDeleteProps {
	visible: boolean,
	onHide: () => void,
}

export const ProfileDelete: React.FC<ProfileDeleteProps> = (props) => {

	const { visible, onHide } = props;

	const [ confirmed, setConfirmed ] = useState(false);

	const [ value, setValue ] = useState('');

	const { deleteAccount, unAuthorize } = useAuth();

	const onAccountDelete = useCallback(
		async () => {

			await deleteAccount();

			confirmAlert({
				title: 'Your account has been deleted',
				buttons: [{
					label: 'OK',
					onClick: () => {
						unAuthorize(true);
					},
				}],
				closeOnEscape: false,
				closeOnClickOutside: false,
			});

		},
		[ deleteAccount, unAuthorize ]
	);

	const hides = () => {

		setValue('');

		setConfirmed(false);

		onHide();

	}

	return (
		<Modal
			icon="userBig"
			title="Delete Profile"
			onHide={hides}
			visible={visible}
			className="support--modal"
			children={
				confirmed ?
				<>
					<p className="message">Please enter "AGREE" in the input field, after confirm your data will be <b>irretrievably lost</b></p>
					<Input
						value={value}
						onChange={({ target }) => setValue(target.value)}
						placeholder={'Type "AGREE" here'} />
					<Button
						label="Confirm"
						style={{ marginTop: 18 }}
						onClick={onAccountDelete}
						variant="danger"
						disabled={value !== 'AGREE'}
						disabledDeep />
					<Button
						label="Cancel"
						onClick={hides} />
				</> :
				<>
					<h2 style={{ fontSize: 24, textAlign: 'center', marginBottom: 12, }}>Are you sure?</h2>
					<p className="message">Are you sure you want to delete your profile?</p>
					<Button
						label="I am sure"
						onClick={setConfirmed.bind(null, true)}
						variant="danger" />
					<Button
						label="Cancel"
						onClick={hides} />
				</>
			} />
	);

}
