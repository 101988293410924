import React, { SVGProps } from 'react';
import { config } from '~/constants';
import set0 from '~/constants/icons/icon-set0';
import set1 from '~/constants/icons/icon-set1';

type IconType =
	[ string, [ number, number ], React.ReactNode ] |
	[ [ number, number ], React.ReactNode ]

const Icons = config.CONFIG.iconSet === 0 ? set0 : set1;

export type IconKey = keyof typeof set0;

export interface IconProps extends SVGProps<SVGSVGElement> {
	name?: IconKey
}

export type IconArgs = IconProps['name'] | IconProps

export const asIcon = (
	icon: IconArgs | null | undefined,
	append?: Omit<IconProps, 'name'>
): IconProps => {

	if (!icon) {
		return {};
	}

	if (typeof icon !== 'string') {
		return { ...append, ...icon };
	}

	return { ...append, name: icon } as IconProps;

}

export const Icon: React.FC<IconProps> = (props) => {

	const { name, ...args } = props;

	if (!name) {
		return null;
	}

	const icon = Icons[name] as IconType;

	const [
		viewBox,
		[ width, height ],
		children
	] = icon.length === 2 ?
		[ `0 0 ${icon[0][0]} ${icon[0][1]}`, ...icon ] :
		icon;

	return (
		<svg
			width={width}
			height={height}
			viewBox={viewBox}
			children={children}
			fillRule="evenodd"
			clipRule="evenodd"
			{...args} />
	);

}
