import React, {
	useMemo,
	useState,
	useEffect,
	useCallback,
	PropsWithChildren as Props,
} from 'react';

import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

type OverItems<T extends string> = Record<T, {
	title?: string,
	className?: string
}>

type OverItemType<P> = {
	isShown: boolean,
	title?: string,
	payload?: P,
	className?: string
}

type OverItemsFull<T extends string, P> = Record<T, OverItemType<P>>

type OverlayCloseCallback<T> = (key: T) => boolean | Promise<boolean>

interface OverProps<T extends string, P> {
	items: OverItemsFull<T, P>,
	hide: (name: T) => void,
	hideAll: () => void,
	hideReduce: () => void
}

interface OverItemProps<T extends string, P> extends OverProps<T, P> {
	name: T,
	render: (payload?: P) => React.ReactNode
}

export const OverItem = <T extends string, P>(props: Props<OverItemProps<T, P>>) => {

	const {
		items,
		name,
		hide,
		render
	} = props;

	const {
		title,
		className,
		payload
	} = items[name];

	const _hide = () => hide(name);

	const _hideFuse = (e: React.MouseEvent) => e.stopPropagation();

	return (
		<CSSTransition
			in={items[name].isShown}
			timeout={200}
			mountOnEnter
			unmountOnExit>
			<div
				onClick={_hide}
				className="app-over-item">
				<div
					onClick={_hideFuse}
					className={cn('app-over-item__inner', className)}>
					{title && (
					<div className="over-item-title">
						<h4>{title}</h4>
						<button onClick={_hide}>
							<svg viewBox="0 0 14 14">
								<path d="M7.93982 6.99996L13.4665 1.47329C13.5757 1.34576 13.6328 1.18171 13.6263 1.01393C13.6198 0.846141 13.5503 0.686982 13.4315 0.568252C13.3128 0.449523 13.1536 0.379967 12.9859 0.373487C12.8181 0.367006 12.654 0.424077 12.5265 0.533294L6.99982 6.05996L1.47315 0.526628C1.34762 0.401092 1.17735 0.330566 0.999819 0.330566C0.822285 0.330566 0.652022 0.401092 0.526486 0.526628C0.40095 0.652163 0.330425 0.822426 0.330425 0.999961C0.330425 1.1775 0.40095 1.34776 0.526486 1.47329L6.05982 6.99996L0.526486 12.5266C0.456698 12.5864 0.400018 12.6599 0.360002 12.7426C0.319987 12.8254 0.2975 12.9154 0.293954 13.0073C0.290407 13.0991 0.305878 13.1906 0.339394 13.2762C0.372911 13.3617 0.423749 13.4394 0.488719 13.5044C0.553689 13.5694 0.631387 13.6202 0.716937 13.6537C0.802487 13.6872 0.894042 13.7027 0.985855 13.6992C1.07767 13.6956 1.16776 13.6731 1.25047 13.6331C1.33318 13.5931 1.40672 13.5364 1.46649 13.4666L6.99982 7.93996L12.5265 13.4666C12.654 13.5758 12.8181 13.6329 12.9859 13.6264C13.1536 13.62 13.3128 13.5504 13.4315 13.4317C13.5503 13.3129 13.6198 13.1538 13.6263 12.986C13.6328 12.8182 13.5757 12.6542 13.4665 12.5266L7.93982 6.99996Z" />
							</svg>
						</button>
					</div>
					)}
					{render(payload)}
				</div>
				<div className="app-over-bg" />
			</div>
		</CSSTransition>
	);

}

export const Over = <T extends string, P>(props: Props<OverProps<T, P>>) => {

	const { items, hideReduce, children } = props;

	const shown = useMemo(
		() => {
			return !!Object
				.values(items)
				.filter(v => (v as OverItemType<T>).isShown).length;
		},
		[ items ]
	);

	useEffect(() => {

		document.body.style.overflow = shown ? 'hidden' : 'auto';

		if (!shown) {
			return () => null;
		}

		const listener = (e: KeyboardEvent) => e.key === 'Escape' && hideReduce();

		document.addEventListener('keydown', listener);

		return () => document.removeEventListener('keydown', listener);

	}, [ shown, hideReduce ]);

	return createPortal(
		children,
		document.querySelector('#over')!
	);

}

export const useOverlay = <T extends string, P>(is: OverItems<T>, cb?: OverlayCloseCallback<T>) => {

	const [ items, setItems ] = useState(() => {

		const initItems = {} as OverItemsFull<T, P>

		for (const i in is) {
			initItems[i] = { ...is[i], isShown: false, };
		}

		return initItems;

	});

	const show = useCallback(
		(
			key: T,
			payload?: P
		) => setItems((is) => {
			return {
				...is,
				[key]: {
					...is[key],
					isShown: true,
					payload,
				},
			};
		}),
		[]
	);

	const hide = useCallback(
		async (key: T) => {

			const isShown = cb ? await cb(key) : true;

			setItems((is) => {
				return {
					...is,
					[key]: {
						...is[key],
						isShown: !isShown,
					},
				};
			});

		},
		[ cb ]
	);

	const hideAll = useCallback(
		() => {
			for (const i in items) {
				hide(i);
			}
		},
		[ hide, items ]
	);

	const hideReduce = useCallback(
		() => {
			const openItems = [] as T[];
			for (const i in items) {
				if (items[i].isShown) {
					openItems.push(i);
				}
			}
			const key = openItems.length - 1;
			openItems[key] && hide(openItems[key]);
		},
		[ hide, items ]
	);

	return {
		items,
		show,
		hide,
		hideAll,
		hideReduce,
		Over,
		OverItem
	};

}
