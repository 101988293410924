import dayjs from 'dayjs';
import { IRequest, REQUEST_STATUS, REQUEST_TYPE } from '~/services';
import { Legend, OptionView } from '~/components';

interface ModalRequestProps {
	request?: IRequest
}

export const ModalRequest: React.FC<ModalRequestProps> = (props) => {

	const { request } = props;

	if (!request) {
		return null;
	}

	const {
		id,
		type,
		status,
		reviewed,
		paid,
		payment,
		authorised,
		created_at,
		updated_at
	} = request;

	return (
		<Legend
			label="Request data">
			<OptionView label="ID" value={id} />
			<OptionView label="Type" value={REQUEST_TYPE[type][0]} />
			<OptionView label="Status" value={REQUEST_STATUS[status][0]} />
			<OptionView label="Is Reviewed" value={reviewed ? 'Reviewed' : 'Unreviewed'} />
			<OptionView label="Is Paid" value={paid ? 'Paid' : 'Unpaid'} />
			<OptionView label="Sender name" value={payment?.sender_name} />
			<OptionView label="Athlete name" value={payment?.athlete_name} />
			<OptionView label="Amount" value={`$${payment?.amount}`} />
			<OptionView label="Payout Status" value={payment?.payout_status ? 'Yes' : 'No'} />
			<OptionView label="Is Authorised" value={authorised ? 'Authorized' : 'Not authorized'} />
			<OptionView label="Created At" value={dayjs(created_at * 1000).format('MMM D, YYYY h:mm A')} />
			<OptionView label="Updated At" value={dayjs(updated_at * 1000).format('MMM D, YYYY h:mm A')} />
		</Legend>
	);

}
