import React from 'react';

import { config, lang } from '~/constants';
import { Button, Modal, ModalProps } from '~/components';

interface SupportModalProps extends ModalProps {
	message: string,
	subject?: string,
}

const MESSAGE = `${lang.SUPPORT_CONTACT_MESSAGE} ${config.SUPPORT_EMAIL}, or tap "Contact" to open your mail client`;

export const SupportModal: React.FC<SupportModalProps> = (props) => {

	const {
		message,
		subject = 'Customer support request',
		...modal
	} = props;

	const mailto = `mailto:${config.SUPPORT_EMAIL}?subject=${subject}&body=${message}`;

	return (
		<Modal {...modal} className="support--modal" icon="fancyQuestionMark">
			<>
				<p
					className="message"
					children={MESSAGE} />
				<Button
					to={mailto}
					label="Contact"
					variant="primary" />
				<Button
					label="Cancel"
					onClick={modal.onHide} />
			</>
		</Modal>
	);

}
