import { useCallback } from 'react';

import { Icon, IconProps } from './Icon';
import { HTMLButton } from '~/types';

interface SelectorButtonProps extends HTMLButton {
	icon: IconProps['name']
}

const SelectorButton: React.FC<SelectorButtonProps> = (props) => {

	const { icon, ...button } = props;

	return (
		<button {...button}>
			<Icon name={icon} />
		</button>
	);

}

export interface QuantitySelectorProps {
	amount: number,
	array?: number[],
	step?: number,
	min?: number,
	max?: number,
	prefix?: string,
	label?: string,
	onChange: (val: number) => void
}

export const QuantitySelector: React.FC<QuantitySelectorProps> = (props) => {

	const {
		amount,
		array,
		step = 10,
		min = 0,
		max = 100000,
		prefix = '',
		label,
		onChange
	} = props;

	const change = useCallback((type: 'dec' | 'inc') => {

		if (array) {

			const key = array.indexOf(amount);

			if (key === -1) {
				const _newAmount = array.reduce(
					(a, b) => Math.abs(b - amount) < Math.abs(a - amount) ? b : a
				);
				return onChange(_newAmount);
			}

			if (
				(type === 'dec' && key === 0) ||
				(type === 'inc' && key === array.length - 1)
			) {
				return;
			}

			return onChange(array[type === 'dec' ? key - 1 : key + 1]);

		}

		const _newAmount = type === 'dec' ?
			amount - step :
			amount + step;

		if (_newAmount < min || _newAmount > max) {
			return;
		}

		onChange(_newAmount);

	}, [ onChange, array, amount, step, min, max ]);

	return (
		<div className="app--quantity-selector">
			<SelectorButton
				icon="minusBig"
				onClick={() => change('dec')}
				disabled={array ? amount === array[0] : amount - step < min} />
			<div className="inner">
				<p className="amount" children={`${prefix}${amount}`} />
				{label && <p className="label" children={label} />}
			</div>
			<SelectorButton
				icon="plusBig"
				onClick={() => change('inc')}
				disabled={array ? amount === array[array.length - 1] : amount + step > max} />
		</div>
	);

}
