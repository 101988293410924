import { api } from '../api';
import { config } from '~/constants';

import { IAthleteInfo, IAthleteInfoStripped } from './Athlete';
import { IBusinessInfo } from './Business';
import { Option } from '~/types';
import { UserUpdateInput } from './Admin';
import { formatServices } from './Athlete';

export const UserRoles = {
	0: [ 'GUEST', 'Guest', 0 ],
	1: [ 'FAN', 'Fan', 1 ],
	2: [ 'ATHLETE', 'Athlete', 2 ],
	3: [ 'BUSINESS', 'Business', 3 ],
	4: [ 'ADMIN', 'Administrator', 4 ],
	11: [ 'GHOST1', 'Fan', 11 ],
	12: [ 'GHOST2', 'Athlete', 12 ],
	13: [ 'GHOST3', 'Business', 13 ],
} as const;

export const UserRolesArray: Option<UserType>[] = Object.values(UserRoles).splice(1, 4).map(
	([ , label, value ]) => ({ label, value })
);

export type UserType = keyof typeof UserRoles;

export type OAuthProvider = 'facebook' | 'apple' | 'instagram'

export interface IAccountBase {
	gender: string,
	first_name: string,
	last_name: string,
	profile_photo: string
}

export const socialLinks = {
	tiktok_link: [ 'Tiktok', 'SocialTT', 'tiktok_link', 'https://www.tiktok.com/@johndoe' ],
	twitter_link: [ 'Twitter', 'SocialTW', 'twitter_link', 'https://twitter.com/johndoe' ],
	snapchat_link: [ 'Snapchat', 'SocialSC', 'snapchat_link', 'https://t.snapchat.com/johndoe' ],
	facebook_link: [ 'Facebook', 'SocialFB', 'facebook_link', 'https://www.facebook.com/john.doe' ],
	instagram_link: [ 'Instagram', 'SocialIG', 'instagram_link', 'https://www.instagram.com/john_doe/' ],
} as const;

export const socialLinksArray = Object.values(socialLinks);

export type SocialLinksKeys = keyof typeof socialLinks;

export type SocialLinksForm = { [T in SocialLinksKeys]: string }

export interface IAccount extends IAccountBase {
	id: string,
	email: string,
	phone: string,
	gender: string,
	username: string,
	digital_id: number,
	email_set: boolean,
	user_type: UserType,
	password_set: boolean,
	profile_completed: boolean,
	verification_status: boolean,
	profile_description: string,
	intro_video: string,
	blocked: boolean,
	last_seen: number,
	created_at: number,
	updated_at: number,
	tiktok_link: string,
	twitter_link: string,
	snapchat_link: string,
	facebook_link: string,
	instagram_link: string,
	payment_source?: boolean
}

export interface IAccountAthlete extends IAccount {
	athlete_info: IAthleteInfo
}

export interface IAccountBusiness extends IAccount {
	business_info: IBusinessInfo
}

export type SignInInput = {
	email: string,
	password: string
}

export type SignUpInput = SignInInput & {
	eula: boolean,
	phone: string,
	user_type: UserType
}

export type SignUpAthleteInput = {
	athlete_personal_info: IAccountBase & {
		description: string,
		intro_video: string
	},
	athlete_info: Omit<IAthleteInfoStripped, 'badge' | 'athlete_partners' | 'featured'>
}

export type SignUpAthleteInputMid = {
	first_name?: string,
	last_name?: string,
	profile_photo?: string,
	intro_video?: string,
	legend?: boolean,
	gender?: string,
	school_id?: string,
	school_name?: string,
	school_email?: string,
	sports_played?: string,
	sports?: string,
	tiktok_link?: string,
	twitter_link?: string,
	snapchat_link?: string,
	facebook_link?: string,
	instagram_link?: string,
}

export type SignUpFanInput = IAccountBase

export type SignUpBusinessInput = {
	business_info: IBusinessInfo
}

export type UpdateInput = IAccountBase & {
	phone: string,
	user_type: UserType,
	intro_video: string,
	profile_description: string,
	athlete_info?: {
		school_id: string,
		school_name: string,
		school_email: string,
		sports_played: string,
		legend: boolean,
		sports: string,
		badge: string
	}
}

export type MediaPhotoInput = {
	photo: File,
	second_photo?: File
}

export type MediaVideoInput = {
	video: any
}

export const isFan = (account: IAccount): account is IAccount => {
	return !!account && [1,11].includes(account.user_type);
}

export const isAthlete = (account: any): account is IAccountAthlete => {
	return !!account && account.athlete_info !== undefined;
}

export const isBusiness = (account: any): account is IAccountBusiness => {
	return !!account && account.business_info !== undefined;
}

export const isCompleted = (account: UserUpdateInput) => {

	const {
		id,
		first_name,
		last_name,
		profile_photo,
		intro_video,
		athlete_info: {
			school_id,
			sports,
			athlete_services,
		},
	} = account;

	if (!first_name || !last_name) {
		return false;
	}

	if (!profile_photo || !intro_video) {
		return false;
	}

	if (!school_id || !sports) {
		return false;
	}

	const { enabled } = formatServices({
		athlete_id: id,
		appearance_type: '',
		...athlete_services,
	});

	if (enabled.length < 1) {
		return false;
	}

	return true;

}

export const Account = {

	signUp: (data: SignUpInput) => api.post<{
		account: Omit<IAccount, 'payment_source'>,
		session_token: string
	}>('/user/email-verifications', data),

	signUpAthleteMid: (data: SignUpAthleteInputMid) => api.post('/user/save-athlete-profile', data, { type: 'JSON' }),

	signUpAthlete: (data: SignUpAthleteInput) => api.post<{
		account: IAccount
	}>('/user/finish-registration', data, { type: 'JSON' }),

	signUpFan: (data: SignUpFanInput) => api.post<{
		account: Omit<IAccount, 'athlete_info'>
	}>('/user/fan/finish-registration', data, { type: 'JSON' }),

	signUpBusiness: (data: SignUpBusinessInput) => api.post<{
		account: any
	}>('/user/business/finish-registration', data, { type: 'JSON' }),

	signIn: (data: SignInInput) => api.post<{
		account: IAccount,
		session_token: string
	}>('/user/sign-ins', data, { type: 'FORM' }),

	signOut: () => api.del('/user/sessions'),

	read: () => api.get<{ account: IAccount }>('/user/accounts'),

	delete: () => api.del('/user/accounts/self'),

	oAuth: (i: OAuthProvider, state?: UserType) =>
		`${config.URL_REST}/auth/${i === 'facebook' ?
			`?provider=${i}${state ? `&state=${state}` : ''}` :
			`${i}${state ? `?state=${state}` : ''}`}`,

	media: {

		photo: (data: MediaPhotoInput) => api.post<{ path: string }>(
			'/user/upload-photo',
			data,
			{ type: 'DATA' },
		),

		video: (data: MediaVideoInput) => api.post<{ path: string }>(
			'/user/upload-intro',
			data,
			{ type: 'DATA' },
		),

	},

	resetPassword: (data: { email: string }) => api.del(
		'/user/accounts/passwords',
		data,
		{ type: 'DATA' }
	),

	resetPasswordConfirm: (data: {
		email: string,
		password: string,
		verificationCode: string
	}) => api.post(
		'/user/accounts/passwords',
		data,
		{ type: 'DATA' }
	),

	update: (data: UpdateInput) => api.put<{
		account: IAccount | IAccountAthlete | IAccountBusiness
	}>('/user/accounts', data, { type: 'JSON' }),

};
