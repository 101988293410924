import cn from 'classnames';

import { Avatar, Flag } from '~/components';
import { IAccount } from '~/services';

interface PushUserProps {
	account: IAccount,
	onSelect: () => void,
	isSelected?: boolean,
	isSelectable?: boolean,
}

export const PushUser: React.FC<PushUserProps> = (props) => {

	const { account, isSelected, isSelectable, onSelect } = props;

	const className = cn(
		'app--user-compact',
		{
			'is-selected': isSelected,
			'is-selectable': isSelectable,
		},
	);

	return (
		<div
			onClick={() => onSelect()}
			className={className}>
			<Avatar src={account.profile_photo} />
			<div className="content">
				<h4>{account.first_name} {account.last_name}</h4>
				<p>{account.email}</p>
			</div>
			<Flag isSelected={isSelected} />
		</div>
	);

}
