import { config } from '~/constants';

const reg = new RegExp('^/(videos|photos|assets)/', 'gi');

const { URL_BASE } = config;

export const mediaLink = (url: File | string, suffix?: 'S' | 'M'): string => {

	if (typeof url !== 'string') {
		return URL.createObjectURL(url);
	}

	if (suffix) {
		url = url.replace(/(.png|.jpe?g)$/, `_${suffix}$&`);
	}

	return url.match(reg) ? URL_BASE + url : url;

}
