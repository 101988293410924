import { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Store } from '~/store';
import { useFastAuth, useRoutes } from '~/hooks';
import { subscribeNotify } from '../services/subscribeNotify';
import { useSSE } from '~/services';
import { App } from '~/types';
import { config } from '~/constants';

export const AppRoutes: React.FC = () => {

	const [ { status, account, isGuest } ] = useSelector(
		(store: Store.Root) => [ store.auth, store.data ] as const
	);

	const { loading: fastAuthLoading } = useFastAuth('s');

	const { push } = useHistory();

	const dispatch = useDispatch<Store.Dispatch>();

	const { route, routes, pathname } = useRoutes(
		status,
		account?.user_type
	);

	useEffect(() => {

		dispatch.data.retrieve();

	}, [ dispatch ]);

	useEffect(() => {

		if (status !== 1 || isGuest) {
			return;
		}

		dispatch.notify.retrieve({ force: true });

	}, [ dispatch, status, isGuest ]);

	useEffect(() => {

		if (status === 1) {
			return;
		}

		dispatch.auth.retrieve();

	}, [ dispatch, status, pathname ]);

	useEffect(() => {

		if (status !== 1 || isGuest) {
			return;
		}

		const unsubscribe = subscribeNotify((event) => {

			if (event.type === 'notification') {
				dispatch.notify._append(event.payload);
			}

		});

		return () => unsubscribe();

	}, [ dispatch, status, isGuest ]);

	const onMessageEvent = useCallback(
		(payload: App.Chat.LivePayload) => {

			const currentTicketID = pathname.match(/^\/manage\/chat\/(.*)$/i)?.[1];

			switch (payload.type) {
				case 'message': {

					if (currentTicketID !== payload.object.reference_id) {
						toast.info('New support message', {
							onClick: () => push(`/manage/chat/${payload.object.reference_id}`),
							pauseOnFocusLoss: false,
						});
					}

					return dispatch.admin.CREATE_MESSAGE({
						message: payload.object,
					});

				}
				case 'ticket': {

					toast.info('New support ticket', {
						onClick: () => push(`/manage/chat/${payload.object.id}`),
							pauseOnFocusLoss: false,
					});

					return dispatch.admin.CREATE_TICKET({
						ticket: payload.object,
						timezone: '',
						end_time: '',
						weekdays: [],
						start_time: '',
						availability: false,
						availability_text: '',
					});

				}
				case 'message-read': {

					return dispatch.admin.SET_MESSAGES_READ_STATUS({
						id: payload.object.id,
						reference_id: payload.object.reference_id,
					});

				}
				case 'ticket-status-changed': {

					// toast.info('Ticket status has been updated', {
					// 	onClick: () => push(`/manage/chat/${payload.object.id}`),
					// });

					return dispatch.admin.SET_TICKET_STATUS({
						ticket: payload.object,
					});

				}
			}

		},
		[ dispatch, push, pathname ]
	);

	useSSE<App.Chat.LivePayload>({
		url: `${config.URL_CHAT}/live_chat`,
		skip: !account,
		onEvent: onMessageEvent,
	});

	if (status === 0 && fastAuthLoading) {
		return null;
	}

	const children = (
		<Switch>
			{routes.map((route, i) => (
			<Route key={i} {...route} />
			))}
			{routes[0] && <Redirect to={routes[0].path as string} />}
		</Switch>
	);

	if (route?.frame) {
		return <route.frame children={children} />
	}

	return children;

}
