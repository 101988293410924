import React from 'react';
import { Control } from 'react-hook-form';

import { App } from '~/types';
import { config, lang } from '~/constants';
import { IAthlete, RequestForm } from '~/services';
import { mediaLink } from '~/utils';

import { InputPrice } from './InputPrice';
import { SquareIcon } from './SquareIcon';

const { MIN, MAX } = config.ENDORSEMENT_CONFIG;

interface AthleteListedProps {
	athlete: IAthlete | App.User.Athlete,
	control?: Control<RequestForm.EndorsementCampaign>,
	onRemove?: (athlete: IAthlete, e: React.MouseEvent) => void,
}

export const AthleteListed: React.FC<AthleteListedProps> = (props) => {

	const { athlete, control, onRemove } = props;

	return (
		<div className="app--athlete-listed">
			<div className="col1">
				<img
					src={mediaLink(athlete.profile_photo, 'S')}
					alt={`${athlete.first_name} ${athlete.last_name}`} />
				<div className="name-wrap">
					<p
						children={`${athlete.first_name}\n${athlete.last_name}`}
						className="name" />
					{!athlete.endorsement_enabled &&
					<p
						children="Unavailable"
						className="availability-flag" />
					}
				</div>
			</div>
			<div className="col2">
				{!!control &&
				<InputPrice
					key={`athlete_prices.${athlete.id}`}
					name={`athlete_prices.${athlete.id}`}
					rules={{
						required: lang.ENDORSEMENT_PRICE_ERROR_REQUIRED,
						validate: (val) => {
							if (!Number(val)) {
								return lang.ENDORSEMENT_PRICE_ERROR_INVALID;
							}
							if (Number(val) < MIN || Number(val) >= MAX) {
								return `Please, enter amount between ${MIN} and ${MAX}`;
							}
							return true;
						}
					}}
					control={control}
					isSmall />
				}
				{!!onRemove &&
				<SquareIcon
					icon={{
						name: 'bin',
					}}
					onClick={(e) => onRemove(athlete, e)} />
				}
			</div>
		</div>
	);

}
