import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

export const useAthlete = (args: { id: string }) => {

	const [ athlete, loading ] = useSelector(
		({ athletes, loading }: Store.Root) => [
			athletes.athletesById[args.id],
			loading.effects.athletes
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	useEffect(() => {

		if (!args.id) {
			return;
		}

		dispatch.athletes.retrieve({ id: args.id });

	}, [ args.id, dispatch ]);

	return {
		loading,
		...athlete,
		...dispatch.athletes,
	};

}
