import { useCallback, useState } from 'react';

import { GenericLoading } from '../types';

export const useLoading = <T extends string>() => {

	const [ loading, setLoading ] = useState<GenericLoading<T>>({});

	const updateLoading = useCallback(
		(key: keyof typeof loading, state?: boolean) => {

			setLoading((val) => ({
				...val,
				[key]: typeof state === 'undefined' ?
					!val[key] :
					state,
			}));

		},
		[],
	);

	return [ loading, updateLoading ] as const;

}
