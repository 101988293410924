import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

export const useAuth = () => {

	const [ state, loading ] = useSelector(
		({ auth, loading }: Store.Root) => [
			auth,
			loading.effects.auth
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	return {
		...state,
		...dispatch.auth,
		loading,
	};

}
