import React from 'react';
import cn from 'classnames';
import { Controller } from 'react-hook-form';

import { Icon, IconArgs } from './Icon';
import { SquareIcon } from './SquareIcon';
import { ControlledField, getFieldState, HTMLSelect, Option } from '~/types';

interface SelectProps extends Omit<HTMLSelect, 'onChange'> {
	icon?: IconArgs | null,
	compact?: boolean,
	options: Option[],
	innerRef?: React.LegacyRef<HTMLSelectElement>,
	onChange?: (option: Option) => void
}

type Props = ControlledField<SelectProps>

const SelectInner: React.FC<Props['inner']> = (props) => {

	const {
		icon,
		value,
		compact,
		options,
		innerRef,
		onChange,
		defaultValue,
		...select
	} = props;

	const state = getFieldState(select);

	return (
		<div className={cn('app-select', { compact })}>
			{icon && (
			<SquareIcon icon={icon} />
			)}
			<div
				className="app-select--container"
				style={{ marginLeft: icon === null ? 60 : 0 }}>
				<select
					ref={innerRef}
					className="app-select--element"
					{...select}
					value={(value === -1 || value === undefined) ? options[0].value : value}
					onChange={({ target }) => {

						if (!onChange) {
							return;
						}

						const option = options.filter(
							(opt) => ((opt.value === -1 && opt.value === undefined && target.value === '') ||
								// eslint-disable-next-line
								opt.value == target.value)
						)[0];

						if (!option || option?.value === -1) {
							return onChange({
								...option,
								value: undefined
							});
						}

						onChange(option);

					}}>
					{options.map(({ value, label }, i) => (
					<option key={i} value={value} label={label} children={label} />
					))}
				</select>
				<Icon
					name="downChevron" />
				{state && !!state?.error?.message && (
				<p
					className="app-select--message"
					children={state.error.message} />
				)}
			</div>
		</div>
	);

}

export const Select: React.FC<Props['outer']> = (props) => {

	if (!('control' in props)) {
		return <SelectInner {...props} />
	}

	const {
		name = '',
		rules = {},
		control,
		defaultValue
	} = props;

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			defaultValue={defaultValue || props.options[0].value}
			render={({ field, fieldState }) => {

				const { ref, value, onChange } = field;

				return (
					<SelectInner
						{...props}
						value={value}
						state={fieldState}
						innerRef={ref}
						onChange={(option) => onChange(option.value)} />
				);

			}} />
	);

}
