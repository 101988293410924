import { App } from '~/types';
import { SquareIcon } from '~/components';
import { normalizeSize } from '~/services/chat';

export interface ChatAssetProps {
	type: App.Chat.AssetType,
	name: string,
	size: number,
	onFileRemove?: () => void,
}

const mimeToIcon = {
	image: 'fileImage',
	video: 'fileVideo',
	application: 'fileDocument',
	audio: 'fileAudio',
} as const

export const ChatAsset: React.FC<ChatAssetProps> = (props) => {

	const { type, name, size, onFileRemove, } = props;

	return (
		<div className="app--chat-message__asset">
			<SquareIcon icon={mimeToIcon[type]} />
			<div className="content">
				<h4>{name}</h4>
				<p>{normalizeSize(size)}</p>
			</div>
			{!!onFileRemove &&
			<SquareIcon
				icon="cross"
				size={32}
				plain
				onClick={onFileRemove} />
			}
		</div>
	);

}
