import cn from 'classnames';
import { useCallback, useState } from 'react';
import { useQueryParams } from 'use-query-params';
import { toast } from 'react-toastify';

import { App } from '~/types';
import { usePromoCodes } from '~/store';
import { Button, Pages, SquareIcon, Switch, useOverlay } from '~/components';
import { _confirm } from '~/services';
import { ModalPromoCode, ModalPromoCodeCreate } from '~/containers/manage';

export const PromoCodes: React.FC = () => {

	const [ isUpdated, setIsUpdated ] = useState(false);

	const [ query, setQuery ] = useQueryParams({
		page: {
			decode: (val) => val && !isNaN(+ val) ? + val : undefined,
			encode: (val) => val ? val.toString() : undefined,
		},
		archived: {
			decode: (val) => typeof val !== 'string' || val !== '1' ?
				undefined :
				true,
			encode: (val?: true) => val ? '1' : undefined,
		},
	});

	const { Over, OverItem, ...over } = useOverlay({
		display: {
			title: 'Promo Code',
			className: 'create display-promo-code',
		},
		create: {
			title: 'Manage promo code',
			className: 'create create-promo-code'
		},
	}, async (key) => {

		if (!isUpdated) {
			return true;
		}

		const confirmed = await _confirm.editLeave();

		if (confirmed) {
			setIsUpdated(false);
		}

		return confirmed;

	});

	const {
		loading,
		promoCodes,
		createPromoCode,
		updatePromoCode,
		deletePromoCode,
		togglePromoCodeState,
	} = usePromoCodes(query);

	const create = useCallback(
		(data: App.Endpoints.CreatePromoCode[0] | App.Endpoints.UpdatePromoCode[0], id?: string) => {

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await (id ?
						updatePromoCode({ id, ...data }) :
						createPromoCode(data)
					);

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

					over.hide('create');

				}),
				{
					pending: `${id ? 'Updating' : 'Creating new'} promo code`,
					success: `Promo code has been ${id ? 'updated' : 'created'}`,
					error: { render: ({ data }) => data },
				},
			);

		},
		[ createPromoCode, updatePromoCode, over ]
	);

	const remove = useCallback(
		async (id: string) => {

			if (!await _confirm.unitRemove('promo code')) {
				return;
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await deletePromoCode({ id });

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

				}),
				{
					pending: 'Deleting promo code',
					success: 'Promo code has been deleted',
					error: { render: ({ data }) => data },
				},
			);

		},
		[ deletePromoCode ]
	);

	const toggleState = useCallback(
		(code: App.Manage.PromoCode, key: 'archived' | 'enabled') => {

			const { archived: a, enabled: e } = code;

			const langMap = {
				archived: [
					`${a ? 'Un' : 'A'}rchiving`,
					`Successfully ${a ? 'un' : 'a'}rchived`,
				],
				enabled: [
					`${e ? 'Dis' : 'En'}abling`,
					`Successfully ${e ? 'dis' : 'en'}abled`,
				],
			};

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await togglePromoCodeState({ code, key });

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

				}),
				{
					pending: `${langMap[key][0]} promo code`,
					success: `${langMap[key][1]} promo code`,
					error: { render: ({ data }) => data },
				},
			);

		},
		[ togglePromoCodeState ]
	);

	return (
		<div className="manage-content">
			<div className="page-header">
				<h1>Promo Codes</h1>
				<div className="page-controls">
					<Button
						variant="primary"
						label="Add"
						onClick={() => over.show('create')} />
				</div>
			</div>
			<div className="page-middle">
				<div className="archive-switch">
					<Switch
						selected={query.archived || false}
						onClick={() => {
							setQuery((val) => ({
								...val,
								page: undefined,
								archived: !val.archived ? true : undefined,
							}));
						}} />
					<p>Show archived codes</p>
				</div>
			</div>
			<div className="users-list">
				{promoCodes.promo_code.map((promoCode) => (
				<div
					key={promoCode.id}
					onClick={() => over.show('display', promoCode)}
					className="users-list--item clickable">
					<SquareIcon icon="info" />
					<div className="user-content">
						<h6 className="push-name">{promoCode.id}</h6>
						<h4>{promoCode.code}</h4>
						<div className="promo-tags">
							{!!promoCode.archived &&
							<div className="promo-tag">Archived</div>
							}
							<div className={cn('promo-tag', { used: promoCode.enabled })}>
								{promoCode.enabled ? 'Enabled' : 'Disabled'}
							</div>
							<div className={cn('promo-tag', { used: promoCode.usage_count > 0 })}>
								{promoCode.usage_count > 0 ?
								`Used ${promoCode.usage_count} time${promoCode.usage_count > 1 ? 's' : ''}` :
								'Never used yet'
								}
							</div>
							{promoCode.total_discount_amount > 0 &&
							<div className="promo-tag">
								Current usage is ${promoCode.total_discount_amount.toFixed(2)}
							</div>
							}
						</div>
					</div>
					<div className="user-actions">
						<Button
							label={promoCode.archived ? 'Unarchive' : 'Archive'}
							onClick={(e) => {
								e.stopPropagation();
								toggleState(promoCode, 'archived')
							}} />
						<Button
							label={promoCode.enabled ? 'Disable' : 'Enable'}
							onClick={(e) => {
								e.stopPropagation();
								toggleState(promoCode, 'enabled');
							}} />
						<Button
							label="Update"
							onClick={(e) => {
								e.stopPropagation();
								over.show('create', promoCode);
							}} />
						<Button
							label="Remove"
							onClick={(e) => {
								e.stopPropagation();
								remove(promoCode.id);
							}} />
					</div>
				</div>
				))}
			</div>
			<div className="page-footer">
				<Pages
					pageCount={promoCodes.total_pages}
					forcePage={query.page || 0}
					onPageChange={({ selected: page }) => {
						setQuery((val) => ({ ...val, page }));
					}} />
			</div>
			<Over {...over}>
				<OverItem
					{...over}
					name="display"
					render={(payload) => (
						<ModalPromoCode
							onHide={() => over.hide('create')}
							promoCode={payload as App.Manage.PromoCode} />
					)} />
				<OverItem
					{...over}
					name="create"
					render={(payload) => (
						<ModalPromoCodeCreate
							onHide={() => over.hide('create')}
							loading={loading.createPromoCode}
							onCreate={create}
							promoCode={payload as App.Manage.PromoCode} />
					)} />
			</Over>
		</div>
	);

}
