import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '~/store';
import { PageContent, PageTitle } from '~/containers';

import { RequestCounters, handleError, Request } from '~/services';
import { Legend, BigAmount, OptionView, Button } from '~/components';
import { StatsHead } from '../containers/Stats';

export const LockerRoom: React.FC = () => {

	const { account } = useAuth();

	const { push } = useHistory();

	const [ data, setCounters ] = useState<RequestCounters | null>(null);

	const retrieve = useCallback(async (refresh?: boolean) => {

		try {

			const data = await Request.getStats().promise;

			setCounters(data);

		} catch (e) {

			handleError(e);

		}

	}, []);

	useEffect(() => {

		retrieve();

	}, [ retrieve ]);

	if (!data || !account) {
		return <PageTitle title="Locker Room" />;
	}

	const { counters, month_amount, week_amount, total_amount } = data;

	return (
		<>
			<PageTitle title="Locker Room" />
			<PageContent pageContentClassName="page--stats">
				<StatsHead
					account={account} />
				<Legend label="Total Earnings">
					<BigAmount amount={total_amount} />
					<>
						<OptionView
							label="Total earnings for the week"
							value={`$${week_amount}`} />
						<OptionView
							label="Total earnings for the month"
							value={`$${month_amount}`} />
					</>
				</Legend>
				<Legend label="Total Requests">
					<BigAmount
						amount={counters.total_personal_appearance + counters.total_shoutout + counters.total_camps + counters.total_endorsement + counters.total_speaking_engagements + counters.total_training_sessions + counters.total_video_chat}
						prefix="" />
					<>
						<OptionView
							label="Total of personal appearances"
							value={`${counters.total_personal_appearance}`} />
						<OptionView
							label="Total shoutouts"
							value={`${counters.total_shoutout}`} />
						<OptionView
							label="Total camps"
							value={`${counters.total_camps}`} />
						<OptionView
							label="Total endorsements"
							value={`${counters.total_endorsement}`} />
						<OptionView
							label="Total speaking engagements"
							value={`${counters.total_speaking_engagements}`} />
						<OptionView
							label="Total training sessions"
							value={`${counters.total_training_sessions}`} />
						<OptionView
							label="Total video chats"
							value={`${counters.total_video_chat}`} />
					</>
					<>
						<OptionView
							label="Number of requests for the week"
							value={`${counters.last_week}`} />
						<OptionView
							label="Number of requests for the month"
							value={`${counters.last_month}`} />
					</>
				</Legend>
				<Legend label="Pending Requests">
					<>
						<OptionView
							label="Pending personal appearances"
							value={`${counters.pending_personal_appearance}`} />
						<OptionView
							label="Pending shoutouts"
							value={`${counters.pending_shoutout}`} />
						<OptionView
							label="Pending camps"
							value={`${counters.pending_camps}`} />
						<OptionView
							label="Pending endorsements"
							value={`${counters.pending_endorsement}`} />
						<OptionView
							label="Pending speaking engagements"
							value={`${counters.pending_speaking_engagements}`} />
						<OptionView
							label="Pending training sessions"
							value={`${counters.pending_training_sessions}`} />
						<OptionView
							label="Pending video chats"
							value={`${counters.pending_video_chat}`} />
					</>
					<div className="legend-extender">
						<Button
							label="Open Request Feed"
							variant="primary"
							onClick={() => push('/requests')} />
					</div>
				</Legend>
			</PageContent>
		</>
	);

}
