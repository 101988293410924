import chroma from 'chroma-js';
import { TenantFont } from './types';

export function formatCamelCase (str: string) {
	return str.replace(
		/[A-Z]+(?![a-z])|[A-Z]/g,
		($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
	);
}

export function floatValue (value: number) {
	try {
		return + parseFloat(`${value}`).toFixed(2)
	} catch {
		return 0;
	}
}

export function parseColor (color: string) {

	const _color = chroma(color);
	const hslMap = ['h','s','l','a'] as const;
	// TODO: Wrong typings
	const hslColor = _color.hsl() as unknown as [number, number, number, number];
	const colorMap = hslColor.map((val, key) => {

		if (Number.isNaN(val)) {
			val = 0
		}

		const _key = hslMap[key];

		if (['l', 's'].includes(_key)) {
			val = val * 100;
		}

		val = floatValue(val);

		return [_key, `${val}${_key !== 'h' ? '%' : ''}`] as const;

	});

	return colorMap;

}

export function createHeadMeta (name: string, content?: string) {

	if (!content) {
		return;
	}

	const link = document.createElement('link');

	link.setAttribute('name', name);
	link.setAttribute('content', content);

	document.head.appendChild(link);

}

interface CreateHeadLinkParams {
	href: string,
	rel?: string,
	type?: string,
	size?: string,
}

export function createHeadLink (args: CreateHeadLinkParams) {

	const link = document.createElement('link');

	Object.entries(args)
		.forEach(([ attr, value ]) => {
			link.setAttribute(attr, value);
		});

	document.head.appendChild(link);

}

export function getFontLink (font: TenantFont) {

	const name = font.fontFamily.replace(' ', '+');
	const weights = font.weights.length ? `:wght@${font.weights.join(';')}` : '';
	const swap = font.swap ? `&display=swap` : '';

	const url = `https://fonts.googleapis.com/css2?family=${name}${weights}${swap}`;

	return url;

}

export function getMaxContrast (colors: string[]) {

	const contrasts = colors.map((color) => chroma.contrast(color, 'white'));

	return Math.max(...contrasts);

}
