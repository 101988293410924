import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from './useAppDispatch';

import { Store } from '../';

export function useRosters (id?: string | false) {

	const [
		data,
		dataById,
		loading,
	] = useSelector(
		({ rosters, loading }: Store.Root) => [
			rosters.rosters,
			(id ? rosters.rostersById[id] : undefined),
			loading.effects.rosters
		] as const
	);

	const { rosters } = useAppDispatch();

	useEffect(
		() => {

			if (id) {
				rosters.readRostersById(id);
			} else if (id !== false) {
				rosters.readRosters();
			}

		},
		[ id, rosters ]
	);

	return {
		data,
		dataById,
		loading,
		...rosters,
	}

}
