import cn from 'classnames';

import { HTMLButton } from '~/types';
import { Icon } from './Icon';

interface CheckProps extends Omit<HTMLButton, 'onClick'> {
	label?: string,
	checked: boolean,
	invalid?: boolean,
	onClick?: () => void
}

export const Check: React.FC<CheckProps> = (props) => {

	const { label, checked, invalid, children, ...button } = props;

	const classNames = cn('app--check', { checked, invalid, 'uses-children': !!children });

	return (
		<div className={classNames}>
			<button
				className="app--check-trigger" {...button}>
				<div>
					<Icon name="flag" />
				</div>
			</button>
			{children ?
			<div
				children={children}
				className="app--check-content" /> :
			<p
				onClick={button.onClick}
				className="app--check-label"
				children={label} />
			}
		</div>
	);

}
