import { useHistory } from 'react-router-dom';

import { useAuth } from '~/store';
import { PageContent, PageTitle } from '~/containers';
import { AthleteHeader } from '~/components';

import { isAthlete } from '~/services';
import { ProfileFan, ProfileAthlete } from '~/containers/Profile';

export const Profile: React.FC = () => {

	const { account } = useAuth();

	const { push } = useHistory();

	if (!account) {
		return null;
	}

	return (
		<>
			<PageTitle title={`${account?.first_name} ${account?.last_name}`} />
			<PageContent
				pageContentClassName="page--athlete-profile">
				<AthleteHeader
					onEdit={() => push('/settings/info')}
					athlete={account}
					profile />
				{isAthlete(account) ?
					<ProfileAthlete account={account} /> :
					<ProfileFan account={account} />
				}
			</PageContent>
		</>
	);

}
