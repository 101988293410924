import { Chat } from './Chat';
import { Util } from './Util';
import { Manage } from './Manage';

import {
	UserType,
	IRequest,
	RequestType,
	IAccount,
	IAccountAthlete,
	IAccountBusiness,
} from '~/services';

export namespace Endpoints {

	export const endpoints = 'endpoints';

	/* Chat
	 */
	export type ReadTicketsManage = [
		{
			subject?: string,
			user_id?: string,
			ticket_id?: string,
			order?: Chat.TicketOrder,
			archived?: boolean,
		},
		{
			tickets: Chat.TicketStripped[],
		}
	]

	export type CreateTicket = [
		{
			user_id?: string,
			subject: string,
			comment?: string,
		},
		{
			ticket: Omit<Chat.Ticket, 'messages'>,
			timezone: string,
			end_time: string,
			weekdays: string[],
			start_time: string,
			availability: boolean,
			availability_text: string,
		}
	]

	export type ReadTickets = [
		{
			archived?: boolean,
		},
		{
			tickets: Chat.TicketStripped[],
		}
	]

	export type ReadChatUsers = [
		null,
		{
			users: Chat.User[],
		}
	]

	export type ReadTicket = [
		null,
		{
			ticket: Chat.Ticket,
		}
	]

	export type SetTicketStatus = [
		{
			id: string,
			status: Chat.TicketStatus,
			comment?: string,
		},
		{
			ticket: Chat.TicketStripped,
		}
	]

	export type CreateMessage = [
		{
			text: string,
			assets?: string,
			reference_id: string,
		},
		{
			message: Chat.Message,
		}
	]

	export type CreateAsset = [
		{
			file: File,
		},
		{
			asset: Chat.Asset,
		}
	]

	export type ReadMeta = [
		null,
		{
			meta: Chat.QA[],
		}
	]

	export type SetMessageStatus = [
		{
			id: number,
			reference_id: string,
		},
		null
	]


	/* User Management
	 */
	export type SyncUser = [
		{
			user_id: string,
		},
		null
	]

	export type SetTrusted = [
		{
			user_id: string,
			trusted: boolean,
		},
		null
	]

	export type SetFeatured = [
		{
			user_id: string,
			featured: boolean,
		},
		null
	]


	/* Custom Pushes
	 */
	export type ReadPush = [
		{
			request?: string,
		},
		{
			custom_push: Manage.Push[],
		}
	]

	export type CreatePush = [
		Util.DeepWritableObject<Manage.Push>,
		{
			custom_push: Manage.Push,
		}
	]

	export type UpdatePush = [
		{
			id: string,
		} & Util.DeepWritableObject<Manage.Push>,
		{
			custom_push: Manage.Push,
		}
	]

	export type DeletePush = [
		{
			id: string,
		},
		null
	]

	export type SendPush = [
		{
			id?: string,
			subject?: string,
			message?: string,
			users_id?: string[],
			user_types_id?: UserType[],
		},
		null
	]


	/* Promo Codes
	 */
	export type CreatePromoCode = [
		Util.DeepWritableObject<Manage.PromoCode>,
		{
			promo_code: Manage.PromoCode,
		}
	]

	export type ReadPromoCode = [
		{
			page?: number,
			archived?: boolean,
		},
		{
			page: number,
			promo_code: Manage.PromoCode[],
			total_pages: number,
		}
	]

	export type UpdatePromoCode = [
		{
			id: string,
		} & Util.DeepWritableObject<Manage.PromoCode>,
		{
			promo_code: Manage.PromoCode,
		}
	]

	export type DeletePromoCode = [
		{
			id: string,
		},
		null
	]

	export type SetPromoCodeStatus = [
		{
			code: Manage.PromoCode,
			key: 'archived' | 'enabled',
		},
		null
	]


	/* Payouts
	 */
	export type ReadPayouts = [
		null,
		{
			balance: string,
			athletes_for_payout: Manage.PayoutAthlete[],
		}
	]

	export type ReadSentPayouts = [
		{
			page?: number,
			order?: 'desc' | 'asc',
		},
		{
			page: number,
			total_pages: number,
			payouts_list: Manage.Payout[],
		}
	]

	export type SendPayouts = [
		{
			phone: string,
			email: string,
			amount: number,
			athlete_id: string,
		}[],
		null
	]

	export type SetPayoutStatus = [
		{
			id: string,
			status: boolean,
			amount: number,
			user_id: string,
			campaign_id?: string
		}
	]


	/* Users
	 */
	export type FilterFeature = 'featured' | 'legend'

	export type FilterCompleteness = 0 | 1 | 2

	export type UsersRead = [
		{
			page: number,
			request?: string,
			page_size: number,
			user_type?: UserType,
			feature_filter?: FilterFeature,
			profile_completed?: FilterCompleteness,
		},
		{
			page: number,
			users: (IAccount & { pending_payouts: number })[],
			total_pages: number,
		}
	]

	export type UserRead = [
		{
			user_id: string,
		},
		{
			amount: number,
			account: IAccount | IAccountAthlete | IAccountBusiness,
			requests: [],
		}
	]

	export type UserCreate = [
		{
			email: string,
			role: UserType,
		},
		{
			user: IAccount,
		}
	]

	export type ReadUsersRequests = [
		{
			page: number,
			user_id: string,
			page_size: number,
			request_type?: RequestType,
			payout_status?: 1 | 0,
		},
		{
			page: number,
			amount: number,
			requests: IRequest[],
			total_pages: number,
		}
	]

	export type ReadUsersRequest = [
		{
			id: string,
		},
		{
			type: 'request' | 'campaign',
			requests: IRequest[],
		}
	]

}
