import { api } from '../api';

export interface Tenant {
	id: string,
	name: string,
	logo: string,
	web_url: string,
	base_url: string,
	sso_name: string,
	tenant_key: string,
	created_at: number,
	updated_at: number,
	tenant_secret: string,
}

export interface TenantStatistic {
	users_count: number,
	requests_count: number,
	paid_requests_amount: number,
	pending_requests_amount: number,
}

export interface TenantsReadOutput {
	tenants: Tenant[],
	tenant_stats: Record<string, TenantStatistic>,
}

export interface TenantWithStats extends Tenant {
	stats: TenantStatistic,
}

export interface TenantSignUpOutput {
	session: string,
}

export const combineTenantStats = (
	{
		tenants,
		tenant_stats,
	}: TenantsReadOutput
): TenantWithStats[] => {

	return tenants.map((tenant) => ({
		...tenant,
		stats: tenant_stats[tenant.id],
	}));

}

export const Tenants = {

	read: () => api.get<TenantsReadOutput>('/mt/list'),

	signUp: (id: string) => api.get<TenantSignUpOutput>(`/mt/get_session_for_tenant?tenant_id=${id}`),

	combineTenantStats,

};
