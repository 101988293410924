import { useCallback, useEffect } from 'react';

import { App } from '~/types';
import { useChats } from '~/store';

import { ChatHead } from './ChatHead';
import { ChatMessages } from './ChatMessages';
import { ChatForm } from './ChatForm';

export interface ChatFrameProps {
	ticket: App.Chat.Ticket,
	isUserView?: boolean,
	messagesRef: React.RefObject<HTMLDivElement>,
}

export const ChatFrame: React.FC<ChatFrameProps> = (props) => {

	const { ticket, isUserView, messagesRef, } = props;

	const { createMessage, loading, setChatRead, } = useChats(ticket.id);

	const onMessage = useCallback(
		async (text: string, assets?: App.Chat.AssetMeta) => {

			if (!ticket) {
				return;
			}

			await createMessage({
				text,
				assets: JSON.stringify(assets),
				reference_id: ticket.id,
			});

		},
		[ ticket, createMessage ]
	);

	useEffect(
		() => {

			if (isUserView) {
				return window.scrollTo(0, document.body.scrollHeight);
			}

			const el = messagesRef.current;
						el?.scrollTo(0, el.scrollHeight);

		},
		[ ticket.messages, isUserView, messagesRef ]
	);

	useEffect(
		() => void setChatRead(),
		[ ticket, setChatRead ]
	);

	return (
		<>
			{!isUserView &&
			<ChatHead
				ticket={ticket} />
			}
			<ChatMessages
				ticket={ticket}
				isUserView={isUserView} />
			<ChatForm
				ticket={ticket}
				isLoading={loading.createMessage}
				onMessage={onMessage} />
		</>
	);

}
