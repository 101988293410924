import { init } from '@rematch/core';
import loadingPlugin from '@rematch/loading';
import immerPlugin from '@rematch/immer';

import { Store, models } from './models';

export const store = init<Store.General, Store.Full>({
	models,
	plugins: [
		loadingPlugin(),
		immerPlugin(),
	],
});
