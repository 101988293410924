import { IAccount } from '~/services';
import { mediaLink } from '~/utils';

interface StatsHeadProps {
	account?: IAccount,
}

export const StatsHead: React.FC<StatsHeadProps> = (props) => {

	const { account } = props;

	return (
		<div className="app--stats-head">
			{account ?
			<img
				src={mediaLink(account.profile_photo, 'S')}
				alt={`${account.first_name} ${account.last_name}`} /> :
			<div className="img-placeholder" />
			}
			{!!account &&
			<>
			<h2>{account.first_name} {account.last_name}</h2>
			<p>{account.email}</p>
			</>
			}
		</div>
	);

}
