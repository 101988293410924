import { useHistory } from 'react-router-dom';

import { EndoresmentAthlete, Legend } from '~/components';
import { RequestViewComponent as RWC, statusMap } from '~/services';
import { RequestInfo } from '../Request';

export const EndorsementCampaign: RWC<'endorsement_campaign'> = (props) => {

	const { request } = props;

	const { push } = useHistory();

	const { endorsement } = request.requests[0];

	return (
		<>
			<Legend
				label="Endorsement task"
				children={endorsement.task} />
			<RequestInfo
				request={request} />
			{!!endorsement.link && (
			<Legend
				label="Endorsement link"
				children={endorsement.link} />
			)}
			<Legend label="Athletes">
				{request.requests.sort(
					(a, b) => statusMap[a.status].priority > statusMap[b.status].priority ? -1 : 1
				).map((request, i) => (
				<EndoresmentAthlete
					key={i}
					request={request}
					onReview={(request) => push(`/request/${request.id}`)} />
				))}
			</Legend>
		</>
	);

}
