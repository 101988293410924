import { lang } from '~/constants';
import { toTitleCase } from '~/utils';
import { ICampaign, IRequest } from '~/services';
import { Legend, OptionView } from '~/components';

export interface RequestInfoProps {
	request: IRequest | ICampaign,
}

export const RequestInfo: React.FC<RequestInfoProps> = (props) => {

	const {
		request: {
			name,
			social_media_platform
		}
	} = props;

	if (!name && !social_media_platform) {
		return null;
	}

	return (
		<Legend
			label={lang.REQUEST_INFO_LABEL}
			children={[
				<OptionView
					key={0}
					label={lang.REQUEST_NAME_LABEL}
					value={name}
					vertical />,
				!!social_media_platform &&
					<OptionView
						key={1}
						label={lang.REQUEST_PLATFORM_LABEL}
						value={toTitleCase(social_media_platform.split(',').join(', '))} />
			]} />
	);

}
