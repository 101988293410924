import dayjs from 'dayjs';

import { RequestViewComponent as RWC } from '~/services';
import { Legend, OptionView } from '~/components';

import { RequestEarnings, RequestInfo, RequestPerson } from '../Request';
import { pluralize } from '~/utils';

export const Camp: RWC<'camp'> = (props) => {

	const { request: { profile, ...request } } = props;

	const { camp } = request;

	const time = dayjs(camp.start_time * 1000);
	const timeEnd = dayjs(camp.end_time * 1000);

	return (
		<>
			<RequestInfo
				request={request} />
			<RequestPerson
				profile={profile}
				request={request} />
			<Legend
				label="Event description"
				children={camp.event_description} />
			<Legend
				label="Special conditions"
				children={camp.special_conditions} />
			<RequestEarnings
				request={request}
				profile={profile}>
				<OptionView
					label="Location"
					value={camp.location.address} />
				<OptionView
					label="Duration"
					value={pluralize(camp.duration, 'hour')} />
				<OptionView
					label="Date"
					value={time.format('MMMM, D YYYY')} />
				<OptionView
					label="Time"
					value={`${time.format('h:mm a')} - ${timeEnd.format('h:mm a')}`} />
			</RequestEarnings>
		</>
	);

}
