import dayjs from 'dayjs';

import { RequestViewComponent as RWC } from '~/services';
import { Legend, OptionView } from '~/components';

import { RequestPerson, RequestEarnings, RequestInfo } from '../Request';

export const SpeakingEngagement: RWC<'speaking_engagement'> = (props) => {

	const { request: { profile, ...request } } = props;

	const { speaking_engagement } = request;

	const time = dayjs(speaking_engagement.start_time * 1000);
	const timeEnd = dayjs(speaking_engagement.end_time * 1000);

	return (
		<>
			<RequestInfo
				request={request} />
			<RequestPerson
				profile={profile}
				request={request} />
			<Legend
				label="Topics"
				children={speaking_engagement.topics} />
			<Legend
				label="Special message"
				children={speaking_engagement.special_message} />
			<Legend
				label="Special conditions"
				children={speaking_engagement.special_conditions} />
			<RequestEarnings
				request={request}
				profile={profile}>
				<OptionView
					label="Location"
					value={speaking_engagement.location.address} />
				<OptionView
					label="Date"
					value={time.format('MMMM, D YYYY')} />
				<OptionView
					label="Time"
					value={`${time.format('h:mm a')} - ${timeEnd.format('h:mm a')}`} />
			</RequestEarnings>
		</>
	);

}
