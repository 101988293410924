import cn from 'classnames';

import { StateDispatch } from '~/types';

import { Switch } from './Switch';

interface CollapsableProps {
	title: string,
	selected: boolean,
	setSelected: StateDispatch<boolean>
}

export const Collapsable: React.FC<CollapsableProps> = (props) => {

	const {
		title,
		selected,
		setSelected,
		children
	} = props;

	const classNames = cn('app--collapsable', { selected });

	return (
		<div className={classNames}>
			<div className="app--collapsable-head">
				<h6 children={title} />
				<Switch
					selected={selected}
					onClick={() => setSelected(val => !val)} />
			</div>
			<div
				className="app--collapsable-body"
				children={children} />
		</div>
	);

}
