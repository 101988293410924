import { ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { App } from '~/types';
import { lang } from '~/constants';
import { useRosters } from '~/store';
import { PageContent, PageTitle } from '~/containers';
import { confirm, Rosters, saveCSV } from '~/services';
import { AthleteListed, Button, Input, Legend, NotFound } from '~/components';
import { AthletesPicker, _pushOrRemove } from '~/containers/AthletesPicker';

export const Roster: React.FC = () => {

	const { id } = useParams<{ id: string }>();

	const { goBack, replace, push } = useHistory();

	const { dataById, updateRoster, deleteRoster, loading, } = useRosters(id);

	const inputRef = useRef<HTMLInputElement>(null);

	const [ picker, setPicker ] = useState(false);

	const [ name, setName ] = useState('');

	const [ athletes, setAthletes ] = useState<App.User.Athlete[]>([]);

	const [ isDirty, setIsDirty ] = useState(false);

	const isValid = useMemo(
		() => !!name.length && !!athletes.length,
		[ name, athletes ]
	);

	const onInputChange = useCallback<
		ChangeEventHandler<HTMLInputElement>
	>(
		(e) => {

			setName(e.target.value);

			setIsDirty(true);

		},
		[]
	);

	useEffect(
		() => {

			if (!dataById) {
				return;
			}

			setName(dataById.name);

			setAthletes(dataById.athletes);

		},
		[ dataById ]
	);

	const onAthleteClick = useCallback(
		(athlete: App.User.Athlete, e: React.MouseEvent) => {

			e.preventDefault();

			e.stopPropagation();

			setAthletes((val) => _pushOrRemove(val, athlete));

			setIsDirty(true);

		},
		[]
	);

	const onSave = useCallback(
		async () => {

			if (!isDirty || !isValid) {
				return;
			}

			const athletes_id = athletes.map(({ id }) => id).join(',');

			const result = await updateRoster({
				id,
				name,
				athletes_id,
			});

			if ('error' in result) {
				return alert(result.error?.message || lang.ROSTER_UPDATE_ERROR);
			}

			setIsDirty(false);

		},
		[ isDirty, isValid, id, name, athletes, updateRoster ]
	);

	const [ isExtracting, setIsExtracting ] = useState(false);

	const extract = useCallback(
		async () => {

			if (!dataById) {
				return;
			}

			const name = `export_${dataById.name.replace(/[/\\?%*:|"<> ]/g, '_')}_${dayjs().format('MM_DD_YYYY')}.csv`;

			if (await confirm({
				title: lang.ROSTER_EXPORT_TITLE,
				message: `${lang.ROSTER_EXPORT_MESSAGE} ${name}`,
				buttonCancelText: 'Cancel',
				buttonConfirmText: 'Export',
			})) {

				setIsExtracting(true);

				const csv = await Rosters.export({ id }).promise;

				await saveCSV(csv, name);

				setIsExtracting(false);

			}

		},
		[ id, dataById ]
	);

	const onDelete = useCallback(
		async () => {

			if (await confirm({
				title: lang.ROSTER_DELETE_TITLE,
				message: lang.ROSTER_DELETE_MESSAGE,
				buttonCancelText: 'Cancel',
				buttonConfirmText: 'Delete',
			})) {

				await deleteRoster({ id });

				replace('/business/rosters');

			}

		},
		[ id, deleteRoster, replace ]
	);

	if (picker) {
		return (
			<AthletesPicker
				note={lang.ROSTER_CREATE_NOTE}
				shown={picker}
				onHide={setPicker.bind(null, false)}
				selected={athletes}
				hideRates
				onAthleteClick={onAthleteClick} />
		);
	}

	if (!dataById) {
		return dataById === null ?
			<NotFound
				title={lang.ROSTER_NOT_FOUND_TITLE}
				message={lang.ROSTER_NOT_FOUND_MESSAGE} /> :
			null;
	}

	return (
		<>

			<PageTitle
				title="Roster"
				onBack={goBack} />

			<PageContent
				pageContentClassName="roster-form">

				<Input
					icon="Whistle"
					value={name}
					innerRef={inputRef}
					onChange={onInputChange}
					placeholder="Roster name" />

				<Legend
					label="Athletes">
					{!!athletes.length && athletes.map((athlete, i) => (
					<AthleteListed
						key={i}
						athlete={athlete}
						onRemove={onAthleteClick} />
					))}
					<div className="legend-extender">
						<Button
							label={`Add ${athletes.length > 0 ? 'more ' : ''}athletes`}
							onClick={setPicker.bind(null, true)}
							variant="secondary" />
					</div>
				</Legend>

				<Button
					label="Save"
					onClick={onSave}
					variant="primary"
					loading={loading.updateRoster}
					disabled={loading.updateRoster || !isDirty || !isValid}
					disabledDeep />

				<Button
					label="Create Campaign"
					onClick={() => push(`/request-form/${dataById.id}/endorsement_campaign`)} />

				<Button
					label="Export"
					onClick={extract}
					loading={isExtracting}
					disabled={isExtracting}
					disabledDeep />

				<Button
					label="Delete"
					variant="danger"
					onClick={onDelete}
					loading={loading.deleteRoster}
					disabled={loading.deleteRoster}
					disabledDeep />

			</PageContent>

		</>
	);

}
