import { Icon } from '~/components';
import { App } from '~/types';
import { ChatTicketManage } from './ChatTicketManage';

export interface ChatHeadProps {
	ticket: App.Chat.Ticket,
	isUserView?: boolean,
}

export const ChatHead: React.FC<ChatHeadProps> = (props) => {

	const { ticket, isUserView } = props;

	return (
		<div className="app--chat-head">
			<div className="app--chat-head__content">
				<Icon name="info" />
				<p>{ticket.subject}</p>
			</div>
			{isUserView &&
			<ChatTicketManage
				ticket={ticket}
				isUserView={isUserView} />
			}
		</div>
	);

}
