import { ProtectedRouteProps } from '~/services';

import { Frame, FrameManage } from '~/containers';
import { config } from '~/constants';

import * as Routes from '~/routes';
import * as Settings from '~/routes/settings';
import * as RoutesManage from '~/routes/manage';
import * as RoutesTenants from '~/routes/tenants';
import * as BusinessRoutes from '~/routes/business';

export enum Route {
	Login = '/login',
	Restore = '/restore',
	Signup = '/signup',
	Dashboard = '/dashboard',
	Request = '/request/:id',
	Requests = '/requests',
	Profile = '/profile',
	Notifications = '/notifications',
	LockerRoom = '/locker-room',
	Athlete = '/p/@:id',
	AthletePreview = '/@:id',
	RequestForm = '/request-form/:id/:type(personal_appearance|speaking_engagement|training_session|endorsement|endorsement_campaign|video_chat|shoutout|camp)',
	Chat = '/chat/:id',
	Support = '/support/:id',

	SettingsInfo = '/settings/info',
	SettingsLinks = '/settings/links',
	SettingsSurvey = '/settings/survey',
	SettingsServices = '/settings/services',

	BusinessStats = '/business/stats',
	BusinessRosters = '/business/rosters',
	BusinessRosterCreate = '/business/create-roster',
	BusinessRoster = '/business/roster/:id',

	ManageUsers = '/manage/users',
	ManageUser = '/manage/user/:id',
	ManageRequest = '/manage/request/:id',
	ManageStats = '/manage/stats',
	ManagePayouts = '/manage/payouts',
	ManagePushes = '/manage/pushes',
	ManageSentPayouts = '/manage/sent-payouts',
	ManagePromoCodes = '/manage/promo-codes',
	ManageAnalyticsProfiles = '/manage/analytics/profiles',
	ManageAnalyticsSessions = '/manage/analytics/sessions',
	ManageChats = '/manage/chats',
	ManageChat = '/manage/chat/:id',
	ManageTenants = '/tenants',

}

export const routes: ProtectedRouteProps[] = [
	{
		path: Route.Login,
		roles: -1,
		component: Routes.Login,
	},
	{
		path: Route.Restore,
		roles: -1,
		component: Routes.Restore,
	},
	{
		path: Route.Signup,
		roles: -1,
		component: Routes.SignUp,
	},
	{
		path: Route.Signup,
		roles: [11, 12, 13],
		component: Routes.SignUpForm,
	},
	{
		path: Route.Dashboard,
		roles: [0, 1, 2, 3],
		frame: Frame,
		hasMenu: true,
		component: Routes.Dashboard,
	},
	{
		path: Route.LockerRoom,
		roles: [2],
		frame: Frame,
		hasMenu: true,
		component: Routes.LockerRoom,
	},
	{
		path: Route.BusinessStats,
		roles: [3],
		frame: Frame,
		hasMenu: true,
		component: BusinessRoutes.Stats,
	},
	{
		path: Route.BusinessRosters,
		roles: [3],
		frame: Frame,
		hasMenu: true,
		component: BusinessRoutes.Rosters,
	},
	{
		path: Route.BusinessRosterCreate,
		roles: [3],
		frame: Frame,
		hasMenu: true,
		component: BusinessRoutes.RosterForm,
	},
	{
		path: Route.BusinessRoster,
		roles: [3],
		frame: Frame,
		hasMenu: true,
		component: BusinessRoutes.Roster,
	},
	{
		path: Route.Requests,
		roles: [1, 2, 3],
		frame: Frame,
		hasMenu: true,
		component: Routes.Requests,
	},
	{
		path: Route.Notifications,
		roles: [1, 2, 3],
		frame: Frame,
		hasMenu: true,
		component: Routes.Notifications,
	},
	{
		path: Route.Profile,
		roles: [1, 2, 3],
		frame: Frame,
		hasMenu: true,
		component: Routes.Profile,
	},
	{
		path: Route.SettingsInfo,
		roles: [1, 2, 3],
		frame: Frame,
		component: Settings.Info,
	},
	{
		path: Route.SettingsLinks,
		roles: [2],
		frame: Frame,
		component: Settings.Links,
	},
	{
		path: Route.SettingsSurvey,
		roles: [2],
		frame: Frame,
		component: Settings.Survey,
	},
	{
		path: Route.SettingsServices,
		roles: [2],
		frame: Frame,
		component: Settings.Services,
	},
	{
		path: Route.Request,
		roles: [1, 2, 3],
		frame: Frame,
		component: Routes.Request,
	},
	{
		// path: `/@:id(${usernameRegex})`,
		path: Route.AthletePreview,
		roles: [0, 1, 2, 3],
		frame: Frame,
		component: Routes.AthletePreview,
	},
	{
		// path: `/p/@:id(${usernameRegex})`,
		path: Route.Athlete,
		roles: [0, 1, 2, 3],
		frame: Frame,
		component: Routes.Athlete,
	},
	{
		path: Route.RequestForm,
		roles: [1, 3],
		frame: Frame,
		component: Routes.RequestForm,
	},
	{
		path: Route.Chat,
		roles: [1, 2, 3],
		frame: Frame,
		component: Routes.Chat,
	},
	{
		path: Route.Support,
		roles: [2],
		frame: Frame,
		component: Routes.Support,
	},
	{
		path: Route.ManageUsers,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Users,
	},
	{
		path: Route.ManageUser,
		exact: true,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.User,
	},
	{
		path: Route.ManageRequest,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Request,
	},
	{
		path: Route.ManageStats,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Stats,
	},
	{
		path: Route.ManagePayouts,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Payouts,
	},
	{
		path: Route.ManagePushes,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Pushes,
	},
	{
		path: Route.ManageSentPayouts,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.SentPayouts,
	},
	{
		path: Route.ManagePromoCodes,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.PromoCodes,
	},
	{
		path: Route.ManageAnalyticsProfiles,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Analytics,
	},
	{
		path: Route.ManageAnalyticsSessions,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.AnalyticsSessions,
	},
	{
		path: Route.ManageChats,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Chats,
	},
	{
		path: Route.ManageChat,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Chat,
	},
];

if (config.CONFIG.features?.tenantsPage) {
	routes.push({
		path: Route.ManageTenants,
		exact: true,
		frame: FrameManage,
		roles: [4],
		component: RoutesTenants.Tenants,
	});
}
