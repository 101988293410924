import { useHistory } from 'react-router-dom';

import { PageContent, PageTitle } from '~/containers';

interface NotFoundProps {
	title: string,
	message: string
}

export const NotFound: React.FC<NotFoundProps> = (props) => {

	const { title, message } = props;

	const { goBack } = useHistory();

	return (
		<>
			<PageTitle
				title={title}
				onBack={goBack} />
			<PageContent
				pageEmptyComponent={
					<p className="content--empty">{message}</p>
				} />
		</>
	);

}
