import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { lang } from '~/constants';
import { LocationType, RequestForm, requestSocialPlatform } from '~/services';
import { usePromoCode } from '~/hooks';

import {
	BigAmount,
	Button,
	CDatePicker,
	CInputButton,
	Input,
	Legend,
	MapWrap,
	PromoCode,
	RSelect
} from '~/components';

export const PersonalAppearance: React.FC<RequestForm.Props> = (props) => {

	const { athlete, onSubmit, processes } = props;

	const [ map, setMap ] = useState<boolean>(false);

	const { control, setValue, handleSubmit, reset } = useForm<RequestForm.PersonalAppearance>();

	const onLocationSet = useCallback(
		({ name, ...location }: LocationType) => {

			setMap(false);

			setValue('location', {
				address: name,
				...location
			}, { shouldValidate: true, });

		},
		[ setValue ]
	);

	const {
		code,
		error,
		setCode,
		discount,
		resetCode,
		isCalculating
	} = usePromoCode({
		amount: athlete?.athlete_info.athlete_services.personal_appearance_rate
	});

	const submit = (form: RequestForm.PersonalAppearance) => {

		onSubmit({ ...form, promo_code: code }, () => reset());

	}

	if (!athlete) {
		return null;
	}

	return (
		<>
			<CInputButton
				name="location"
				icon="mapPin"
				rules={{ required: true }}
				control={control}
				onClick={() => setMap(true)}
				placeholder="Choose location"
				titleTransform={(val) => (val as any).address} />
			<CDatePicker
				icon="calendar"
				name="date"
				rules={{ required: true }}
				control={control}
				minimumDate={dayjs().add(1, 'minute').toDate()}
				placeholder="Select date" />
			<Input
				name="event_description"
				icon="info"
				rules={{ required: true }}
				control={control}
				textarea
				placeholder="Event description" />
			<Legend
				label={lang.REQUEST_INFO_LABEL}
				children={
					<div className="legend-section">
						<Input
							name="name"
							note={lang.REQUEST_NAME_NOTE}
							rules={{ required: true }}
							control={control}
							placeholder={lang.REQUEST_NAME_PLACEHOLDER} />
						<RSelect
							name="social_media_platform"
							note={lang.REQUEST_PLATFORM_NOTE}
							isMulti
							control={control}
							options={requestSocialPlatform}
							placeholder={lang.REQUEST_PLATFORM_PLACEHOLDER}
							defaultValue={null} />
					</div>
				} />
			<PromoCode
				code={code}
				error={error}
				setCode={setCode}
				resetCode={resetCode}
				isCalculating={isCalculating} />
			<Legend
				label="Total price"
				children={(
					<BigAmount
						amount={athlete.athlete_info.athlete_services.personal_appearance_rate}
						discount={discount} />
				)} />
			<Button
				label="Book Now"
				variant="primary"
				onClick={handleSubmit(submit)}
				loading={processes || isCalculating}
				disabled={!!error}
				disabledDeep />
			<MapWrap
				onClose={() => setMap(false)}
				noRadius
				isVisible={map}
				onLocationCreate={onLocationSet} />
		</>
	);

}
