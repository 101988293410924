import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { includes, xor } from 'lodash';

import { pluralize } from '~/utils';
import { langManage } from '~/constants';
import { App, StateResponse } from '~/types';

import {
	IAccount,
	UserRoles,
	UserRolesArray,
	UserType
} from '~/services';

import {
	Button,
	Flag,
	Input,
	Legend,
	OptionView,
	SquareIcon
} from '~/components';

import { PushCertainUsers } from '../PushCertainUsers';

interface ModalPushSendProps {
	push?: App.Manage.Push,
	update: StateResponse<boolean>
	onSend: (data: App.Endpoints.SendPush[0]) => void,
	onHide: () => void
}

const STEPS = [
	'text',
	'dest',
	'dest-ids',
	'dest-types',
	'confirm'
] as const;

type StepKeyType = typeof STEPS[number];

type TextFormType = {
	subject: string,
	message: string
}

export const ModalPushSend: React.FC<ModalPushSendProps> = (props) => {

	const { push, update, onSend, onHide } = props;

	const [ , setIsUpdated ] = update;

	const [ step, setStep ] = useState<StepKeyType>(push ? 'dest' : 'text');

	const [ data, setData ] = useState<TextFormType | null>(null);

	const [ byRole, setByRole ] = useState(true);

	const [ users, setUsers ] = useState<IAccount[]>([]);

	const [ roles, setRoles ] = useState<UserType[]>([]);

	const { control, handleSubmit } = useForm<TextFormType>();

	const goBack = useCallback(
		() => {

			switch (step) {
				case 'text':
					return;
				case 'dest':
					return void (!push && setStep('text'));
				case 'dest-ids':
				case 'dest-types':
					return void setStep('dest');
				case 'confirm':
					return void setStep(byRole ? 'dest-types' : 'dest-ids');
				default:
					break;
			}

		},
		[ step, setStep, push, byRole ]
	);

	const submit = handleSubmit(
		(data: TextFormType) => {

			setData(data);

			setStep('dest');

			setIsUpdated(true);

		}
	);

	const send = async () => {

		const payload: App.Endpoints.SendPush[0] = push ? {
			id: push.id,
		} : {
			message: data?.message || '',
			subject: data?.subject || '',
		};

		if (byRole) {
			payload.user_types_id = roles.map((i) => i);
		} else {
			payload.users_id = users.map(({ id }) => id);
		}

		onSend(payload);

	}

	return (
		<>
			<div className="send-push--head">
				<SquareIcon
					icon="arrowBack"
					onClick={goBack}
					disabled={step === 'text'} />
				<h1>Send push</h1>
				<SquareIcon
					icon="cross"
					onClick={onHide} />
			</div>
			<div className="send-push--body">
				{step === 'text' && (
				<form onSubmit={submit}>
					<Legend label={langManage.pushes.TEXT_LEGEND}>
						<div className="field--wrapper">
							<Input
								icon="bell"
								name="subject"
								rules={{ required: true }}
								control={control}
								placeholder={langManage.pushes.PLACEHOLDER_SUBJECT} />
							<Input
								icon="mail"
								name="message"
								rules={{ required: true }}
								control={control}
								placeholder={langManage.pushes.PLACEHOLDER_MESSAGE}
								textarea />
						</div>
					</Legend>
					<Button
						label="Next"
						variant="primary" />
				</form>
				)}
				{step === 'dest' && (
				<>
					<Legend label={langManage.pushes.DEST_LEGEND}>
						<div className="field--wrapper">
							<p className="message">{langManage.pushes.DEST_PROMPT}</p>
							<Flag
								onClick={() => setByRole(true)}
								children={langManage.pushes.SELECT_ROLES}
								isSelected={byRole}
								isVertical />
							<Flag
								onClick={() => setByRole(false)}
								children={langManage.pushes.SELECT_CERTAIN}
								isSelected={!byRole}
								isVertical />
						</div>
					</Legend>
					<Button
						label="Next"
						variant="primary"
						onClick={() => {
							setStep(byRole ? 'dest-types' : 'dest-ids');
							setIsUpdated(true);
						}} />
				</>
				)}
				{step === 'dest-types' && (
				<>
					<Legend label={langManage.pushes.ROLE_LEGEND}>
						<div className="field--wrapper">
							<p className="message">{langManage.pushes.DEST_ROLES}</p>
							{UserRolesArray.filter(({ value }) => value !== 4).map(({ label, value }, i) => (
							<Flag
								key={i}
								onClick={() => setRoles(xor(roles, [ value ]) as UserType[])}
								children={label}
								isSelected={includes(roles, value)}
								isVertical />
							))}
						</div>
					</Legend>
					<Button
						label={roles.length ? 'Next' : langManage.pushes.SELECT_MIN_ROLES}
						onClick={() => setStep('confirm')}
						variant={roles.length ? 'primary' : undefined}
						disabled={!roles.length}
						disabledDeep />
				</>
				)}
				{step === 'dest-ids' && <PushCertainUsers users={[ users, setUsers ]} />}
				{step === 'confirm' && (
				<>
					<Legend label={langManage.pushes.PUSH_PREVIEW_LEGEND}>
						<OptionView
							label="Subject"
							value={data?.subject || push?.subject}
							vertical />
						<OptionView
							label="Message"
							value={data?.message || push?.message}
							vertical />
					</Legend>
					<Legend label={langManage.pushes.PUSH_DESTINATION_LEGEND}>
						<div className="field--wrapper">
							<p className="message">
								<span>{langManage.pushes.DEST_CONFIRM} </span>
								{byRole ?
									<span>{langManage.pushes.DEST_CONFIRM_ROLES} <i>{roles.map(role => UserRoles[role][1].toLowerCase()).join(', ')}</i></span> :
									<i>{pluralize(users.length, 'user')}</i>
								}
							</p>
						</div>
					</Legend>
				</>
				)}
			</div>
			{step === 'dest-ids' && (
			<div className="modal--footer">
				<Button
					label={users.length ? 'Next' : langManage.pushes.SELECT_MIN_USERS}
					onClick={() => setStep('confirm')}
					variant={users.length ? 'primary' : undefined}
					disabled={!users.length}
					disabledDeep />
			</div>
			)}
			{step === 'confirm' && (
			<div className="modal--footer">
				<Button
					label="Send"
					onClick={send}
					variant="primary" />
			</div>
			)}
		</>
	);

}
