import { useStats } from '~/store';
import { RType } from '~/services';
import { Legend, Loader, OptionView } from '~/components';

export const Stats: React.FC = () => {

	const { stats, loading } = useStats();

	return (
		<div className="manage-content">
			<div className="page-header">
				<h1>Stats <Loader loading={loading.readStats} /></h1>
			</div>
			{stats && (
			<div className="page-body stats-page">
				<div>
					<Legend
						label="Users By Type">
						<OptionView label="total" value={stats.users_by_type.total} />
						<OptionView label="admins" value={stats.users_by_type.admins} />
						<OptionView label="business" value={stats.users_by_type.business} />
						<OptionView label="athletes" value={stats.users_by_type.athletes} />
						<OptionView label="fans" value={stats.users_by_type.fans} />
					</Legend>
				</div>
				<div>
					<Legend
						label="Incomplete users by type">
						<OptionView label="total" value={stats.uncompleted_profiles_by_type.total} />
						<OptionView label="fans" value={stats.uncompleted_profiles_by_type.fans} />
						<OptionView label="business" value={stats.uncompleted_profiles_by_type.business} />
						<OptionView label="athletes" value={stats.uncompleted_profiles_by_type.athletes} />
					</Legend>
				</div>
				<div>
					<Legend
						label="Requests By Status">
						<OptionView label="Pending" value={stats.requests_by_status.pending} />
						<OptionView label="Accepted" value={stats.requests_by_status.accepted} />
						<OptionView label="Cancelled" value={stats.requests_by_status.cancelled} />
						<OptionView label="Pending Review" value={stats.requests_by_status.pending_review} />
						<OptionView label="Finished" value={stats.requests_by_status.finished} />
						<OptionView label="In Progress" value={stats.requests_by_status.in_progress} />
					</Legend>
				</div>
				<div>
					<Legend
						label="Requests &mdash; Count (Paid / Pending)">
						<OptionView
							label="Total"
							value={`${stats.requests_total} ($${stats.paid_pending_total.paid} / $${stats.paid_pending_total.pending})`} />
						{stats.paid_pending_by_request_type.map(({ type, paid, pending }, i) => {

							const key = type.split('_').splice(1).join('_') as RType;

							return (
								<OptionView
									key={i}
									label={`${type.split('_').splice(1).join(' ')}s`}
									value={`${stats.requests_by_type[key] || ''} ($${paid} / $${pending})`} />
							);

						})}
					</Legend>
				</div>
			</div>
			)}
		</div>
	);

}
