import { TabsRouter } from '~/components';

export const BusinessTabs: React.FC = () => {

	return (
		<TabsRouter
			tabs={[
				{ value: '/business/stats', label: 'Statistics', },
				{ value: '/business/rosters', label: 'Rosters', }
			]} />
	);

}
