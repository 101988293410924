import { useState } from 'react';

import { useAuth, useData } from '~/store';
import { SignUpWrap } from '~/containers';
import { Pagination } from '~/components';

import {
	StepType,
	SignUpData,
	signUpData,
	FormControl,
	useSignUpSteps
} from '~/services/signup';

import * as Steps from '~/containers/SignUp';

export const SignUpForm: React.FC = () => {

	const { account } = useAuth();

	const { isLoaded } = useData();

	const step = useState<StepType>('SignUpPhoto');

	const data = useState<SignUpData>(signUpData);

	const form = useState<FormControl>({});

	const Container = Steps[step[0]];

	const steps = useSignUpSteps(step[0], account?.user_type);

	if (!account) {
		return null;
	}

	return (
		<SignUpWrap
			step={step}
			steps={steps}
			account={account}>
			{!!isLoaded &&
			<>
				<Container
					step={step}
					form={form}
					data={data}
					steps={steps}
					account={account} />
				<Pagination
					step={step[0]}
					steps={steps.steps.map(([ name ]) => name)}
					stepChange={(payload) => step[1](payload)}
					nextDisabled={form[0].nextDisabled}
					beforeStepChange={form[0].onSubmit}
					firstPrevLabel=""
					lastNextLabel="Submit" />
			</>
			}
		</SignUpWrap>
	);

}
