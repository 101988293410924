import chroma from 'chroma-js';

export const mapStyles = (color: string) => {

	let baseColor: chroma.Color = chroma(
		chroma.valid(color) ? color : 'black'
	);

	const styles = [{
		elementType: 'geometry',
		stylers: [{
			color: baseColor.set('hsl.l', .13).hex(),
		}],
	}, {
		elementType: 'labels.icon',
		stylers: [{
			visibility: 'off',
		}],
	}, {
		elementType: 'labels.text.fill',
		stylers: [{
			color: baseColor.set('hsl.l', .46).hex(),
		}],
	}, {
		elementType: "labels.text.stroke",
		stylers: [{
			color: baseColor.set('hsl.l', .13).hex(),
		}],
	}, {
		featureType: 'administrative',
		elementType: 'geometry',
		stylers: [{
			color: baseColor.set('hsl.l', .46).hex(),
		}],
	}, {
		featureType: 'administrative.country',
		elementType: 'labels.text.fill',
		stylers: [{
			color: baseColor.set('hsl.l', .62).hex(),
		}],
	}, {
		featureType: 'administrative.land_parcel',
		stylers: [{
			visibility: 'off',
		}],
	}, {
		featureType: 'administrative.locality',
		elementType: 'labels.text.fill',
		stylers: [{
			color: baseColor.set('hsl.l', .74).hex(),
		}],
	}, {
		featureType: 'poi',
		elementType: 'labels.text.fill',
		stylers: [{
			color: baseColor.set('hsl.l', .46).hex(),
		}],
	}, {
		featureType: 'poi.park',
		elementType: 'geometry',
		stylers: [{
			color: baseColor.set('hsl.l', .09).hex(),
		}],
	}, {
		featureType: 'poi.park',
		elementType: 'labels.text.fill',
		stylers: [{
			color: baseColor.set('hsl.l', .38).hex(),
		}],
	}, {
		featureType: 'poi.park',
		elementType: 'labels.text.stroke',
		stylers: [{
			color: baseColor.set('hsl.l', .11).hex(),
		}],
	}, {
		featureType: 'road',
		elementType: 'geometry.fill',
		stylers: [{
			color: baseColor.set('hsl.l', .17).hex(),
		}],
	}, {
		featureType: 'road',
		elementType: 'labels.text.fill',
		stylers: [{
			color: baseColor.set('hsl.l', .54).hex(),
		}],
	}, {
		featureType: 'road.arterial',
		elementType: 'geometry',
		stylers: [{
			color: baseColor.set('hsl.l', .22).hex(),
		}],
	}, {
		featureType: 'road.highway',
		elementType: 'geometry',
		stylers: [{
			color: baseColor.set('hsl.l', .24).hex(),
		}],
	}, {
		featureType: 'road.highway.controlled_access',
		elementType: 'geometry',
		stylers: [{
			color: baseColor.set('hsl.l', .31).hex(),
		}],
	}, {
		featureType: 'road.local',
		elementType: 'labels.text.fill',
		stylers: [{
			color: baseColor.set('hsl.l', .38).hex(),
		}],
	}, {
		featureType: 'transit',
		elementType: 'labels.text.fill',
		stylers: [{
			color: baseColor.set('hsl.l', .46).hex(),
		}],
	}, {
		featureType: 'water',
		elementType: 'geometry',
		stylers: [{
			color: baseColor.set('hsl.l', .09).hex(),
		}],
	}, {
		featureType: 'water',
		elementType: 'labels.text.fill',
		stylers: [{
			color: baseColor.set('hsl.l', .24).hex(),
		}],
	}];

	return styles;

}
