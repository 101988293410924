import { IconSport } from '~/components/IconSport';
import { Dimensions } from '~/types';

type SurveyStep = 'school' | 'sport'

interface IconResolverProps {
	id: string,
	key: SurveyStep,
	size?: Dimensions,
	icon?: boolean,
	iconAlt?: boolean,
	compact?: boolean,
	fontSize?: number | number[],
	originalName?: string,
}

export const iconResolver = (
	props: IconResolverProps
): React.ReactNode | [ string, number ] => {

	const { id, key, size, originalName } = props;

	switch (key) {
		case 'school': {

			if (!originalName) {
				return null;
			}

			return (
				<p children={originalName[0]} />
			);

		}
		case 'sport': {
			return (
				<IconSport
					{...size}
					name={id} />
			);
		}
	}

}
