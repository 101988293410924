import cn from 'classnames';
import Linkify from 'linkify-react';

export interface LegendProps {
	label: string,
	error?: string | boolean,
	className?: string,
	hasPadding?: boolean,
	labelBackgroundColor?: `#${string}`,
}

export const Legend: React.FC<LegendProps> = (props) => {

	const { label, error, className, hasPadding, children } = props;

	const classNames = cn(
		'app-legend',
		className,
		{
			multi: Array.isArray(children),
			error: !!error,
			'has-padding': !!hasPadding,
		},
	);

	return (
		<div className={classNames}>
			<div className="app-legend--inner">
				<div className="app-legend-head">
					<h4>
						<span>{label}</span>
					</h4>
				</div>
				<div className="app-legend-body">
					{!Array.isArray(children) ?
					<Linkify
						options={{
							target: '_blank',
						}}
						children={children} /> :
					children.filter((item) => !!item).map((item, i) => (
					<div key={i} className="app-legend--item">
						<Linkify
							options={{
								target: '_blank'
							}}
							children={item} />
					</div>
					))}
				</div>
			</div>
		</div>
	);

}
