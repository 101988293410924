import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';

import { PageContent, PageTitle } from '~/containers';
import { useAuth, useRequest2 } from '~/store';
import { useRequestId } from '~/hooks';
import { ActionsProps, getRequestTitle, isRequest, REQUEST_TYPE } from '~/services';
import { NotFound } from '../components';
import { lang } from '../constants';

import * as ViewComponents from '~/containers/RequestView';

import {
	RequestHead,
	RequestActionsFan,
	RequestActionsAthlete,
	RequestActionsBusiness
} from '~/containers/Request';

export const Request: React.FC = () => {

	const { id } = useParams<{ id: string }>();

	const { goBack } = useHistory();

	const { account } = useAuth();

	const { originalId, isCampaign } = useRequestId(id);

	const {
		request,
		refresh,
		retrieve,
		loading,
		isEmpty,
		...api
	} = useRequest2(originalId, isCampaign);

	const onUpdate = useCallback<ActionsProps['onUpdate']>(
		(action) => {

			switch (action.type) {
				case 'ACCEPT_REQUEST': return api.accept(action.id);
				case 'CANCEL_REQUEST': return api.cancel(action.id);
				case 'REVIEW_REQUEST': return api.review(action);
				case 'APPROVE_REQUEST': return api.approve(action);
				case 'DECLINE_REQUEST': return api.decline(action.id);
				case 'CANCEL_CAMPAIGN': return api.cancelCampaign(action.id);
				case 'VIDEO_UPLOAD': return api.uploadVideo(action);
				case 'ASSETS_UPLOAD': return api.uploadAssets(action);
				case 'UPDATE_PAY_STATUS': return api.updatePayStatus(action);
			}

		},
		// eslint-disable-next-line
		[ request ]
	);

	if (isEmpty) {
		return (
			<NotFound
				title={lang.NOT_FOUND_REQUEST_TITLE}
				message={lang.NOT_FOUND_REQUEST_MESSAGE} />
		);
	}

	if (!account || !request) {
		return null;
	}

	const RequestComponent = ViewComponents[REQUEST_TYPE[request.type][3]];

	const args = {
		account: account,
		request: request,
		onUpdate: onUpdate,
	};

	return (
		<>
			<PageTitle
				title={getRequestTitle(request, true)}
				onBack={goBack} />
			<PageContent pageContentClassName="request--view">
				<RequestHead {...args} />
				<RequestComponent {...args as any} />
				{(account.user_type === 2 && !isRequest.endorsementCampaign(request)) ? (
				<RequestActionsAthlete {...args} request={request} />
				) : (
				<>
					{!isRequest.endorsementCampaign(request) ? (
					<RequestActionsFan {...args} request={request} />
					) : (
					<RequestActionsBusiness {...args} request={request} />
					)}
				</>
				)}
			</PageContent>
		</>
	);

}
