import { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { HTMLVideo } from '~/types';
import { mediaLink } from '~/utils';
import { Icon } from './Icon';
import { Logo } from './Logo';

interface VideoWrapProps extends HTMLVideo {
	source: {
		uri: string | File,
	},
	watermark?: boolean,
	onClick?: () => void,
}

export const VideoWrap: React.FC<VideoWrapProps> = (props) => {

	const {
		source,
		watermark,
		controls,
		onClick,
		...video
	} = props;

	const videoRef = useRef<HTMLVideoElement | null>(null);

	const [ playing, setPlaying ] = useState(false);

	useEffect(() => {

		if (!videoRef.current) {
			return;
		}

		videoRef.current[playing ? 'play' : 'pause']();

	}, [ videoRef, playing ]);

	const src = useMemo(() => {

		return mediaLink(source.uri);

	}, [ source.uri ]);

	const play = async () => {

		await new Promise((r) => setTimeout(r, 200));

		videoRef.current?.play();

		setPlaying(true);

	}

	return (
		<div onClick={onClick} className={cn('app--video-wrap', { playing, clickable: !!onClick })}>
			<div className="overlay">
				<button
					type="button"
					onClick={onClick || play}>
					<Icon name="playBig" />
				</button>
			</div>
			{watermark && (
			<div className="watermark">
				<Logo />
			</div>
			)}
			<video
				ref={videoRef}
				src={src + '#t=0.1'}
				loop
				preload="metadata"
				onPlay={() => setPlaying(true)}
				onPause={() => setPlaying(false)}
				onClick={() => setPlaying(false)}
				controls={false}
				playsInline
				onContextMenu={(e) => e.preventDefault()}
				{...video} />
		</div>
	);

}
