import { useCallback, useState } from 'react';
import DropdownMenu, { DropdownItemRadio, DropdownItemRadioGroup } from '@atlaskit/dropdown-menu';

import { App } from '~/types';
import { useAppDispatch, useAppSelector } from '~/store';
import { Button } from '~/components';
import { toastConfirm } from '~/services/toastConfirm';

import { ModalTicketClose } from '../modals';

export interface ChatTicketManageProps {
	ticket: App.Chat.TicketStripped,
	isUserView?: boolean,
}

export const ChatTicketManage: React.FC<ChatTicketManageProps> = (props) => {

	const { ticket, isUserView, } = props;

	const [ closeConfirm, setCloseConfirm ] = useState(false);

	const { admin } = useAppDispatch();

	const { loading } = useAppSelector();

	const onStatusUpdate = useCallback(
		async (status: App.Chat.TicketStatus, comment?: true | string) => {

			if (status === ticket.status) {
				return;
			}

			if (status === App.Chat.TicketStatus.closed && (typeof comment === 'boolean')) {
				return void setCloseConfirm(true);
			}

			if (typeof comment === 'boolean') {
				return;
			}

			await toastConfirm(admin.setTicketStatus, { id: ticket.id, status, comment }, {
				pending: 'Updating the ticket status',
				success: 'Has updated the ticket status',
			});

		},
		[ admin, ticket ]
	);

	return (
		<>

			{(isUserView && (ticket.status !== App.Chat.TicketStatus.closed)) &&
			<Button
				label="Close ticket"
				onClick={() => onStatusUpdate(App.Chat.TicketStatus.closed, true)} />
			}

			{!isUserView &&
			<div className="app-dropdown">
				<DropdownMenu
					shouldRenderToParent
					trigger="Actions"
					spacing="cozy"
					placement="bottom-end"
					onOpenChange={(e) => e.event.preventDefault()}>

					{/* <DropdownItem
						onClick={(e) => e.preventDefault()}
						children="Edit" />

					<DropdownItem
						onClick={(e) => e.preventDefault()}
						children="Delete" /> */}

					<DropdownItemRadioGroup id="actions" title="Ticket status">
						{App.Chat.TICKET_STATUS.map(({ value, label }, i) => (
						<DropdownItemRadio
							id={value}
							key={i}
							onClick={(e) => {

								e.preventDefault();

								onStatusUpdate(value, value === App.Chat.TicketStatus.closed ? true : undefined);

							}}
							children={label}
							isSelected={ticket.status === value} />
						))}
					</DropdownItemRadioGroup>

				</DropdownMenu>
			</div>
			}

			<ModalTicketClose
				onHide={setCloseConfirm.bind(null, false)}
				visible={closeConfirm}
				isLoading={loading.effects.admin.setTicketStatus}
				isUserView={isUserView}
				onCommentSubmit={async (comment) => onStatusUpdate(App.Chat.TicketStatus.closed, comment)} />

		</>
	);

}
