import { createModel } from '@rematch/core';

import { Store } from '~/store';
import { Tenants, TenantWithStats } from '~/services';

interface State {
	tenants: TenantWithStats[],
}

const state: State = {
	tenants: [],
};

export const tenants = createModel<Store.General>()({
	state,
	reducers: {
		INSERT_TENANTS: (state, tenants: TenantWithStats[]) => ({
			...state,
			tenants
		}),
	},
	effects: (dispatch) => ({
		retrieve: async () => {

			try {

				const data = Tenants.combineTenantStats(
					await Tenants.read().promise
				);

				dispatch.tenants.INSERT_TENANTS(data);

				return true;

			} catch (e) {

				return false;

			}

		},
		signUp: async (key: string) => {

			try {

				const { session } = await Tenants.signUp(key).promise;

				return session;

			} catch (e) {

				return false;

			}

		},
	}),
});
