import cn from 'classnames';

import { HTMLButton } from '~/types';

export interface SwitchProps extends HTMLButton {
	selected?: boolean
}

export const Switch: React.FC<SwitchProps> = (props) => {

	const { selected, ...button } = props;

	const classNames = cn('app--switch', { selected });

	return (
		<button
			{...button}
			className={classNames}>
			<div className="app--switch-knob" />
			<div className="app--switch-track" />
		</button>
	);

}
