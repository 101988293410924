import cn from 'classnames';

import { UserRoles, UserType } from '~/services';

export const UserLabel: React.FC<{ big?: boolean, role?: UserType }> = ({ big, role }) => {

	if (!role) {
		return null;
	}

	return (
		<span
			className={cn('user-role', { big: big, [UserRoles[role][0].toLowerCase()]: role })}
			children={UserRoles[role][1]} />
	);

}
