export const toTitleCase = (str: string, firstOnly?: boolean) => {

	if (firstOnly) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	return str.replace(
		/\w\S*/g,
		(txt) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		},
	);

}
