import { createModel } from '@rematch/core';

import { Store } from '../';
import { App } from '~/types';
import { getEmptyMessage } from '~/services/chat';

import {
	Admin,
	IAccount,
	StatsOutput,
	ResponseFail,
	UserUpdateInput,
	isCompleted,
	isBusiness,
	isAthlete,
} from '~/services';

interface AdminState {
	stats: StatsOutput | null,
	users: App.Endpoints.UsersRead[1],
	usersById: Record<string, App.Endpoints.UserRead[1]>,
	requestsById: Record<string, App.Endpoints.ReadUsersRequest[1]>,
	requestsByUserId: Record<string, App.Endpoints.ReadUsersRequests[1]>,
	payouts: App.Manage.PayoutAthlete[],
	balance: string,
	pushes: App.Manage.Push[],
	sentPayouts: App.Endpoints.ReadSentPayouts[1],
	tickets: App.Chat.TicketStripped[],
	ticketsQA: App.Chat.QA[],
	ticketsById: Record<string, App.Chat.Ticket> | null,
	promoCodes: App.Endpoints.ReadPromoCode[1],
}

const state: AdminState = {
	stats: null,
	users: {
		page: 0,
		users: [],
		total_pages: 0,
	},
	usersById: {},
	requestsById: {},
	requestsByUserId: {},
	payouts: [],
	balance: '',
	pushes: [],
	sentPayouts: {
		page: 0,
		total_pages: 0,
		payouts_list: [],
	},
	tickets: [],
	ticketsQA: [],
	ticketsById: {},
	promoCodes: {
		page: 0,
		total_pages: 0,
		promo_code: [],
	},
};

export const admin = createModel<Store.General>()({
	state,
	reducers: {
		_clear: () => {
			return { ...state };
		},
		SET_USERS: (state, users: App.Endpoints.UsersRead[1]) => {
			return {
				...state,
				users,
			};
		},
		SET_USER: (state, payload: { id: string, user: App.Endpoints.UserRead[1] }) => {

			state.usersById[payload.id] = payload.user;

			return state;

		},
		SET_STATS: (state, stats: StatsOutput) => {
			return {
				...state,
				stats,
			};
		},
		SET_REQUEST: (state, payload: { id: string, request: App.Endpoints.ReadUsersRequest[1] }) => {

			state.requestsById[payload.id] = payload.request;

			return state;

		},
		SET_REQUESTS: (state, payload: { id: string, requests: App.Endpoints.ReadUsersRequests[1] }) => {

			state.requestsByUserId[payload.id] = payload.requests;

			return state;

		},
		CREATE_USER: (state, user: IAccount) => {
			return {
				...state,
				users: {
					...state.users,
					users: [ { ...user, pending_payouts: 0 }, ...state.users.users ],
				},
			};
		},
		TOGGLE_USER_BLOCK: (state, id: string) => {

			if (state.usersById[id]) {
				state.usersById[id] = {
					...state.usersById[id],
					account: {
						...state.usersById[id].account,
						blocked: !state.usersById[id].account.blocked,
					},
				};
			}

			state.users.users = state.users.users.map(
				(user) => user.id === id ? { ...user, blocked: !user.blocked } : user,
			);

			return state;

		},
		REMOVE_USER: (state, id: string) => {

			if (state.usersById[id]) {
				delete state.usersById[id];
			}

			state.users.users = state.users.users.filter(
				(user) => user.id !== id
			);

			return state;

		},
		TOGGLE_PAID_STATUS: (state, payload: App.Endpoints.SetPayoutStatus[0]) => {

			const { id, user_id, amount, status } = payload;

			const _id = payload.campaign_id || id;

			if (state.requestsById[_id]) {
				state.requestsById[_id].requests = state.requestsById[_id].requests.map(
					(request) => request.id !== id ? request : ({
						...request,
						payment: !request.payment ? null : {
							...request.payment,
							payout_status: status,
						},
					}),
				);
			}

			if (state.usersById[user_id]) {
				state.usersById[user_id].amount = state.usersById[user_id].amount - amount;
			}

			if (state.requestsByUserId[user_id]) {
				state.requestsByUserId[user_id].requests = state.requestsByUserId[user_id].requests.map(
					(request) => request.id !== id ? request : ({
						...request,
						payment: !request.payment ? null : {
							...request.payment,
							payout_status: status,
						},
					}),
				);
			}

			return state;

		},
		INSERT_PAYOUTS: (state, { athletes_for_payout: payouts, balance }: App.Endpoints.ReadPayouts[1]) => {
			return {
				...state,
				payouts,
				balance,
			};
		},
		USER_UPDATE: (state, payload: UserUpdateInput) => {

			const profile_completed = isCompleted(payload);

			return {
				...state,
				users: {
					...state.users,
					users: state.users.users.map(
						(user) => user.id !== payload.id ? user : {
							...user,
							profile_completed,
							first_name: payload.first_name,
							last_name: payload.last_name,
							profile_photo: payload.profile_photo,
						},
					),
				},
				usersById: {
					...state.usersById,
					[payload.id]: {
						...state.usersById[payload.id],
						account: {
							...state.usersById[payload.id].account,
							...payload,
							profile_completed,
						},
					},
				},
			};

		},
		CREATE_PUSH: (state, push: App.Manage.Push) => {

			return {
				...state,
				pushes: [ push, ...state.pushes ],
			};

		},
		INSERT_PUSHES: (state, pushes: App.Manage.Push[]) => {

			return {
				...state,
				pushes,
			};

		},
		PUSH_UPDATE: (state, push: App.Manage.Push) => {

			return {
				...state,
				pushes: state.pushes.map(
					(i) => i.id === push.id ? push : i
				),
			};

		},
		DELETE_PUSH: (state, { id }: App.Endpoints.DeletePush[0]) => {

			return {
				...state,
				pushes: state.pushes.filter(
					(i) => i.id !== id
				),
			};

		},
		INSERT_SENT_PAYOUTS: (state, sentPayouts: App.Endpoints.ReadSentPayouts[1]) => {
			return {
				...state,
				sentPayouts,
			};
		},
		INSERT_PROMO_CODES: (state, promoCodes: App.Endpoints.ReadPromoCode[1]) => {
			return {
				...state,
				promoCodes,
			};
		},
		CREATE_PROMO_CODE: (state, payload: App.Endpoints.CreatePromoCode[1]) => {
			return {
				...state,
				promoCodes: {
					...state.promoCodes,
					promo_code: [ payload.promo_code, ...state.promoCodes.promo_code ],
				},
			};
		},
		UPDATE_PROMO_CODE: (state, { promo_code }: App.Endpoints.UpdatePromoCode[1]) => {
			return {
				...state,
				promoCodes: {
					...state.promoCodes,
					promo_code: state.promoCodes.promo_code.map(
						(item) => item.id === promo_code.id ? promo_code : item
					),
				},
			};
		},
		DELETE_PROMO_CODE: (state, { id }: App.Endpoints.DeletePromoCode[0]) => {
			return {
				...state,
				promoCodes: {
					...state.promoCodes,
					promo_code: state.promoCodes.promo_code.filter(
						(item) => item.id !== id
					),
				},
			};
		},
		SET_TRUSTED: (state, { user_id, trusted }: App.Endpoints.SetTrusted[0]) => {

			const users = state.users.users.map((user) => {

				if (!isBusiness(user)) {
					return user;
				}

				if (user_id !== user.id) {
					return user;
				}

				return {
					...user,
					business_info: {
						...user.business_info,
						trusted,
					},
				};

			});

			return {
				...state,
				usersById: state.usersById[user_id] ? {
					...state.usersById,
					[user_id]: {
						...state.usersById[user_id],
						account: {
							...state.usersById[user_id].account,
							business_info: {
								...state.usersById[user_id],
								trusted,
							},
						},
					},
				} : state.usersById,
				users: {
					...state.users,
					users,
				},
			};

		},
		SET_FEATURED: (state, { user_id, featured }: App.Endpoints.SetFeatured[0]) => {

			const users = state.users.users.map((user) => {

				if (!isAthlete(user)) {
					return user;
				}

				if (user_id !== user.id) {
					return user;
				}

				return {
					...user,
					athlete_info: {
						...user.athlete_info,
						featured,
					},
				};

			});

			return {
				...state,
				usersById: state.usersById[user_id] ? {
					...state.usersById,
					[user_id]: {
						...state.usersById[user_id],
						account: {
							...state.usersById[user_id].account,
							athlete_info: {
								...state.usersById[user_id],
								featured,
							},
						},
					},
				} : state.usersById,
				users: {
					...state.users,
					users,
				},
			};

		},
		CREATE_TICKET: (state, { ticket: { user_profiles: _, ...ticket } }: App.Endpoints.CreateTicket[1]) => {
			return {
				...state,
				tickets: [ ticket, ...state.tickets ],
			};
		},
		SET_TICKETS_META: (state, { meta }: App.Endpoints.ReadMeta[1]) => {
			state.ticketsQA = meta;
			return state;
		},
		SET_TICKETS: (state, { tickets }: App.Endpoints.ReadTickets[1]) => {
			state.tickets = [ ...tickets ];
			return state;
		},
		SET_TICKET: (state, { ticket }: App.Endpoints.ReadTicket[1]) => {
			return {
				...state,
				ticketsById: {
					...state.ticketsById,
					[ticket.id]: ticket,
				},
			};
		},
		SET_TICKET_AS_EMPTY: (state, id: string) => {
			(state.ticketsById as any)[id] = null;
			return state;
		},
		SET_TICKETS_MANAGE: (state, { tickets }: App.Endpoints.ReadTicketsManage[1]) => {
			return {
				...state,
				tickets,
			};
		},
		SET_TICKET_STATUS: (state, { ticket }: App.Endpoints.SetTicketStatus[1]) => {

			state.tickets = state.tickets.map((i) => i.id === ticket.id ? { ...i, status: ticket.status } : i);

			if (state.ticketsById?.[ticket.id]) {
				state.ticketsById[ticket.id].status = ticket.status;
				state.ticketsById[ticket.id].comment = ticket.comment;
				state.ticketsById[ticket.id].updated_at = ticket.updated_at;
			}

			return state;

		},
		CREATE_MESSAGE: (state, { message }: App.Endpoints.CreateMessage[1]) => {

			// Update tickets list ticket badge count
			if (!message.sent_id) {
				state.tickets = state.tickets.map((i) => i.id !== message.reference_id ? i : {
					...i,
					badge: i.badge + 1,
					last_message_at: message.created_at,
				});
			}

			// Add message to the ticket
			state.ticketsById?.[message.reference_id]?.messages
				.push(message);

			return state;

		},
		CREATE_MESSAGE_FULLFILL: (state, { message }: App.Endpoints.CreateMessage[1]) => {

			if (
				!state.ticketsById?.[message.reference_id] ||
				!state.ticketsById[message.reference_id].messages
			) {
				return state;
			}

			state.ticketsById[message.reference_id].messages =
			state.ticketsById[message.reference_id].messages.map(
				(i) => i.sent_id === message.sent_id ? message : i
			);

			return state;

		},
		SET_MESSAGES_READ_STATUS: (state, { reference_id }: App.Endpoints.SetMessageStatus[0]) => {

			if (
				!state.ticketsById?.[reference_id] ||
				!state.ticketsById[reference_id].messages
			) {
				return state;
			}

			state.ticketsById[reference_id].messages =
			state.ticketsById[reference_id].messages.map(
				(i) => ({ ...i, is_read: true, })
			);

			return state;

		},
	},
	effects: (dispatch) => ({
		readUsers: async (params: App.Endpoints.UsersRead[0]) => {

			const { page, users, total_pages } = await Admin.readUsers(params).promise;

			const output = {
				page,
				users,
				total_pages
			};

			dispatch.admin.SET_USERS(output);

			return output;

		},
		readUser: async (user_id: string, state) => {

			if (state.admin.usersById[user_id]) {
				return;
			}

			try {

				const { amount, account, requests } = await Admin.readUser({ user_id }).promise;

				dispatch.admin.SET_USER({
					id: user_id,
					user: {
						amount,
						account,
						requests,
					},
				});

				return true;

			} catch (e) {

				return null;

			}

		},
		readRequests: async (params: App.Endpoints.ReadUsersRequests[0]) => {

			const { amount, page, requests, total_pages } = await Admin.readRequests(params).promise;

			dispatch.admin.SET_REQUESTS({
				id: params.user_id,
				requests: {
					page,
					amount,
					requests,
					total_pages
				},
			});

		},
		readStats: async () => {

			const { counters } = await Admin.readStats().promise;

			dispatch.admin.SET_STATS(counters);

		},
		readRequest: async (id: string, state): Promise<App.Endpoints.ReadUsersRequest[1] | null> => {

			if (state.admin.requestsById[id]) {
				return state.admin.requestsById[id];
			}

			try {

				const request = await Admin.readRequest({ id }).promise;

				delete (request as any).status_response;

				dispatch.admin.SET_REQUEST({ id, request });

				return request;

			} catch (e) {

				return null;

			}

		},
		createUser: async (params: App.Endpoints.UserCreate[0]) => {

			try {

				const { user } = await Admin.createUser(params).promise;

				dispatch.admin.CREATE_USER(user);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		toggleUserBlock: async (user_id: string) => {

			try {

				await Admin.blockUser({ user_id }).promise;

				dispatch.admin.TOGGLE_USER_BLOCK(user_id);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		removeUser: async (user_id: string) => {

			try {

				await Admin.removeUser({ user_id }).promise;

				dispatch.admin.REMOVE_USER(user_id);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		togglePaidStatus: async (params: App.Endpoints.SetPayoutStatus[0]) => {

			try {

				await Admin.togglePaidStatus(params).promise;

				dispatch.admin.TOGGLE_PAID_STATUS(params);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readPayouts: async () => {

			try {

				const payload = await Admin.getPayouts().promise;

				dispatch.admin.INSERT_PAYOUTS(payload);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		sendPayouts: async (data: App.Manage.PayoutAthletePriced[]) => {

			try {

				const newData: App.Endpoints.SendPayouts[0] = data.map((athlete) => ({
					amount: athlete.amount,
					email: athlete.email,
					phone: athlete.mobile,
					athlete_id: athlete.id,
				}));

				await Admin.sendPayouts(newData).promise;

				await dispatch.admin.readPayouts();

				await dispatch.admin.readSentPayouts({});

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		updateUser: async (data: UserUpdateInput) => {

			try {

				await Admin.userUpdate(data).promise;

				dispatch.admin.USER_UPDATE(data);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		createPush: async (data: App.Endpoints.CreatePush[0]) => {

			try {

				const { custom_push } = await Admin.Pushes.create(data).promise;

				dispatch.admin.CREATE_PUSH(custom_push);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readPushes: async (params: App.Endpoints.ReadPush[0] = {}) => {

			try {

				const { custom_push } = await Admin.Pushes.read(params).promise;

				dispatch.admin.INSERT_PUSHES(custom_push);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		updatePush: async (data: App.Endpoints.UpdatePush[0]) => {

			try {

				const { custom_push } = await Admin.Pushes.update(data).promise;

				dispatch.admin.PUSH_UPDATE(custom_push);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		deletePush: async (data: App.Endpoints.DeletePush[0]) => {

			try {

				await Admin.Pushes.delete(data).promise;

				dispatch.admin.DELETE_PUSH(data);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		sendPush: async (data: App.Endpoints.SendPush[0]) => {

			try {

				await Admin.Pushes.send(data).promise;

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readSentPayouts: async (data: App.Endpoints.ReadSentPayouts[0]) => {

			try {

				const sentPayouts = await Admin.readSentPayouts(data).promise;

				dispatch.admin.INSERT_SENT_PAYOUTS(sentPayouts);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readPromoCodes: async (input: App.Endpoints.ReadPromoCode[0]) => {

			try {

				const promoCodes = await Admin.PromoCodes.read(input).promise;

				dispatch.admin.INSERT_PROMO_CODES(promoCodes);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		createPromoCode: async (data: App.Endpoints.CreatePromoCode[0]) => {

			try {

				const payload = await Admin.PromoCodes.create(data).promise;

				dispatch.admin.CREATE_PROMO_CODE(payload);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		updatePromoCode: async (data: App.Endpoints.UpdatePromoCode[0]) => {

			try {

				const payload = await Admin.PromoCodes.update(data).promise;

				dispatch.admin.UPDATE_PROMO_CODE(payload);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		deletePromoCode: async (data: App.Endpoints.DeletePromoCode[0]) => {

			try {

				await Admin.PromoCodes.delete(data).promise;

				dispatch.admin.DELETE_PROMO_CODE(data);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		togglePromoCodeState: async (props: App.Endpoints.SetPromoCodeStatus[0]) => {

			const { code, key } = props;

			try {

				const { promo_code } = await Admin.PromoCodes.update({
					...code,
					[key]: !code[key],
				 }).promise;

				dispatch.admin.UPDATE_PROMO_CODE({ promo_code });

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		syncUser: async (props: App.Endpoints.SyncUser[0]) => {

			try {

				await Admin.syncUser(props).promise;

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		syncUsers: async () => {

			try {

				await Admin.syncUsers().promise;

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		setTrusted: async (props: App.Endpoints.SetTrusted[0]) => {

			try {

				await Admin.setTrutsted(props).promise;

				dispatch.admin.SET_TRUSTED(props);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		setFeatured: async (props: App.Endpoints.SetFeatured[0]) => {

			try {

				await Admin.setFeatured(props).promise;

				dispatch.admin.SET_FEATURED(props);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readTicketsMeta: async (_, state) => {

			try {

				if (state.admin.ticketsQA.length) {
					return true;
				}

				const data = await Admin.Chats.readMeta().promise;

				dispatch.admin.SET_TICKETS_META(data);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		createTicket: async (props: App.Endpoints.CreateTicket[0]) => {

			try {

				const data = await Admin.Chats.create(props).promise;

				dispatch.admin.CREATE_TICKET(data);

				return {
					data,
				}

			} catch (e) {

				return {
					error: (e as ResponseFail).message
				}

			}

		},
		readTickets: async (params: App.Endpoints.ReadTickets[0] = {}) => {

			try {

				const data = await Admin.Chats.read(params).promise;

				dispatch.admin.SET_TICKETS(data);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readTicketsMange: async (params: App.Endpoints.ReadTicketsManage[0] = {}) => {

			try {

				const data = await Admin.Chats.readManage(params).promise;

				dispatch.admin.SET_TICKETS_MANAGE(data);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readTicket: async (id: string, state) => {

			try {

				if (state.admin.ticketsById?.[id]) {
					return true;
				}

				const data = await Admin.Chats.readTicket(id).promise;

				dispatch.admin.SET_TICKET(data);

				return true;

			} catch (e) {

				dispatch.admin.SET_TICKET_AS_EMPTY(id);

				return (e as ResponseFail).message;

			}

		},
		setTicketStatus: async (params: App.Endpoints.SetTicketStatus[0]) => {

			try {

				const data = await Admin.Chats.setTicketStatus(params).promise;

				dispatch.admin.SET_TICKET_STATUS(data);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		createMessage: async (params: App.Endpoints.CreateMessage[0], state) => {

			try {

				const message = getEmptyMessage({
					user: state.auth.account,
					...params,
				});

				dispatch.admin.CREATE_MESSAGE({
					message,
				});

				const data = await Admin.Chats.createMessage(params).promise;

				dispatch.admin.CREATE_MESSAGE_FULLFILL({
					message: {
						...data.message,
						sent_id: message.sent_id,
					},
				});

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		setMessageStatus: async (params: App.Endpoints.SetMessageStatus[0]) => {

			try {

				await Admin.Chats.setMessageStatus(params).promise;

				dispatch.admin.SET_MESSAGES_READ_STATUS(params);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
	}),
});
