import { User } from './User';

export namespace Rosters {

	export const rostes = 'ROSTERS';

	export interface Roster {
		id: string,
		name: string,
		user_id: string,
		created_at: number,
		updated_at: number,
		athletes_id: string,
		athletes_count: number,
	}

	export interface RosterDetailed extends Roster {
		athletes: User.Athlete[],
	}

	export namespace Endpoints {

		export type Create = [
			{
				name: string,
				athletes_id: string,
			},
			{
				roster: Rosters.RosterDetailed,
			}
		]

		export type Read = [
			void,
			{
				rosters: Rosters.Roster[],
			}
		]

		export type ReadById = [
			{
				id: string,
			},
			{
				// FIXME: WRONG PLURAL
				// TODO: Create[1],
				rosters: Rosters.RosterDetailed,
			}
		]

		export type Update = [
			Create[0] & {
				id: string,
			},
			Create[1]
		]

		export type Delete = [
			{
				id: string,
			},
			void
		]

		export type Export = [
			{
				id: string,
			},
			string
		]

	}

}
