import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';

import { App } from '~/types';
import { getAssetMeta } from '~/services/chat';
import { VideoWrap } from '~/components';
import { ChatAsset } from './ChatAsset';

export type ClickAction = [ 'open' | 'download', string ] | undefined

export interface ChatMessageProps {
	message: App.Chat.Message,
	isOuter: boolean,
	isSimpleMedia?: boolean,
	onMessageClick?: (
		data: App.Chat.Message,
		meta?: App.Chat.AssetMeta,
		action?: ClickAction,
	) => void,
}

export const ChatMessage: React.FC<ChatMessageProps> = (props) => {

	const { message, isOuter, isSimpleMedia, onMessageClick, } = props;

	const meta = useMemo(
		() => getAssetMeta(message.assets),
		[ message.assets ]
	);

	const ratio = useMemo(
		() => Number((meta?.dimensions?.height || 0) / (meta?.dimensions?.width || 0)),
		[ meta ]
	);

	const className = cn(
		'app--chat-message',
		{
			'app--chat-message_outer': !isOuter,
			'app--chat-message_clickable': !!message.assets,
			'app--chat-message_media-asset': !isSimpleMedia && ['image','video'].includes(meta?.type || ''),
		},
	);

	const createdAt = useMemo(
		() => dayjs(message.created_at * 1000),
		[ message ]
	);

	const clickAction = useMemo<ClickAction>(
		() => {
			return meta ?
				[
					(!isSimpleMedia && ['image','video'].includes(meta.type)) ?
						'open' :
						'download',
					message.text,
				] :
				undefined;
		},
		[ meta, message, isSimpleMedia ]
	);

	const onClick = useCallback(
		() => onMessageClick?.(message, meta, clickAction),
		[ onMessageClick, message, meta, clickAction ]
	);

	return (
		<div className={className}>
			<div
				onClick={onClick}
				className="app--chat-message__inner">

				{meta && (
					(meta.type === 'application') ||
					(isSimpleMedia && ['image','video'].includes(meta.type))
				) &&
				<ChatAsset
					type={meta.type}
					name={meta.name}
					size={meta.size} />
				}

				{(!isSimpleMedia && meta?.type === 'image') &&
				<>
					<img className="app--chat-message__image" src={message.text} alt={meta?.name} />
					<i style={{ paddingTop: `${ratio * 100}%` }} />
				</>
				}

				{(!isSimpleMedia && meta?.type === 'video') &&
				<>
					<VideoWrap onClick={() => {}} source={{ uri: message.text, }} />
					<i style={{ paddingTop: `${ratio * 100}%` }} />
				</>
				}

				{!meta?.type &&
				<p className="app--chat-message__text">{message.text}</p>
				}

				<div className="app--chat-message__info">
					<p
						title={createdAt.format('LLLL')}
						children={createdAt.format('h:mm a')}
						className="app--chat-message__date" />
					{isOuter &&
					<div className="app--chat-message__read-status">
						<svg width="14" height="8" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg">
							<path d="M9.273 0.140135C9.5093 0.346456 9.53361 0.705301 9.3273 0.941631L3.37636 7.75821C3.26848 7.88174 3.11246 7.95263 2.94844 7.95263C2.78441 7.95263 2.62839 7.88174 2.52052 7.75821L0.140135 5.03159C-0.0661869 4.79521 -0.0418595 4.43636 0.194478 4.23004C0.430807 4.02373 0.789652 4.04804 0.995974 4.28442L2.94844 6.52086L8.47145 0.194478C8.67776 -0.0418594 9.03661 -0.066187 9.273 0.140135Z" />
							<path style={{ opacity: message.is_read ? 1 : 0 }} d="M13.0783 0.204086C13.3054 0.42045 13.3142 0.780007 13.0978 1.00719L6.60568 7.82372C6.49094 7.94422 6.32916 8.0083 6.16306 7.99913C5.99697 7.98997 5.84321 7.90847 5.74241 7.77608L5.41801 7.35005C5.22798 7.10048 5.27623 6.74406 5.52579 6.55403C5.74021 6.39073 6.03347 6.4033 6.23236 6.56834L12.2751 0.223664C12.4915 -0.00350851 12.8511 -0.012279 13.0783 0.204086Z" />
						</svg>
					</div>
					}
				</div>

			</div>
		</div>
	);

}
