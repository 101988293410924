import Cookies from 'universal-cookie';

import { createAPI, Response, ResponseFail } from './createAPI';
import { config, errors } from '~/constants';

type APIResponse = {
	status_response: {
		status: boolean,
		http_status_code: number,
		response_message: string,
		response_internal_code: keyof typeof errors
	}
}

export const api = createAPI<APIResponse>({
	baseURL: config.URL_REST,
	middleware: {
		request: async (r, p) => {

			if (p.skipAuth) {
				return new Promise((resolve) => resolve(r));
			}

			const sid = (new Cookies()).get(config.API_COOKIE);

			if (sid && !sid.includes('GUEST')) {
				r.headers.append(config.API_TOKEN, sid);
			}

			return r;

		},
		response: ({ status_response }, request) => {

			const {
				status,
				response_message: message,
				response_internal_code: code
			} = status_response;

			return [
				status,
				{
					code,
					message: errors[code] || message,
					request,
					...(errors[code] && {
						payload: {
							prevMessage: message,
						},
					}),
				},
			];

		},
	},
	defaultType: 'JSON',
});

export type { Response, APIResponse, ResponseFail }
