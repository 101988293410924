import { ResponseFail } from '../api';
import { errors } from '~/constants';

const isAPIResponse = (val: any): val is ResponseFail => {
	return 'code' in val && 'message' in val;
}

const createError = (val: any): ResponseFail => {

	let code: number = 0;
	let message: string = 'Unknown error occured';

	if ('message' in val) {
		message = val.mesage;
	}

	return {
		code,
		message,
	};

}

export const handleError = (
	error: unknown,
	prevent?: (error: ResponseFail) => string | keyof typeof errors | boolean | void
) => {

	if (typeof error === 'string') {
		return alert(error);
	}

	const _error = !isAPIResponse(error) ?
		createError(error) :
		error;

	let { message } = _error;

	if (prevent) {
		const _altMessage = prevent(_error);
		if (typeof _altMessage !== 'string' &&
			typeof _altMessage !== 'number') {
			if (_altMessage) {
				return;
			}
		} else {
			message = typeof _altMessage !== 'string' ?
				errors[_altMessage] :
				_altMessage;
		}
	}

	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		console.error(_error);
	}

	alert(message || 'Unknown error occured');

}
