const Methods = {
	get: 'GET',
	put: 'PUT',
	post: 'POST',
	del: 'DELETE',
	patch: 'PATCH',
};

type Method = keyof typeof Methods

type Data = Record<string, any>

type Type = 'FORM' | 'JSON' | 'DATA'

interface Request extends RequestInit {
	headers: Headers
}

export type RequestStripped = {
	path: string,
	type: Type,
	data: Data,
	method: string,
}

export type ResponseFail = {
	code: number,
	message: string,
	request?: RequestStripped,
	payload?: Record<string, any>
}

interface Options<R> {
	baseURL?: string,
	charset?: 'utf-8',
	middleware?: {
		request?: (
			request: Request,
			params: { skipAuth?: boolean }
		) => Request | Promise<Request>,
		response?: (
			response: R,
			request: RequestStripped,
			requestRaw: Request
		) => [ boolean, ResponseFail ]
	},
	defaultType?: Type
}

interface Params<T> {
	raw?: boolean,
	type?: Type,
	skipAuth?: boolean,
	customURL?: string,
	done?: (r: T, fn: (a?: any) => void) => void,
	fail?: (e: any, fn: (a?: any) => void) => void
}

export interface Response<T> {
	abort: () => void,
	promise: Promise<T>
}

type RequestArgs<T> = [ path: string, data?: Data, params?: Params<T> ]

type RequestArgsMain<T> = [ method: string, ...rest: RequestArgs<T> ]

const serialize = (data: Data, type?: Type): [ string | FormData, string ] => {

	let body: string | FormData = '';
	let header: string = '';

	switch (type) {
		case 'FORM':
			body = (new URLSearchParams(data)).toString();
			header = 'x-www-form-urlencoded';
			break;
		case 'JSON':
			body = JSON.stringify(data);
			header = 'json';
			break;
		case 'DATA':
			body = new FormData();
			for (const name in data) {
				body.append(name, data[name]);
			}
			break;
	}

	return [ body, header ];

}

const MIME = {
	csv: 'text/csv',
	json: 'application/json',
}

export const createAPI = <R>(options: Options<R>) => {

	const {
		baseURL = '',
		charset = 'utf-8',
		middleware = {},
		defaultType,
	} = options;

	const request = <T>(...args: RequestArgsMain<T>): Response<T> => {

		const [
			method,
			path,
			data = {},
			params = {}
		] = args;

		let is_aborted = false;

		const { signal, abort } = new AbortController();

		const promise = new Promise<T>(async (resolve, reject) => {

			const _reject = (error: ResponseFail): void => {

				let e;

				if (is_aborted || !(error instanceof Error)) {

					e = { is_aborted, ...error };

				} else {

					e = { is_aborted, code: -1, message: error.message };

				}

				if (params?.fail) {
					return params?.fail(e, reject);
				}

				reject(e);

			}

			const _resolve = (r: R & T): void => {

				if (params?.done) {
					return params?.done(r, resolve);
				}

				resolve(r);

			}

			const uri = new URL(baseURL);

			let url = params.customURL ?
				`${params.customURL}${uri.pathname}${path}` :
				`${uri.href}${path}`;

			let req: Request = {
				method,
				signal,
				headers: new Headers()
			};

			if (middleware.request) {
				req = await middleware.request(req, { skipAuth: params.skipAuth });
			}

			if (Object.keys(data).length) {

				if (method === 'GET') {

					for (const i in data) {
						if (data[i] === null || data[i] === undefined) {
							delete data[i];
						}
					}

					if (Object.keys(data).length) {
						url += `?${(new URLSearchParams(data)).toString()}`;
					}

				} else {

					const [ body, header ] = serialize(data, params.type || defaultType);

					req.body = body;

					if (header) {
						req.headers.append(
							'Content-Type',
							`application/${header}; charset=${charset}`
						);
					} else {
						req.headers.delete('Content-Type');
					}

				}

			}

			const request = {
				method,
				path,
				type: params.type || defaultType || 'FORM',
				data,
			};

			try {

				const resp = await fetch(url, req);

				const type = resp.headers.get('Content-Type');

				if (type === MIME.csv) {
					const body = await resp.text();
					return _resolve(body as unknown as R & T);
				}

				if (type !== MIME.json) {
					const message = await resp.text();
					return _reject({
						code: resp.status,
						message,
						request,
					});
				}

				if (params.raw) {

					const body = await resp.text();

					return _resolve({ body } as unknown as R & T);

				}

				const json = await resp.json() as R & T;

				if (middleware.response) {

					const [ status, error ] = middleware.response(json, request, req);

					return status ? _resolve(json) : _reject(error);

				}

				return _resolve(json);

			} catch (e) {

				_reject(e as ResponseFail);

			}

		});

		return {
			abort: () => {

				is_aborted = true;

				abort();

			},
			promise,
		};

	}

	const api = {} as Record<Method, <T>(...args: RequestArgs<T>) => Response<T>>

	for (const key in Methods) {
		// Hate
		const method = key as Method;
		api[method] = (...args) => request(Methods[method], ...args);
	}

	return api;

}
