import cn from 'classnames';

import { getValidChildren } from '~/utils';

interface TabArgs {
	label: string,
	value?: string,
	emptyText?: string,
}

export interface TabProps {
	label: string,
	onChange: () => void,
	counter?: number,
	isSelected: boolean,
}

export interface TabsHeadProps {
	tab: number,
	tabs: TabArgs[],
	tabsCounter?: number[],
	onChange?: (tab: number) => void,
}

export interface TabsProps extends TabsHeadProps {
	grow?: boolean,
	loading?: boolean | boolean[],
	className?: string,
}

const Tab: React.FC<TabProps> = (props) => {

	const { label, onChange, isSelected, counter, } = props;

	return (
		<div
			onClick={onChange}
			className={cn('app--tabs-tab', { selected: isSelected })}>
			<p>{label}</p>
			{!!counter &&
			<span children={counter} />
			}
		</div>
	);

}

export const TabsHead: React.FC<TabsHeadProps> = (props) => {

	const { tab, tabs, tabsCounter, onChange } = props;

	return (
		<div className="app--tabs-head">
			{tabs.map(({ label }, i) => (
			<Tab
				key={i}
				label={label}
				counter={tabsCounter?.[i] || 0}
				onChange={() => {
					if (onChange && i !== tab) {
						onChange(i);
					}
				}}
				isSelected={i === tab} />
			))}
		</div>
	);

}

export const Tabs: React.FC<TabsProps> = (props) => {

	const { tab, tabs, onChange, children, className, tabsCounter } = props;

	return (
		<div className={cn('app--tabs', { single: tabs.length === 1 })}>
			<TabsHead
				tab={tab}
				tabs={tabs}
				onChange={onChange}
				tabsCounter={tabsCounter} />
			{children && (
			<div className={cn('tabs--content', className)}>
				{children ? getValidChildren(children)[tab] : children}
			</div>
			)}
		</div>
	);

}
