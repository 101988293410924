import { useEffect, useState } from 'react';
import { config } from '~/constants';

import { Button } from './Button';
import { Input } from './Input';
import { Legend } from './Legend';
import { SquareIcon } from './SquareIcon';

export interface PromoCodeProps {
	code?: string,
	error?: string,
	setCode: (val?: string) => void,
	resetCode: () => void,
	isCalculating?: boolean,
}

export const PromoCode: React.FC<PromoCodeProps> = (props) => {

	const { code = '', error, setCode, resetCode, isCalculating } = props;

	const [ value, setValue ] = useState(code);

	useEffect(
		() => {
			setValue(code);
		},
		[ code ]
	);

	if (!config.CONFIG.features?.promoCodes) {
		return null;
	}

	return (
		<Legend label="Coupon Code">
			<div className="promo-code-form">
				<div className="promo-code-field">
					<Input
						icon="sale"
						value={value}
						error={error}
						onChange={({ target }) => setValue(target.value)}
						placeholder="Coupon Code" />
					<div className="search-reset">
						<SquareIcon
							icon="cross"
							onClick={() => {
								setValue('');
								resetCode();
							}} />
					</div>
				</div>
				<Button
					label="Apply"
					loading={isCalculating}
					onClick={() => setCode(value)}
					variant="primary" />
			</div>
		</Legend>
	);

}
