import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { App } from '~/types';

import { Store } from '../models';

export const usePromoCodes = ({ page, archived }: App.Endpoints.ReadPromoCode[0]) => {

	const [ promoCodes, loading ] = useSelector(
		({ admin, loading }: Store.Root) => [
			admin.promoCodes,
			loading.effects.admin
		] as const
	);

	const { admin } = useDispatch<Store.Dispatch>();

	useEffect(() => {

		admin.readPromoCodes({ page, archived });

	}, [ admin, page, archived ]);

	return {
		promoCodes,
		...admin,
		loading,
	};

}
