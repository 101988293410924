import { Switch, SwitchProps } from '~/components';

export interface ArchiveSwitchProps extends SwitchProps {
	label: string,
}

export const ArchiveSwitch: React.FC<ArchiveSwitchProps> = (props) => {

	const { label, ...args } = props;

	return (
		<div className="archive-switch">
			<Switch {...args} />
			{!!label && <p>{label}</p>}
		</div>
	);

}
