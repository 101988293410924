import { lang } from './lang';

export const errors = {
	205: lang.INCORRECT_CREDENTIALS,
	208: lang.EMAIL_ALREADY_TAKEN,
	211: lang.USERNAME_ALREADY_TAKEN,
	641: 'Wrong request date',
	12001: lang.PASSWORD_INCORRECT_CODE,
	12002: lang.INCORRECT_BUSINESS,
} as const;
