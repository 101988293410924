import { Icon } from './Icon';

export interface TooltipProps {
	text: string,
}

export const Tooltip: React.FC<TooltipProps> = (props) => {

	const { text } = props;

	return (
		<div className="app-tooltip">
			<Icon
				name="imark" />
			<p
				children={text} />
		</div>
	);

}
