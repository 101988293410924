import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AthleteCard, Tabs } from '~/components';
import {
	Athlete,
	handleError,
	IAccount,
	IAthlete
} from '~/services';

interface ProfileFanProps {
	account: IAccount
}

const profileTabs = [
	{ label: 'Recent', },
];

export const ProfileFan: React.FC<ProfileFanProps> = () => {

	const { push } = useHistory();

	const [ tab, setTab ] = useState(0);

	const [ recent, setRecent ] = useState<{
		loading: boolean,
		athletes: IAthlete[]
	}>({
		loading: false,
		athletes: [],
	});

	const getRecent = useCallback(async () => {

		try {

			setRecent((val) => ({
				...val,
				loading: true,
			}));

			const { athletes } = await Athlete.recent().promise;

			setRecent({
				loading: false,
				athletes,
			});

		} catch (e) {

			setRecent((val) => ({
				...val,
				loading: false,
			}));

			handleError(e);

		}

	}, []);

	useEffect(() => {

		getRecent();

	}, [ getRecent ]);

	return (
		<Tabs
			tab={tab}
			tabs={profileTabs}
			onChange={setTab}>
			<div className="page--container grid-4s">
				{recent.athletes.map((athlete, i) => (
				<AthleteCard
					key={athlete.id}
					athlete={athlete}
					onClick={() => push(`/@${athlete.username}`)} />
				))}
			</div>
		</Tabs>
	);

}
