import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

export const usePayouts = () => {

	const [ payouts, balance, loading ] = useSelector(
		({ admin, loading }: Store.Root) => [
			admin.payouts,
			admin.balance,
			loading.effects.admin.readPayouts
		] as const
	);

	const { admin } = useDispatch<Store.Dispatch>();

	useEffect(() => {

		admin.readPayouts();

	}, [ admin ]);

	return {
		payouts,
		balance,
		...admin,
		loading,
	};

}
