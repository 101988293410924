import cn from 'classnames';

import { mediaLink } from '~/utils';
import { Icon } from './Icon';

interface AvatarProps {
	src: string,
	alt?: string,
	count?: number,
	countTitle?: string
}

export const Avatar: React.FC<AvatarProps> = (props) => {

	const { src, alt, count, countTitle } = props;

	return (
		<div className={cn('user-avatar', { 'avatar-empty': !src })}>
			{src ? (
			<img
				src={mediaLink(src, 'S')}
				alt={alt}
				className="user-avatar" />
			) : (
				<Icon name="user" />
			)}
			{!!count && <span className="counter" title={countTitle}>{count}</span>}
		</div>
	);

}
