import React from 'react';

import { lang } from '~/constants';
import { Button, Modal, ModalProps } from '~/components';

export const ModalAuthPrompt: React.FC<Omit<ModalProps, 'title'>> = (props) => {

	return (
		<Modal
			title="Account"
			icon="userBig"
			{...props}
			className="campaign--modal">
			<p className="message" children={lang.GUEST_AUTH_PROMPT} />
			<Button
				to="/login"
				label="Sign In"
				variant="primary" />
			<Button
				to="/signup"
				label="Create Account"
				variant="secondary" />
			<Button
				label="Cancel"
				onClick={props.onHide} />
		</Modal>
	);

}
