import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { Status } from '~/store';
import { routes as ROUTES } from '~/constants';
import { ProtectedRouteProps, UserType } from '~/services';

export const useRoutes = (status: Status, role: UserType = 0) => {

	const { pathname } = useLocation();

	const routes = useMemo(
		() => ROUTES.filter(({ roles = 0 }) => {

			switch (status) {
				case Status.authorized:
					switch (roles) {
						case -1:
							return false;
						case 0:
						case 1:
							return true;
						default:
							return roles.includes(role);
					}
				case Status.authorizing:
					return false;
					// Keep this part so we can load
					// pages where auth is not important faster
					// switch (roles) {
					// 	case 0:
					// 		return true;
					// 	case -1:
					// 	case 1:
					// 		return false;
					// 	default:
					// 		return roles.includes(role);
					// }
				case Status.unauthorized:
					switch (roles) {
						case -1:
						case 0:
							return true;
						case 1:
							return false;
						default:
							return roles.includes(role);
					}
			}

			return false;

		}),
		[ status, role ]
	);

	const route = useMemo<ProtectedRouteProps | null>(
		() => {

			return routes.filter(
				(data) => matchPath(pathname, data)
			)[0] || null;

		},
		[ routes, pathname ]
	);

	return { route, routes, pathname };

}
