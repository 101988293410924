export const normalizeURL = (link: string) => {

	let url;

	try {

		url = new URL(link);

	} catch (_) {

		return '';

	}

	return url.toString();

}
