import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { components, createFilter } from 'react-select';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { App, Option } from '~/types';
import { Store } from '~/store';
import { Admin } from '~/services';
import { Avatar, Button, Input, Modal, ModalProps, RSelect } from '~/components';

interface UserOption extends Option<string> {
	email: string,
	photo: string,
}

export interface ModalTicketCreateForm {
	user: Option,
	subject: string,
}

export interface ModalTicketCreateProps extends ModalProps {
	onCreate: (form: App.Endpoints.CreateTicket[1]) => void,
	isUserView?: boolean,
}

export const ModalTicketCreate: React.FC<ModalTicketCreateProps> = (props) => {

	const { isUserView, onCreate, ...args } = props;

	const { admin } = useDispatch<Store.Dispatch>();

	const { control, handleSubmit, reset } = useForm<ModalTicketCreateForm>({});

	const [ users, setUsers ] = useState<UserOption[]>([]);

	const [ isUsersLoading, setIsUsersLoading ] = useState(false);

	const onTextChange = useDebouncedCallback(
		async (text: string) => {

			if (isUsersLoading || !text.length) {
				return;
			}

			try {

				setIsUsersLoading(true);

				const { users } = await Admin.Chats.readUsers(text).promise;

				const usersOptions: UserOption[] = users.map(
					(user) => ({
						label: `${user.first_name} ${user.last_name}`,
						value: user.id,
						email: user.email,
						photo: user.profile_photo,
					})
				);

				setUsers(usersOptions);

			} catch (e) {

			} finally {

				setIsUsersLoading(false);

			}

		},
		300,
	);

	const onSubmit = useCallback(
		async (form: ModalTicketCreateForm) => {

			const { user, subject } = form;

			const data: App.Endpoints.CreateTicket[0] = {
				user_id: user ? `${user.value}` : undefined,
				subject,
			}

			const result = await admin.createTicket(data);

			if ('error' in result) {
				return toast.error(result.error);
			}

			onCreate(result.data);

			reset();

		},
		[ admin, onCreate, reset ]
	);

	return (
		<Modal
			icon={isUserView ? 'ticketShield' : undefined}
			title="Start conversation"
			className="form-modal"
			isEnhanced={!isUserView}
			{...args}>
			<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<p className="message">To create a new conversation you must enter a topic</p>
				{!isUserView &&
				<RSelect
					icon="user"
					name="user"
					rules={{ required: true }}
					control={control}
					options={users}
					isLoading={isUsersLoading}
					placeholder="Athlete's na&#xFEFF;me or em&#xFEFF;ail"
					isClearable
					filterOption={createFilter({
						stringify: ({ data, value, label }) => {
							return `${value} ${label} ${(data as UserOption).email}`;
						}
					})}
					components={{
						Option: ({ ...args }) => (
							<components.Option {...args} className="user-option">
								<Avatar
									src={(args.data as UserOption).photo} />
								<div className="user-text">
									<p className="user-name">{args.label}</p>
									<p className="user-email" title={(args.data as UserOption).email}>{(args.data as UserOption).email}</p>
								</div>
							</components.Option>
						),
					}}
					onTextChange={onTextChange}
					loadingMessage={() => `Loading users...`}
					noOptionsMessage={() => `Start typing to find user`} />
				}
				<Input
					icon="description"
					name="subject"
					rules={{ required: true }}
					control={control}
					textarea
					placeholder="Topic conversation" />
				<div className="actions">
					<Button
						label="Create"
						variant="primary"
						loading={args.isLoading} />
					<Button
						type="button"
						label="Cancel"
						onClick={args.onHide} />
				</div>
			</form>
		</Modal>
	);

}
