import { useMemo } from 'react';

import { Button } from './Button';
import { Legend } from './Legend';
import { MapLocation } from './MapLocation';

import { ILocation, RequestType } from '../services';

const LocationTypes: RequestType[] = [
	'speaking_engagement',
	'personal_appearance',
	'training_session',
	'camp',
];

interface AthleteServiceProps {
	label: string,
	amount: number,
	type?: RequestType,
	note?: string,
	prefix?: string,
	disabled?: boolean,
	locations?: ILocation[],
	onPress?: () => void
}

export const AthleteService: React.FC<AthleteServiceProps> = (props) => {

	const {
		type,
		label,
		amount,
		note,
		prefix = '$',
		disabled,
		locations = [],
		onPress
	} = props;

	const Locations = useMemo(
		() => {

			const _locations = locations
				.filter(
					({ location_type }) => !type ? true : location_type === type
				);

			return _locations.map((item) => (
				<MapLocation key={item.id} {...item} />
			));

		},
		[ locations, type ]
	);

	const content = (
		<>
			<div className="app--athlete-service-inner">
				<p className="service--type" children={label} />
				<div className="service--label">
					<p className="service--rate" children={`${prefix}${amount}`} />
					{note && <p className="service--note" children={note} />}
				</div>
			</div>
			{!!locations && (type && LocationTypes.includes(type)) && !!Locations.length && (
			<div className="app--athlete-service-locations">
				<Legend
					label="Travel Parameters"
					children={Locations} />
			</div>
			)}
		</>
	);

	return (
		<div className="app--athlete-service">
			{!onPress ? content : (
			<div className="app--athlete-service-inner">
				<div children={content} />
				<div>
					<Button
						variant="primary"
						label="Purchase"
						onClick={onPress}
						disabled={disabled}
						disabledDeep />
				</div>
			</div>
			)}
		</div>
	);

}
