import { createModel } from '@rematch/core';

import { Store } from './';

import { OptionDefault } from '~/types';
import { Data, DataType, RefDataResponse } from '~/services';

export type DataKeys = keyof Omit<RefDataResponse, 'grafana_url' | 'grafana_sessions_url' | 'stripe_public' | 'iap_apple_products' | 'intro_video'>

const createOptions = <T extends DataType.Union>(arr: T[]) => arr.map(
	({ id: value, name: label }) => ({ value, label })
);

const createMap = <T extends { id: string }>(arr: T[]): Record<string, T> => {
	const ____Map = {} as Record<string, T>;

	arr.forEach((item) => {
		____Map[item.id] = item;
	});

	return ____Map;
}

export const dataUtils = { createOptions, createMap, };

const state: RefDataResponse & {
	keyed: {
		genders: Record<string, DataType.Gender>,
		schools: Record<string, DataType.School>,
		available_schools: Record<string, DataType.School>,
		sports: Record<string, DataType.Sport>,
	},
	options: {
		genders: OptionDefault[],
		schools: OptionDefault[],
		available_schools: OptionDefault[],
		sports: OptionDefault[],
	},
} = {
	iap_apple_products: [],
	available_schools: [],
	stripe_public: '',
	intro_video: '',
	genders: [],
	schools: [],
	sports: [],
	keyed: {
		genders: {},
		schools: {},
		available_schools: {},
		sports: {},
	},
	options: {
		genders: [],
		schools: [],
		available_schools: [],
		sports: [],
	},
};

export const data = createModel<Store.General>()({
	state,
	reducers: {
		insert: (state, data: RefDataResponse) => {

			if (!data.available_schools) {
				data.available_schools = data.schools;
			}

			return {
				...state,
				...data,
				keyed: {
					genders: createMap(data.genders),
					schools: createMap(data.schools),
					available_schools: createMap(data.available_schools),
					sports: createMap(data.sports),
				},
				options: {
					genders: createOptions(data.genders),
					schools: createOptions(data.schools),
					available_schools: createOptions(data.available_schools),
					sports: createOptions(data.sports),
				},
			};

		},
	},
	effects: (dispatch) => ({
		retrieve: async () => {

			const data = await Data.read().promise;

			dispatch.data.insert(data);

		},
	}),
});
