import { IconArgs, IconProps } from '../components';

export const asIcon = (
	icon: IconArgs | null | undefined,
	append?: Omit<IconProps, 'name'>
): IconProps | null | undefined => {

	if (!icon) {
		return icon;
	}

	if (typeof icon !== 'string') {
		return { ...append, ...icon };
	}

	return { ...append, name: icon } as IconProps;

}
