import cn from 'classnames';

import { HTMLButton } from '~/types';

import { Loader } from './Loader';
import { asIcon, Icon, IconArgs } from './Icon';

export interface SquareIconProps extends HTMLButton {
	icon: IconArgs,
	link?: string,
	size?: number,
	plain?: boolean,
	loading?: boolean,
	disabled?: boolean
}

export const SquareIcon: React.FC<SquareIconProps> = (props) => {

	const {
		icon,
		link,
		size,
		plain,
		loading,
		className,
		...button
	} = props;

	const args = {
		className: cn(
			'app-square-icon',
			{
				clickable: !!button.onClick,
				'app-square-icon_plain': plain,
				'app-square-icon_loading': loading,
				'app-square-icon_disabled': button.disabled,
			},
			className,
		),
		style: size ?
			{
				width: size,
				height: size,
			} :
			undefined,
	}

	const children = loading ?
		<Loader loading={loading} /> :
		<Icon {...asIcon(icon)} />

	if (link) {
		return <a href={link} {...args} children={children} />
	}

	return <button {...button} {...args} children={children} />

}
