import { useCallback, useEffect, useMemo, useState } from 'react';
import { Business, handleError, RequestType, StatsScheme } from '~/services';

type State = StatsScheme & {
	loaded: boolean,
	loading: boolean
}

const initialStats: State = {
	loaded: false,
	loading: false,
	counters: {
		spent_total: 0,
		requests_by_status: {
			pending: 0,
			accepted: 0,
			finished: 0,
			cancelled: 0,
			in_progress: 0,
			pending_review: 0,
		},
		accepted_rejected_total: {
			type: 'total',
			accepted: 0,
			rejected: 0,
		},
		spent_by_athletes: [],
		spent_by_request_types: [],
		accepted_rejected_by_type: [],
	},
	filename: '',
};

export function useBusinessStats () {

	const [ { counters, ...state }, setStats ] = useState<State>(initialStats);

	const retrieve = useCallback(
		async () => {

			setStats((val) => ({
				...val,
				loading: true,
			}));

			try {

				const resp = await Business.getStats({
					send: false,
				}).promise;

				setStats({
					...resp,
					loaded: true,
					loading: false,
				});

			} catch (e) {

				handleError(e);

				setStats((val) => ({
					...val,
					loading: false,
				}));

			}

		},
		[]
	);

	useEffect(() => {

		retrieve();

	}, [ retrieve ]);

	const requestByType = useMemo(
		() => {

			const _map = {} as Record<RequestType, [ number, number ]>

			counters.accepted_rejected_by_type.forEach(
				({ type, accepted, rejected }) => _map[type] = [ accepted, rejected ]
			);

			return _map;

		},
		[ counters ]
	);

	return {
		data: counters,
		dataByType: requestByType,
		...state,
	}

}
