import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { App } from '~/types';
import { lang } from '~/constants';
import { useRosters } from '~/store';
import { Button, Roster, Skeleton } from '~/components';

import {
	PageContent,
	PageTitle,
	BusinessTabs,
	CreateRosterModal,
} from '~/containers';

export const Rosters: React.FC = () => {

	const { push } = useHistory();

	const { data, loading } = useRosters();

	const [ createModal, setCreateModal ] = useState(false);

	const onRosterClick = useCallback(
		({ id }: App.Rosters.Roster) => push(`/business/roster/${id}`),
		[ push ]
	);

	const renderItem = useCallback(
		(item: App.Rosters.Roster) =>
			<Roster
				key={item.id}
				data={item}
				onClick={onRosterClick} />
		,
		[ onRosterClick ]
	);

	const onCreateContinue = useCallback(
		(name: string) => push('/business/create-roster', { name }),
		[ push ]
	);

	const PageContentHeader = (
		<>
			<BusinessTabs />
			<div className="roster-create-button">
				<Button
					label={lang.ROSTERS_CREATE_BUTTON}
					onClick={setCreateModal.bind(null, true)}
					variant="primary"
					loading={loading.readRosters}
					disabled={loading.readRosters}
					disabledDeep />
			</div>
		</>
	);

	const EmptyContent = (
		<p className="content--empty">{lang.ROSTERS_EMPTY_LIST}</p>
	);

	return (
		<>

			<PageTitle
				title="Toolbox" />

			<PageContent
				data={data}
				loading={loading.readRosters}
				renderItem={renderItem}
				loadingComponent={<Skeleton type="notifications" />}
				pageContentHeader={PageContentHeader}
				pageEmptyComponent={EmptyContent}
				pageContentHeaderClassName="rosters-head" />

			<CreateRosterModal
				isVisible={createModal}
				setVisile={setCreateModal}
				onContinue={onCreateContinue} />

		</>
	);

}
