import React from 'react';
import dayjs from 'dayjs';

import { Rating } from './Rating';

import { mediaLink } from '~/utils';

interface AthleteReviewProps {
	name: string,
	date: number,
	photo: string,
	rating: number
}

export const AthleteReview: React.FC<AthleteReviewProps> = (props) => {

	const { name, date, photo, rating } = props;

	return (
		<div className="app--review">
			<img
				src={mediaLink(photo, 'S')}
				alt={`${name}`} />
			<div className="review--content">
				<h4 children={name} />
				<Rating value={rating} />
				<p children={dayjs(date * 1000).format('MMM D, YYYY')} />
			</div>
		</div>
	);

}
