import cn from 'classnames';
import { Controller } from 'react-hook-form';

import { getState, ControlledField, HTMLInput } from '~/types';

import { IconArgs } from './Icon';
import { ButtonProps } from './Button';
import { SquareIcon } from './SquareIcon';
import { useCallback } from 'react';

export interface InputPriceProps extends HTMLInput {
	icon?: IconArgs | null,
	note?: string,
	error?: string,
	prefix?: string,
	loading?: boolean,
	isSmall?: boolean,
	textarea?: boolean,
	innerRef?: React.LegacyRef<HTMLInputElement>,
	maxLength?: number,
	iconButton?: ButtonProps
}

type Props = ControlledField<InputPriceProps>

const InputPriceInner: React.FC<Props['inner']> = (props) => {

	const {
		icon,
		note,
		error,
		prefix = '$',
		loading,
		isSmall,
		textarea,
		innerRef,
		maxLength,
		iconButton,
		...input
	} = props;

	const state = getState(input);

	const onKeyPress = useCallback<
		React.KeyboardEventHandler<HTMLInputElement>
	>(
		(e) => {
			if (!/[0-9]/.test(e.key)) {
				e.preventDefault();
			}
		},
		[]
	);

	const className = cn(
		'app-input',
		'app-input--price',
		{
			invalid: !!state?.error || !!error,
			'has-value': Number(input.value) > 0,
			'is-small': isSmall,
		},
	);

	return (
		<div className={className}>
			{icon && (
			<SquareIcon
				icon={icon}
				type="button"
				loading={loading}
				disabled={loading}
				{...iconButton} />
			)}
			<div
				className="app-input--container"
				style={{ marginLeft: icon === null ? 65 : 0 }}>

				{textarea ? (
				<textarea
					ref={innerRef as any}
					className="app-input--element"
					onKeyPress={onKeyPress}
					{...input as any} />
				) : (
				<input
					ref={innerRef}
					className="app-input--element"
					onKeyPress={onKeyPress}
					{...input} />
				)}

				{(prefix && Number(input.value) > 0) &&
				<div className="app-input--prefix-container">
					<p>{prefix}<span>{`${input.value}`}</span></p>
				</div>
				}

				{!!state?.error?.message && (
				<p
					className="app-input--message"
					children={state.error.message} />
				)}

				{!!note &&
				<p className="app-input--message note" children={note} />
				}

				{!!error &&
				<p className="app-input--message" children={error} />
				}

			</div>
		</div>
	);

}

export const InputPrice: React.FC<Props['outer']> = (props) => {

	if (!('control' in props)) {
		return <InputPriceInner {...props} />
	}

	const {
		name = '',
		rules = {},
		control,
		defaultValue = '',
		...args
	} = props;

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState }) => {

				const { ref, value, onBlur, onChange } = field;

				return (
					<InputPriceInner
						{...args}
						name={name}
						value={value}
						state={fieldState}
						onBlur={onBlur}
						innerRef={ref}
						onChange={onChange} />
				);

			}} />
	);

}
