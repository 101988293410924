interface BackgroundProps {
	url: string,
	className?: string
}

export const Background: React.FC<BackgroundProps> = (props) => {

	const { url, className } = props;

	return (
		<div
			style={{ backgroundImage: `url(${url})` }}
			className={className}>
			{props.children}
		</div>
	);

}
