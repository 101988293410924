import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { App } from '~/types';

import { ChatMessage, ClickAction } from './ChatMessage';
import { Gallery } from '~/components/Gallery';
import { downloadAsset } from '~/services/chat';

export interface ChatMessagesProps {
	ticket: App.Chat.Ticket,
	isUserView?: boolean,
}

export const ChatMessages: React.FC<ChatMessagesProps> = (props) => {

	const { ticket, isUserView, } = props;

	const [ preview, setPreview ] = useState('');

	const onMessageClick = useCallback(
		(message: App.Chat.Message, meta?: App.Chat.AssetMeta, action?: ClickAction) => {
			if (action) {
				const [ type, url ] = action;
				type === 'download' ?
					downloadAsset(url, meta?.name || 'file') :
					setPreview(url);
			}
		},
		[]
	);

	const Messages = useMemo(
		() => ticket.messages.map((message) => (
			<ChatMessage
				key={message.id}
				isOuter={
					isUserView ?
						message.sent_by === 'user' :
						message.user_id !== ticket.user_id
				}
				message={message}
				// isSimpleMedia
				onMessageClick={onMessageClick} />
		)),
		[ ticket, isUserView, onMessageClick, ]
	);

	const className = cn(
		'app--chat-messages',
		{
			'app--chat-messages_empty': !ticket.messages.length,
		}
	);

	return (
		<div className={className}>
			{!!ticket.messages.length ?
			Messages :
			<p>There are no messages here yet</p>
			}
			<Gallery
				uri={preview}
				visible={!!preview}
				onDismiss={setPreview.bind(null, '')} />
		</div>
	);

}
