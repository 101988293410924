import { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth, useData } from '~/store';
import { lang } from '~/constants';
import { iconResolver, pushOrRemove } from '~/utils';
import { PageContent, PageTitle } from '~/containers';

import { Button, Flag, Grid, Search } from '~/components';
import { Account, handleError, isAthlete } from '~/services';

import { SignUpData, SurveyType } from '~/services/signup';

type SurveyDaya = Pick<SignUpData, 'school' | 'sport'>

export const Survey: React.FC = () => {

	const { goBack } = useHistory();

	const { account } = useAuth();

	const { getById, ...refData } = useData();

	const [ loading, setLoading ] = useState(false);

	const [ data, setData ] = useState<SurveyDaya>({
		school: [],
		sport: [],
	});

	useEffect(() => {

		if (!isAthlete(account)) {
			return;
		}

		const { athlete_info: info } = account;

		return setData({
			school: getById('schools', [info.school_id]),
			sport: getById('sports', info.sports.split(',')),
		});

	}, [ account, setData, getById ]);

	const [ search, setSearch ] = useState('');

	const filteredRefData = useMemo(() => {

		const _data = refData.available_schools;

		if (!search) {
			return _data;
		}

		return _data.filter(
			({ name }) => name
				.toLowerCase()
				.includes(search.toLowerCase())
		);

	}, [ refData, search ]);

	const toggleDataItem = useCallback(
		<T extends SurveyType.Item>(step: SurveyType.Step, value: T) => {

			setData((data) => ({
				...data,
				[step]: step === 'sport' ?
					pushOrRemove(data[step], value) :
					[ value ]
			}));

		},
		[]
	);

	const isDataItemPicked = useCallback(
		<T extends SurveyType.Item>(data: T[], value: T): boolean => {

			return !!data.filter(({ id }) => id === value.id)[0];

		},
		[]
	);

	const proceed = useCallback(
		async () => {

			if (!account) {
				return;
			}

			try {

				setLoading(true);

				const { school, sport } = data;

				if (isAthlete(account)) {

					await Account.update({
						...account,
						athlete_info: {
							...account.athlete_info,
							school_id: school[0].id,
							school_name: school[0].name,
							sports_played: sport[0].id,
							sports: sport.map(({ id }) => id).join(','),
						},
					}).promise;

				}

				goBack();

			} catch (e) {

				setLoading(false);

				handleError(e);

			}

		},
		[ account, data, goBack ]
	);

	return (
		<>
			<PageTitle
				title="Survey"
				onBack={goBack} />
			<PageContent
				pageContentClassName="page--settings">
				<h2 className="less-pad">{lang.SIGNUP_SURVEY_SPORT_TITLE}</h2>
				<p>{lang.SIGNUP_SURVEY_SPORT_KICKER}</p>
				<Grid
					content={refData.sports}
					rowCount={5}
					children={(sport) => {
						const onClick = () => toggleDataItem('sport', sport);
						const isSelected = isDataItemPicked(data.sport, sport);
						return (
							<Flag
								key={sport.id}
								label={sport.name}
								onClick={onClick}
								className="app--grid-item"
								isSelected={isSelected}>
								<i
									children={iconResolver({
										id: sport.id,
										key: 'sport',
										icon: true,
									})} />
							</Flag>
						);
					}}
					className="survey" />
				{refData.available_schools.length > 1 &&
				<>
					<h2 className="less-pad">{lang.SIGNUP_SURVEY_SCHOOL_TITLE}</h2>
					<p>{lang.SIGNUP_SURVEY_SPORT_KICKER}</p>
					<Search
						value={search}
						onSearch={setSearch}
						placeholder={lang.SIGNUP_SURVEY_SCHOOL_SEARCH} />
					<Grid
						content={filteredRefData}
						rowCount={5}
						children={(school) => {
							const onClick = () => toggleDataItem('school', school);
							const isSelected = isDataItemPicked(data.school, school);
							return (
								<Flag
									key={school.id}
									label={school.name}
									onClick={onClick}
									className="app--grid-item"
									isSelected={isSelected}>
									<i
										children={iconResolver({
											id: school.id,
											key: 'school',
											icon: true,
											originalName: school.name,
										})} />
								</Flag>
							);
						}}
						className="survey" />
				</>
				}
				<div className="proceed-btn">
					<Button
						label="Submit"
						variant="primary"
						onClick={proceed}
						disabled={loading || !data.sport.length || !data.school.length}
						disabledDeep />
				</div>
			</PageContent>
		</>
	);

}
