import { useCallback, useEffect, useState } from 'react';
import { useQueryParams } from 'use-query-params';

import { OptionParam, SearchParam } from '~/utils';

import { handleError } from './api';

import {
	Athlete,
	IAthlete,
	isReadSearchResponse,
	SEARCH_FIELDS_OPTIONS,
	SEARCH_PLACEHOLDER,
} from './modules';

export function useAthleteSearch (campaign?: boolean) {

	const [ athletes, setAthletes ] = useState<IAthlete[]>([]);

	const [ loading, setLoading ] = useState(0);

	const [ { filter, field: _field, legend }, setParams ] = useQueryParams({
		filter: SearchParam,
		field: OptionParam(SEARCH_FIELDS_OPTIONS, 'name'),
		legend: {
			decode: (val) => typeof val !== 'string' || val !== '1' ?
				true :
				undefined,
			encode: (val?: true) => val ? undefined : '1',
		},
	});

	const [ hasFeatured, setHasFeatured ] = useState(false);

	const [ field, setField ] = useState(_field);

	useEffect(
		() => {
			filter && setField(_field);
		},
		[ filter, _field ]
	);

	const retrieve = useCallback(async () => {

		try {

			setLoading(filter ? 2 : 1);

			const data = await Athlete[campaign ? 'readEndorsement' : 'read']({ filter, field, legend }).promise;

			setAthletes([]);
			setHasFeatured(false);

			const featued = isReadSearchResponse(data) ? data.featured_athletes : [];

			!!featued.length && setHasFeatured(true);

			setAthletes([
				...featued,
				...data.athletes,
			]);

		} catch (e) {

			handleError(e);

		} finally {

			setLoading(0);

		}

	}, [ filter, field, legend, campaign ]);

	useEffect(() => {

		retrieve();

	}, [ retrieve ]);

	const trigger = useCallback(
		(key?: 'filter' | 'field' | 'legend', value?: string) => {

			if (!key) {
				return setParams({ filter: undefined, field: undefined, legend: undefined, });
			}

			setParams(v => ({ ...v, [key]: value }));

		},
		[ setParams ]
	);

	return {
		athletes,
		loading,
		filter,
		field: _field,
		legend,
		trigger,
		hasFeatured,
		text: {
			SEARCH_FIELDS_OPTIONS,
			SEARCH_PLACEHOLDER,
		},
	};

}
