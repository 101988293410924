import { useCallback, useEffect, useState } from 'react';

import { Athlete, IAccountAthlete, IReview, IShoutout, ResponseFail } from '../services';

interface HookState {
	athlete: IAccountAthlete | null,
	reviews: IReview[],
	shoutouts: IShoutout[],
	isLoading: boolean,
	error?: string
}

interface HookArgs {
	id: string
}

const initialState: HookState = {
	athlete: null,
	reviews: [],
	shoutouts: [],
	isLoading: true,
};

export const useAthlete = (args: HookArgs) => {

	const [ state, setState ] = useState<HookState>(initialState);

	const retrieve = useCallback(async (id: string) => {

		if (!id) {
			return;
		}

		try {

			setState({
				...initialState,
				isLoading: true,
			});

			const data = await Athlete.read({ id }).promise;

			setState({
				...data,
				isLoading: false,
			});

		} catch (e) {

			setState({
				...initialState,
				isLoading: false,
				error: (e as ResponseFail).message,
			});

		}

	}, []);

	useEffect(() => {

		retrieve(args.id);

	}, [ args.id, retrieve ]);

	return {
		...state,
	};

}
