import cn from 'classnames';

import { INotification } from '~/services';
import { getRelativeTime } from '~/utils';

interface NotificationProps {
	data: INotification,
	onClick: (data: INotification, e: React.MouseEvent) => void
}

export const Notification: React.FC<NotificationProps> = (props) => {

	const { data, onClick } = props;

	return (
		<div
			onClick={(e) => onClick(data, e)}
			className={cn('app--notification', { 'is-read': data.is_read })}>
			<div className="app--notification-inner">
				<h4>{data.data}</h4>
				<p>{getRelativeTime(data.created_at)}</p>
				{!data.is_read && <div className="read-indictor" />}
			</div>
		</div>
	);

}
