import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';
import {
	getRequestByID,
	IRequestExtra,
	requestStatusResolve
} from '~/services/modules/Request';

export const useRequest2 = (id?: string, isCampaign?: boolean) => {

	const [ isEmpty, setIsEmpty ] = useState(false);

	const [ cached, _requests, request, loading, account, refresh ] = useSelector(
		({ requests, loading, auth }: Store.Root) => [
			requests.cached,
			requests.requests,
			getRequestByID(id, isCampaign, requests.requestsById),
			loading.effects.requests,
			auth.account,
			requests.refresh
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	useEffect(() => {

		if (!id || typeof isCampaign === 'undefined') {
			return void dispatch.requests.retrieveList({});
		}

		const req = async () => {

			setIsEmpty(false);

			const _isEmpty = await dispatch.requests.retrieve({ id, isCampaign });

			setIsEmpty(!_isEmpty);

		}

		req();

	}, [ id, isCampaign, dispatch, setIsEmpty ]);

	const requests = useMemo(
		() => {

			if (!_requests.length || account?.user_type !== 3) {
				return _requests;
			}

			const cRequests = {} as Record<string, IRequestExtra[]>;

			const _Requests = _requests.filter(
				(request) => {

					if (!request.endorsement_campaign_id) {
						return true;
					}

					const campaignId = request.endorsement_campaign_id;

					if (!cRequests[campaignId]) {
						cRequests[campaignId] = [];
					}

					cRequests[campaignId].push(request);

					return false;

				}
			);

			for (const campaignId in cRequests) {

				const requests = cRequests[campaignId];

				const request = requests[0];

				const request_amount = requests.reduce(
					(val, { status, request_amount }) => val += status !== 3 ? request_amount : 0, 0
				);

				const amount_to_athlete = requests.reduce(
					(val, { status, amount_to_athlete }) => val += status !== 3 ? amount_to_athlete : 0, 0
				);

				const fee_amount = requests.reduce(
					(val, { status, fee_amount }) => val += status !== 3 ? fee_amount : 0, 0
				);

				const status = requestStatusResolve(
					requests.map(({ status }) => status)
				);

				_Requests.push({
					id: campaignId,
					type: 'endorsement_campaign',
					paid: true,
					accepted_at: 0,
					is_apple_iap: false,
					status,
					name: request.name,
					payment: null,
					created_at: request.created_at,
					updated_at: 0,
					authorised: true,
					athlete_id: '',
					first_name: `${request.first_name} ${request.last_name}${requests.length > 1 ? ` and ${requests.length - 1} more` : ''}`,
					last_name: '',
					order_number: 0,
					profile_photo: '',
					request_amount,
					amount_to_athlete,
					fee_amount,
					expiration_time: request.expiration_time,
					requester_id: '',
					reviewed: false,
					camp: null,
					shoutout: null,
					video_chat: null,
					endorsement: null,
					social_media_platform: request.social_media_platform,
					training_session: null,
					speaking_engagement: null,
					personal_appearance: null,
					endorsement_campaign_id: '',
				});

			}

			return _Requests.sort((a, b) => a.created_at > b.created_at ? -1 : 1);

		},
		[ _requests, account?.user_type ],
	);

	return {
		cached,
		loading,
		requests,
		request,
		refresh,
		isEmpty,
		...dispatch.requests,
	};

}
