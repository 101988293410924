import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

interface PagesProps extends Omit<ReactPaginateProps, 'pageRangeDisplayed' | 'marginPagesDisplayed'> {
	compact?: boolean,
}

export const Pages: React.FC<PagesProps> = (props) => {

	const { compact, ...args } = props;

	return (
		<ReactPaginate
			containerClassName="pages"
			pageRangeDisplayed={compact ? 1 : 4}
			marginPagesDisplayed={compact ? 1 : 1}
			{...args} />
	);

}
