import { useCallback, useEffect, useState } from 'react';
import { Discount, Stripe } from '~/services';

export interface UsePromoCodeProps {
	amount?: number,
}

export const usePromoCode = ({ amount }: UsePromoCodeProps) => {

	const [ code, setCode ] = useState<string | undefined>();

	const [ error, setError ] = useState<string | undefined>();

	const [ discount, setDiscount ] = useState<Discount | undefined>(undefined);

	const [ isCalculating, setIsCalculating ] = useState(false);

	const calculate = useCallback(
		async (code: string, amount: number) => {

			setError(undefined);
			setDiscount(undefined);
			setIsCalculating(true);

			try {

				const discount = await Stripe.calculateDiscount({
					promo_code: code,
					request_amount: amount,
				}).promise;

				setDiscount(discount);

				setIsCalculating(false);

			} catch (e) {

				setError('Promo code is not available');
				setDiscount(undefined);
				setIsCalculating(false);

			}

		},
		[]
	);

	const resetCode = useCallback(
		() => {

			setCode(undefined);
			setError(undefined);
			setDiscount(undefined);
			setIsCalculating(false);

		},
		[]
	);

	useEffect(
		() => {

			if (!amount || !code) {
				return;
			}

			calculate(code, amount);

		},
		[ code, amount, calculate ]
	);

	return {
		code,
		error,
		amount,
		setCode,
		resetCode,
		discount,
		isCalculating,
	};

}
