import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import { NumberParam, BooleanParam, useQueryParam, withDefault, useQueryParams } from 'use-query-params';

import { App } from '~/types';
import { useEffectOnce } from '~/hooks';
import { useAuth, useChats, useNotifications } from '~/store';
import { PageContent, PageTitle } from '~/containers';
import { INotification, handleError } from '~/services';
import { Button, Notification, Skeleton, Tabs, Ticket } from '~/components';
import { ArchiveSwitch, ModalTicketAvailability, ModalTicketCreate, ModalTicketQA } from '~/containers/manage';

const tabs = [
	{ label: 'Notifications', emptyText: `You don't have any\n notifications yet` },
	{ label: 'Conversations', emptyText: `You don't have any\n conversations yet` },
];

export const Notifications: React.FC = () => {

	const { push } = useHistory();

	const { account } = useAuth();

	const [ tab, setTab ] = useQueryParam('tab', withDefault(NumberParam, 0));

	const [ supportArgs, setSupportArgs ] = useQueryParams({
		'archived': withDefault(BooleanParam, false),
	});

	const { tickets, ticketsQA, loading: loadingConversations, readTicketsMeta, } = useChats(
		undefined,
		supportArgs,
	);

	const { data: notifications, loading: loadingNotifications, setReadAsync, retrieve } = useNotifications();

	const [ createModal, setCreateModal ] = useState(false);

	const [ qaModal, setQAmodal ] = useState(false);

	const [ availability, setAvailability ] = useState<App.Endpoints.CreateTicket[1]>();

	const changeTab = (val: number) => {
		setTab(val, 'replace');
	}

	useEffectOnce(() => {

		retrieve({ force: true });

	});

	const onClick = useCallback(
		async (item: INotification | App.Chat.TicketStripped, e: React.MouseEvent) => {

			if (!('request_type' in item)) {
				return push(`/support/${item.id}`);
			}

			const {
				id,
				is_read,
				request_id,
				request_type,
				endorsement_campaign_id: campaign
			} = item;

			try {

				if (!is_read) {
					await setReadAsync(id);
				}

				if (e.altKey) {
					return;
				}

				if (!validate(request_id)) {
					return;
				}

				const path = request_type === 'ticket' ? 'support' : 'request';

				push(`/${path}/${campaign ? `@${campaign}` : request_id}`);

			} catch (e) {

				handleError(e);

			}

		},
		[ push, setReadAsync ]
	);

	const onTicketCreate = useCallback(
		async (data: App.Endpoints.CreateTicket[1]) => {

			if (data.availability) {
				return push(`/support/${data.ticket.id}`);
			}

			setCreateModal(false);

			setTimeout(() => {
				setAvailability(data);
			}, 200);

		},
		[ push ]
	);

	const data = useMemo<(INotification | App.Chat.TicketStripped)[]>(
		() => tab === 0 ? notifications : tickets,
		[ tab, notifications, tickets ]
	);

	const renderItem = useCallback(
		(item: INotification | App.Chat.TicketStripped) => {

			return 'request_type' in item ?
				<Notification key={item.id} data={item} onClick={onClick} /> :
				<Ticket key={item.id} data={item} onClick={onClick} />

		},
		[ onClick ]
	);

	const EmptyComponent = (
		<p className="content--empty" children={tabs[tab].emptyText} />
	);

	const Header = account?.user_type === 2 ?
		(
			<Tabs
				tab={tab}
				tabs={tabs}
				onChange={changeTab}
				tabsCounter={[notifications.length, tickets.length]} />
		) :
		undefined;

	const PageHeaderComponent = tab === 1 ?
		(
			<div className="support-head">
				<ArchiveSwitch
					label="Hide closed tickets"
					onClick={() => setSupportArgs((val) => ({ ...val, archived: !val.archived, }))}
					selected={!supportArgs.archived} />
				<Button
					label="Start conversation"
					onClick={() => {
						setQAmodal(true);
						readTicketsMeta();
					}}
					variant="primary" />
			</div>
		) :
		undefined

	return (
		<>

			<PageTitle
				title="Message Center"
				content={Header} />

			<PageContent
				data={data}
				loading={
					tab === 0 ?
					loadingNotifications.retrieve :
					loadingConversations.readTickets
				}
				renderItem={renderItem}
				loadingComponent={<Skeleton type="notifications" />}
				pageContentHeader={PageHeaderComponent}
				pageEmptyComponent={EmptyComponent} />

			<ModalTicketCreate
				visible={createModal}
				isLoading={loadingConversations.createTicket}
				isUserView
				onHide={setCreateModal.bind(null, false)}
				onCreate={onTicketCreate} />

			<ModalTicketQA
				data={ticketsQA}
				visible={qaModal}
				isLoading={loadingConversations.readTicketsMeta}
				onHide={setQAmodal.bind(null, false)}
				onCreate={() => {
					setQAmodal(false);
					setTimeout(() => {
						setCreateModal(true);
					}, 200);
				}} />

			<ModalTicketAvailability
				data={availability}
				visible={!!availability}
				onConfirm={({ ticket }) => push(`/support/${ticket.id}`)} />

		</>
	);

}
