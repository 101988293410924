export const pushOrRemove = <T,>(arr: T[], val: T, key?: keyof T) => {

	if (
		!arr.includes(val) ||
		(key && !isInArray(arr, val, key))
	) {
		return [ ...arr, val ];
	}

	const _arr = [ ...arr ];
	const _key = _arr.indexOf(val);

	_arr.splice(_key, 1);

	return _arr;

}

export const asArray = <T>(
	item: T | T[],
	split?: (item: T) => T[]
): T[] => {
	return Array.isArray(item) ? item : split ? split(item) : [ item ];
}

export const isInArray = <T>(arr: T[], item: T, key: keyof T) => {

	return arr.filter(
		(_item) => _item[key] === item[key]
	).length > 0;

}

export const fromArray = <T,>(
	val: T | T[],
	key?: number,
	defaultKey: number = 0
): T => {
	return Array.isArray(val) ? val[key || defaultKey] : val;
}

export const isLastN = (len: unknown[] | number, i: number, limit: number) => {

	if (Array.isArray(len)) {
		len = len.length;
	}

	return i >= len - limit;

}

export const isLast = (len: unknown[] | number, i: number) => {

	return isLastN(len, i, 1);

}

export const _isInArray = <T extends { id: string }>(arr: T[], item: T) => {

	const _isFound = !!arr.filter(({ id }) => id === item.id)[0];

	return _isFound;

}

export const _pushOrRemove = <T extends { id: string }>(arr: T[], item: T) => {

	if (_isInArray(arr, item)) {
		return arr.filter(({ id }) => id !== item.id);
	}

	return [ item, ...arr ];

}
