import { useData } from '~/store';

export const Analytics: React.FC = () => {

	const { grafana_url } = useData();

	return (
		<div className="manage-content frame">
			{!!grafana_url &&
			<iframe src={grafana_url} title="Grafana Statistics" />
			}
		</div>
	);

}
