const gMapPath = 'https://maps.googleapis.com/maps/api/geocode/json';

interface GoogleMapConfig {
	key: string
}

export const gMapsAPI = (options: GoogleMapConfig) => {

	const { key } = options;

	const search = async (address: string) => {

		try {

			const url = `${gMapPath}?address=${address}&key=${key}`;

			const resp = await fetch(url);
			const json = await resp.json();

			return json;

		} catch (error) {

			throw new Error('Unknown map issue');

		}

	}

	const getBoundsZoom = (bounds: {
		northeast: { lat: number, lng: number }
		southwest: { lat: number, lng: number }
	}, mapDim: {
		width: number,
		height: number
	}) => {

		if (!bounds) {
			return 10;
		}

		const WORLD_DIM = { height: 256, width: 256 };
		const ZOOM_MAX = 21;

		function latRad (lat: number) {

			const sin = Math.sin(lat * Math.PI / 180);
			const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;

			return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;

		}

		function zoom (mapPx: number, worldPx: number, fraction: number) {
			return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
		}

		const ne = bounds.northeast;
		const sw = bounds.southwest;

		const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

		const lngDiff = ne.lng - sw.lng;
		const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

		const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
		const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

		return Math.min(latZoom, lngZoom, ZOOM_MAX);

	}

	return {
		search,
		getBoundsZoom,
	};

}
