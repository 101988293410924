import { Controller } from 'react-hook-form';

import { ControlledProps } from '../types';
import { QuantitySelector, QuantitySelectorProps } from './QuantitySelector';

export type CQuantitySelectorProps =
	Omit<QuantitySelectorProps, 'amount' | 'onChange'> &
	(ControlledProps & { defaultValue?: number });

export const CQuantitySelector: React.FC<CQuantitySelectorProps> = (props) => {

	const { name, rules, control, defaultValue, ...args } = props;

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			defaultValue={defaultValue || 1}
			render={({ field }) => {
				return (
					<QuantitySelector
						{...args}
						amount={field.value}
						onChange={field.onChange} />
				);
			}} />
	);

}
