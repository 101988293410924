import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Input, Modal, ModalProps } from '~/components';

export interface ModalTicketCloseForm {
	comment: string,
}

export interface ModalTicketCloseProps extends ModalProps {
	isUserView?: boolean,
	onCommentSubmit?: (comment: string) => Promise<void>,
}

export const ModalTicketClose: React.FC<ModalTicketCloseProps> = (props) => {

	const { onCommentSubmit, isUserView, onHide, ...args } = props;

	const { control, handleSubmit, reset } = useForm<ModalTicketCloseForm>({
		mode: 'onChange',
	});

	const onSubmit = useCallback(
		async ({ comment = '' }: ModalTicketCloseForm) => {

			await onCommentSubmit?.(comment);

			reset();

			onHide?.();

		},
		[ onCommentSubmit, reset, onHide ]
	);

	return (
		<Modal
			icon={isUserView ? 'ticketShield' : undefined}
			title="Closing a ticket"
			onHide={onHide}
			className="form-modal"
			isEnhanced={!isUserView}
			{...args}>
			<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<p className="message">Specify the reason for ending the chat</p>
				<Input
					icon="description"
					name="comment"
					control={control}
					textarea
					placeholder="Reason for termination" />
				<div className="actions">
					<Button
						label="OK"
						variant="primary"
						onClick={handleSubmit(onSubmit)}
						loading={args.isLoading} />
					<Button
						type="button"
						label="Cancel"
						onClick={onHide} />
				</div>
			</form>
		</Modal>
	);

}
