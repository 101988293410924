import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { useAuth } from '~/store';
import { lang, phoneRegexr } from '~/constants';

import { Button, Check, Input, InputPhone, Wrapper } from '~/components';
import { Account, ResponseFail, SignUpInput, UserType } from '~/services';
import { Terms } from '../containers/Modals';

export const SignUp: React.FC = () => {

	const { authorize } = useAuth();

	const [ userType, setUserType ] = useState<UserType | null>(null);

	const [ eulaModal, setEulaModal ] = useState<boolean>(false);

	const [ loading, setLoading ] = useState(false);

	const { control, handleSubmit, setError, setValue } = useForm<SignUpInput>();

	const submit = async (data: SignUpInput) => {

		if (!userType) {
			return;
		}

		try {

			setLoading(true);

			data.user_type = userType;

			const { account, session_token: token } = await Account.signUp(data).promise;

			authorize({
				token,
				account,
			});

		} catch (e) {

			setLoading(false);

			const { message } = e as ResponseFail;

			setError('email', { message });

		}

	}

	const setEula = (type: UserType | null) => {

		if (!type) {
			setUserType(type);
			setEulaModal(false);
			return;
		}

		setUserType(type);
		setEulaModal(false);

	}

	const EulaCheckbox = (
		<>
			Accept <span className="terms-link" onClick={setEulaModal.bind(null, true)}>EULA State of Michigan End user license agreement</span>
		</>
	);

	return (
		<div className="container container--centered">
			<Wrapper
				icon="bigArrowRight"
				title="Sign Up"
				className="wrapper--login">
				{(!userType || eulaModal) ? (
				<>
					<p className="header">I am a…</p>
					<Button
						label="Athlete"
						variant="primary"
						onClick={() => setEula(2)} />
					<Button
						label="Fan"
						variant="secondary"
						onClick={() => setEula(1)} />
					<Button
						label="Business"
						onClick={() => setEula(3)} />
					<Button
						to="/login"
						label={<><span style={{ opacity: .6 }}>Already have an account?</span> <b>LOGIN</b></>}
						variant="hollow" />
					<Button
						to="/dashboard"
						label="Continue as guest" />
				</>
				) : (
				<form onSubmit={handleSubmit(submit)}>
					<Input
						icon="mail"
						type="email"
						name="email"
						rules={{ required: 'Email is required' }}
						control={control}
						placeholder="Email" />
					<Input
						icon="lock"
						type="password"
						name="password"
						rules={{ required: 'Password is required' }}
						control={control}
						placeholder="Password" />
					<InputPhone
						name="phone"
						icon="phone"
						control={control}
						rules={{
							required: 'Phone number is required',
							validate: (val) => phoneRegexr.test(val) ?
								true :
								lang.INVALID_PHONE,
						}} />
					<Controller
						name="eula"
						rules={{ required: true }}
						render={({ field: { value, onChange }, fieldState: { invalid } }) => (
							<Check
								type="button"
								checked={value}
								invalid={invalid}
								onClick={onChange.bind(null, !value)}
								children={EulaCheckbox} />
						)}
						control={control} />
					<Button
						label="Sign Up"
						variant="primary"
						loading={loading} />
					<Button
						label="Cancel"
						onClick={() => setEula(null)} />
				</form>
				)}
			</Wrapper>
			<Terms
				onHide={() => {
					setValue('eula', false);
					setEulaModal(false);
				}}
				visible={eulaModal}
				onAccept={() => {
					setValue('eula', true);
					setEulaModal(false);
				}} />
		</div>
	);

}
