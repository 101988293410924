import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';

import { lang } from '~/constants';
import { Account, handleError } from '~/services';
import { Button, Input, InputCode, Wrapper } from '~/components';

const STORE_KEY = 'restore_email';

interface ModalRestoreProps {
	email?: string,
	setEmail: (val: string) => void
}

interface RestoreConfirmModalProps extends ModalRestoreProps {
	email: string
}

type RestoreInput = {
	email: string
}

type RestoreConfirmInput = {
	password: string
}

const RestoreConfirmModal: React.FC<RestoreConfirmModalProps> = (props) => {

	const { email, setEmail } = props;

	const { push } = useHistory();

	const [ loading, setLoading ] = useState(false);

	const { handleSubmit, control } = useForm<RestoreConfirmInput>();

	const [ code, setCode ] = useState('');

	const [ error, setError ] = useState('');

	const submit = async (data: RestoreConfirmInput) => {

		if (code.length !== 6) {
			return setError('Please, enter the code');
		}

		setError('');

		try {

			setLoading(true);

			const input = {
				email,
				password: data.password,
				verificationCode: code,
			};

			await Account.resetPasswordConfirm(input).promise;

			window.confirm(
				`${lang.PASSWORD_RESTORED_LABEL}. ${lang.PASSWORD_RESTORED_MESSAGE}`,
			);

			setEmail('');

			push('/login');

		} catch (e) {

			handleError(e, ({ code }) => [200, 202].includes(code) && 12001);

		} finally {

			setLoading(false);

		}

	}

	return (
		<div className="container container--centered">
			<Wrapper
				icon="bigArrowRight"
				title="Sign In"
				className="wrapper--login login--restore">
				<p
					className="message"
					children={lang.PASSWORD_RESTORE_CONFIRM} />
				<InputCode
					name="code"
					type="number"
					error={error}
					onChange={setCode}
					inputMode="numeric" />
				<Input
					icon="lock"
					name="password"
					rules={{ required: true }}
					control={control}
					placeholder="Password" />
				<Button
					label="Reset password"
					variant="primary"
					loading={loading}
					onClick={handleSubmit(submit)} />
				<Button
					label="Cancel"
					onClick={() => setEmail('')} />
			</Wrapper>
		</div>
	);

}

export const Restore: React.FC = () => {

	const { goBack } = useHistory();

  const [ email, setEmail ] = useLocalStorage(STORE_KEY, '');

	const [ loading, setLoading ] = useState(false);

	const { handleSubmit, control } = useForm<RestoreInput>();

	const submit = async (data: RestoreInput) => {

		try {

			setLoading(true);

			await Account.resetPassword(data).promise;

			setEmail(data.email);

		} catch (e) {

			handleError(e, ({ code }) => {

				code === 200 && setEmail(data.email);

				return code === 200;

			});

		} finally {

			setLoading(false);

		}

	}

	if (email) {
		return (
			<RestoreConfirmModal
				email={email}
				setEmail={setEmail} />
		);
	}

	return (
		<div className="container container--centered">
			<Wrapper
				icon="bigArrowRight"
				title="Sign In"
				className="wrapper--login login--restore">
				<p
					className="message"
					children={lang.PASSWORD_RESTORE} />
				<form onSubmit={handleSubmit(submit)}>
					<Input
						name="email"
						icon="mail"
						type="email"
						rules={{ required: true }}
						control={control}
						placeholder="Email" />
					<Button
						label="Reset password"
						variant="primary"
						loading={loading} />
				</form>
				<Button
					label="Cancel"
					onClick={goBack} />
			</Wrapper>
		</div>
	);

}
