import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import cn from 'classnames';
import { Controller } from 'react-hook-form';

import { asIcon } from '~/utils';
import { ControlledField, getState } from '../types';

import { IconArgs } from './Icon';
import { SquareIcon } from './SquareIcon';

export interface InputPhoneProps extends Omit<PhoneInputProps, 'mask'> {
	icon?: IconArgs | null,
	value?: string,
	defaultValue?: string,
}

type Field = ControlledField<InputPhoneProps>

// const phoneMask = ['+','1',' ',/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,/\d/];

const InputPhoneInner: React.FC<Field['inner']> = (props) => {

	const {
		icon,
		value = '',
		...input
	} = props;

	const _icon = asIcon(icon);

	const state = getState(input);

	return (
		<div className={cn('app--input-phone', { invalid: !!state?.error })}>
			{_icon && (
			<SquareIcon icon={_icon} />
			)}
			<div className="app--input-phone-container">
				<PhoneInput
					{...input}
					specialLabel=""
					enableAreaCodes
					disableCountryGuess
					inputClass="app--input-phone-element"
					country="us"
					onlyCountries={['us']}
					value={value} />
				{!!state?.error?.message && (
				<p
					className="app-input--message"
					children={state.error.message} />
				)}
			</div>
		</div>
	);

}

export const InputPhone: React.FC<Field['outer']> = (props) => {

	if (!('control' in props)) {
		return <InputPhoneInner {...props} />
	}

	const {
		name = '',
		rules = {},
		control,
		defaultValue = '',
	} = props;

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState }) => {

				const { value, onBlur, onChange } = field;

				return (
					<InputPhoneInner
						{...props}
						value={value}
						state={fieldState}
						onBlur={onBlur}
						onChange={(value) => onChange(`+${value}`)} />
				);

			}} />
	);

}
