import { createModel } from '@rematch/core';

import { Store } from '../';
import { App } from '~/types';
import { ResponseFail, Rosters } from '~/services';

interface RostersState {
	rosters: App.Rosters.Roster[],
	rostersById: Record<string, App.Rosters.RosterDetailed | null>,
}

const state: RostersState = {
	rosters: [],
	rostersById: {},
}

export const rosters = createModel<Store.General>()({
	state,
	reducers: {
		INSERT_ROSTERS: (state, payload: App.Rosters.Endpoints.Read[1]) => {

			state.rosters = payload.rosters;

			return state;

		},
		UNSHIFT_ROSTERS: (state, payload: App.Rosters.Roster) => {

			state.rosters.unshift(payload);

			return state;

		},
		REPLACE_ROSTERS: (state, payload: App.Rosters.Roster) => {

			state.rosters = state.rosters.map(
				(roster) => roster.id === payload.id ? payload : roster
			);

			return state;

		},
		INSERT_ROSTER: (state, { rosters }: App.Rosters.Endpoints.ReadById[1]) => {

			// TODO: Update to non plural;
			state.rostersById[rosters.id] = rosters;

			return state;

		},
		INSERT_EMPTY_ROSTER: (state, id: string) => {

			state.rostersById[id] = null;

			return state;

		},
		DELETE_ROSTER: (state, { id }: App.Rosters.Endpoints.Delete[0]) => {

			delete state.rostersById[id];

			return state;

		},
		DELETE_ROSTERS: (state, { id }: App.Rosters.Endpoints.Delete[0]) => {

			state.rosters = state.rosters.filter(
				(roster) => roster.id !== id,
			);

			return state;

		},
	},
	effects: (dispatch) => ({
		readRosters: async () => {

			try {

				const result = await Rosters.read().promise;

				dispatch.rosters.INSERT_ROSTERS(result);

			} catch (error) {

				return {
					error: error as ResponseFail,
				}

			}

		},
		readRostersById: async (id: string) => {

			try {

				const result = await Rosters.readById({ id }).promise;

				dispatch.rosters.INSERT_ROSTER(result);

			} catch (error) {

				const e = error as ResponseFail;

				if (e.message === 'record not found') {
					dispatch.rosters.INSERT_EMPTY_ROSTER(id);
				}

				return {
					error: error as ResponseFail,
				}

			}

		},
		createRoster: async (data: App.Rosters.Endpoints.Create[0]) => {

			try {

				const { roster } = await Rosters.create(data).promise;

				// TODO: Update to non plural;
				dispatch.rosters.INSERT_ROSTER({ rosters: roster });

				dispatch.rosters.UNSHIFT_ROSTERS({ ...roster, athletes: undefined, } as App.Rosters.Roster);

				return {
					roster,
				}

			} catch (error) {

				return {
					error: error as ResponseFail,
				}

			}

		},
		updateRoster: async (data: App.Rosters.Endpoints.Update[0]) => {

			try {

				const { roster } = await Rosters.update(data).promise;

				// TODO: Update to non plural;
				dispatch.rosters.INSERT_ROSTER({ rosters: roster });

				dispatch.rosters.REPLACE_ROSTERS({ ...roster, athletes: undefined, } as App.Rosters.Roster);

				return {
					roster,
				}

			} catch (error) {

				return {
					error: error as ResponseFail,
				}

			}

		},
		deleteRoster: async (data: App.Rosters.Endpoints.Delete[0]) => {

			try {

				await Rosters.delete(data).promise;

				// TODO: Update to non plural;
				dispatch.rosters.DELETE_ROSTER(data);

				dispatch.rosters.DELETE_ROSTERS(data);

				return {}

			} catch (error) {

				return {
					error: error as ResponseFail,
				}

			}

		},
	}),
});
