import { useCallback, useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useAuth } from '~/store';

export const useFastAuth = (key: string) => {

	const { loading, retrieve } = useAuth();

	const [ token ] = useQueryParam(key, StringParam);

	const auth = useCallback(
		async (token: string) => {
			await retrieve(token);
		},
		[ retrieve ]
	);

	useEffect(
		() => void (token ? auth(token) : null),
		[ auth, token ]
	);

	return { loading: loading.retrieve };

}
