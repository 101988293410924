export async function saveCSV (content: string, fileName: string) {

	content = `data:text/csv;charset=utf-8,${content}`;

	const data = encodeURI(content);

	const link = document.createElement('a');
				link.setAttribute('href', data);
				link.setAttribute('download', fileName);

	document.body.appendChild(link);

	link.click();

}
