export const getVideoDuration = async (file: Blob, round?: boolean) => new Promise<number>(
	(resolve, reject) => {

		const video = document.createElement('video');

		video.preload = 'metadata';

		video.onloadedmetadata = () => {

			window.URL.revokeObjectURL(video.src);

			const duration = round ?
				Math.round(video.duration) :
				video.duration;

			resolve(duration);

		}

		video.src = URL.createObjectURL(file);

		// const reader = new FileReader();

		// reader.onloadend = () => {

		// 	if (!reader.result) {
		// 		return reject('Unable to get file reader result');
		// 	}

		// 	const media = new Audio(reader.result as string);

		// 	media.onloadedmetadata = () => {

		// 		const duration = round ? Math.round(media.duration) : media.duration;

		// 		resolve(duration);

		// 	}

		// }

		// reader.readAsDataURL(file);

	}
);
