import { daysLeft } from '~/utils';
import { IconParam } from '~/components';
import { RequestPageProps } from '~/services';

export const RequestHead: React.FC<RequestPageProps> = (props) => {

	const { request } = props;

	const { status, expiration_time } = request;

	if (status !== 1) {
		return null;
	}

	return (
		<div className="app--request-head">
			<IconParam
				icon={{ name: 'calendar' }}
				text={`${daysLeft(expiration_time)} left to accept`} />
		</div>
	);

}
