export const clipboardCopy = (text: string): Promise<unknown> => {

	const _fb = (text: string, resolve: (value: unknown) => void) => {

		const ta = document.createElement('textarea');
					ta.value = text;
					ta.style.top = '0';
					ta.style.left = '0';
					ta.style.position = 'fixed';

		document.body.appendChild(ta);

		ta.focus();
		ta.select();

		document.execCommand('copy');

		document.body.removeChild(ta);

		resolve(true);

	}

	return new Promise(async (resolve, reject) => {

		if (!navigator.clipboard) {
			return void _fb(text, resolve);
		}

		try {
			await navigator.clipboard.writeText(text);
			resolve(true);
		} catch (e) {
			reject(e);
		}

	});

}
