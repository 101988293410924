import { useCallback, useState, ChangeEventHandler, useRef } from 'react';

import { lang } from '~/constants';
import { Button, Input, Modal } from '~/components';

interface CreateRosterModalProps {
	isVisible: boolean,
	setVisile: (state: boolean) => void,
	onContinue: (name: string) => void,
}

export const CreateRosterModal: React.FC<CreateRosterModalProps> = (props) => {

	const { isVisible, setVisile, onContinue, } = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const [ name, setName ] = useState('');

	const onInputChange = useCallback<
		ChangeEventHandler<HTMLInputElement>
	>(
		(e) => setName(e.target.value),
		[]
	);

	const onCreate = useCallback(
		() => {

			if (!name.length) {
				return inputRef.current?.focus();
			}

			onContinue(name);

		},
		[ name, inputRef, onContinue ]
	);

	return (
		<Modal
			icon="Whistle"
			title={lang.ROSTER_CREATE_TITLE}
			onHide={() => setVisile(false)}
			visible={isVisible}
			className="roster--modal">
			<>
				<p
					className="message"
					children={lang.ROSTER_CREATE_TEXT} />
				<Input
					icon="Whistle"
					value={name}
					innerRef={inputRef}
					onChange={onInputChange}
					placeholder={lang.ROSTERS_NAME_PLACEHOLDER} />
				<Button
					label="Continue"
					variant="primary"
					onClick={onCreate}
					disabled={!name.length}
					disabledDeep />
				<Button
					label="Cancel"
					onClick={() => setVisile(false)} />
			</>
		</Modal>
	);

}
