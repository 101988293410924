import cn from 'classnames';
import { Discount } from '~/services';

interface BigAmountProps {
	amount: number | string,
	prefix?: string,
	discount?: Discount,
}

export const BigAmount: React.FC<BigAmountProps> = (props) => {

	const { amount, prefix = '$', discount } = props;

	const price = typeof amount === 'number' ? `${prefix}${amount}` : amount;

	return (
		<p
			className={cn('app--big-amount', { 'has-discount': !!discount })}
			children={
				!discount ?
				price :
				<>
					{prefix}{discount.result_amount.toFixed(2)} <span className="original-amount">{price}</span>
				</>
			} />
	);

}
