import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import { App, Option } from '~/types';
import { langManage } from '~/constants';
import { discountTypeOptions } from '~/services';
import { Button, Input, CDatePicker, RSelect } from '~/components';

type PromoCodeInput = App.Endpoints.CreatePromoCode[0] | App.Endpoints.UpdatePromoCode[0];

type PromoCodeForm = Omit<PromoCodeInput, 'discount_type'> & {
	discount_type: Option<App.Manage.DiscountType>
};

interface ModalPromoCodeCreateProps {
	promoCode?: App.Manage.PromoCode,
	loading: boolean,
	onHide: () => void,
	onCreate: (form: PromoCodeInput, id?: string) => void
}

const lang = langManage.promoCodes;

const isValidAmount = (val: any) => {
	return isNaN(+ val) ? 'Please enter valid amount' : true;
}

export const ModalPromoCodeCreate: React.FC<ModalPromoCodeCreateProps> = (props) => {

	const { promoCode, loading, onHide, onCreate } = props;

	const { control, handleSubmit, watch } = useForm<PromoCodeForm>({
		defaultValues: {
			...promoCode,
			usage_limit: promoCode?.usage_limit === 0 ?
				undefined : promoCode?.usage_limit,
			usage_per_user_limit: promoCode?.usage_per_user_limit === 0 ?
				undefined : promoCode?.usage_per_user_limit,
			total_discount_amount_limit: promoCode?.total_discount_amount_limit === 0 ?
				undefined : promoCode?.total_discount_amount_limit,
			discount_amount: promoCode ?
				promoCode.discount_type === 'percent' ?
					promoCode.discount_amount * 100 :
					promoCode.discount_amount :
				undefined,
			discount_type: promoCode ?
				discountTypeOptions.filter(
					({ value }) => value === promoCode.discount_type
				)[0] :
				undefined,
		},
	});

	const submit = (data: PromoCodeForm) => {
		data.discount_amount = + data.discount_amount;
		data.total_discount_amount_limit = + data.total_discount_amount_limit;
		data.usage_limit = + data.usage_limit;
		data.usage_per_user_limit = + data.usage_per_user_limit;
		if (data.expiration_time && typeof data.expiration_time !== 'number') {
			data.expiration_time = dayjs(data.expiration_time).unix();
		}
		if (data.discount_type.value === 'percent') {
			data.discount_amount = data.discount_amount / 100;
		}
		onCreate({
			...data,
			discount_type: data.discount_type.value as App.Manage.DiscountType,
		}, promoCode?.id);
	}

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Input
				icon="description"
				name="code"
				note={lang.NOTE_CODE}
				rules={{ required: true }}
				control={control}
				placeholder={lang.PLACEHOLDER_CODE} />
			<div className="modal--row">
				<RSelect
					name="discount_type"
					icon="description"
					note={lang.NOTE_DISCOUNT_TYPE}
					rules={{ required: true }}
					control={control}
					options={discountTypeOptions}
					placeholder={lang.PLACEHOLDER_DISCOUNT_TYPE} />
				<CDatePicker
					icon="calendar"
					name="expiration_time"
					note={lang.NOTE_DISCOUNT_EXPIRATION_DATE}
					control={control}
					minimumDate={new Date()}
					placeholder={lang.PLACEHOLDER_DISCOUNT_EXPIRATION_DATE} />
			</div>
			<div className="modal--row">
				<Input
					icon="coin"
					name="discount_amount"
					note={lang.NOTE_DISCOUNT_AMOUNT}
					rules={{
						required: true,
						validate: (val) => {
							const _val = + val;
							if (isNaN(_val)) {
								return 'Please enter valid amount';
							}
							if (watch('discount_type').value === 'percent') {
								return _val < 1 || _val > 100 ? 'Please enter valid percentage between 1 and 100' : true;
							}
						}
					}}
					control={control}
					placeholder={lang.PLACEHOLDER_DISCOUNT_AMOUNT} />
				<Input
					icon="clock"
					name="total_discount_amount_limit"
					note={lang.NOTE_TOTAL_LIMIT_AMOUNT}
					rules={{
						validate: isValidAmount,
					}}
					control={control}
					placeholder={lang.PLACEHOLDER_TOTAL_LIMIT_AMOUNT} />
			</div>
			<div className="modal--row">
				<Input
					icon="clock"
					name="usage_limit"
					note={lang.NOTE_USAGE_LIMIT}
					rules={{
						validate: isValidAmount,
					}}
					control={control}
					placeholder={lang.PLACEHOLDER_USAGE_LIMIT} />
				<Input
					icon="clock"
					name="usage_per_user_limit"
					note={lang.NOTE_USAGE_LIMIT_PER_USER}
					rules={{
						validate: isValidAmount,
					}}
					control={control}
					placeholder={lang.PLACEHOLDER_USAGE_LIMIT_PER_USER} />
			</div>
			<div className="actions">
				<Button
					loading={loading}
					variant="primary"
					label={promoCode ? 'Update' : 'Create'} />
				<Button
					type="button"
					onClick={onHide}
					label="Cancel" />
			</div>
		</form>
	);

}
