import { config } from '~/constants';
import { Button, Modal, ModalProps } from '~/components';

interface TermsProps extends Omit<ModalProps, 'title'> {
	onAccept: () => void
}

export const Terms: React.FC<TermsProps> = (props) => {

	const { onAccept, ...modal } = props;

	return (
		<Modal
			{...modal}
			title="EULA"
			className="modal--eula">
			<iframe
				src={`${config.URL_BASE}/eula.html`}
				title="Terms &amp; conditions"
				frameBorder="0" />
			<div className="modal-actions">
				<Button
					onClick={modal.onHide}
					label="Decline" />
				<Button
					variant="primary"
					onClick={onAccept}
					label="Accept" />
			</div>
		</Modal>
	);

}
