import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
	useQueryParams,
	withDefault,
	BooleanParam,
	createEnumParam,
	QueryParamConfig,
	StringParam,
} from 'use-query-params';

import { App } from '~/types';
import { useChats } from '~/store';
import { Button, Select } from '~/components';
import {
	Ticket,
	Container,
	ArchiveSwitch,
	ModalTicketCreate,
	ChatManageSearch,
	ChatSearchChip,
} from '~/containers/manage';

const listOrderOptions = [
	{ value: 'new-messages-first', label: 'By date of last message', },
	{ value: 'new-tickets-first', label: 'By date of ticket creation', },
]

const OrderParam = createEnumParam([
	'new-messages-first',
	'new-tickets-first',
]);

export const Chats: React.FC = () => {

	const [ args, setQuery ] = useQueryParams({
		order: withDefault(OrderParam, 'new-messages-first') as QueryParamConfig<App.Chat.TicketOrder>,
		subject: StringParam,
		user_id: StringParam,
		ticket_id: StringParam,
		archived: withDefault(BooleanParam, false),
	});

	const [ createModal, setCreateModal ] = useState(false);

	const { tickets, loading } = useChats(
		undefined,
		args
	);

	const create = useCallback(
		async (_data: App.Endpoints.CreateTicket[1]) => {

			setCreateModal(false);

			toast.info('Conversation has been created');

			setQuery({
				order: 'new-messages-first',
				subject: undefined,
				user_id: undefined,
				archived: false,
				ticket_id: undefined,
			});

		},
		[ setQuery ]
	);

	const PageControls = (
		<>
			<Button
				label="New conversation"
				variant="primary"
				onClick={setCreateModal.bind(null, true)} />
			<ChatManageSearch
				onChange={({ field, value }) => {
					setQuery((val) => ({
						...val,
						user_id: undefined,
						subject: undefined,
						ticket_id: undefined,
						[field]: typeof value !== 'string' ?
							value.id :
							value,
					}));
				}} />
		</>
	);

	const PageMiddle = (
		<>
			<div className="filters">
				<Select
					value={args.order}
					compact
					options={listOrderOptions}
					onChange={({ value }) => setQuery({ order: value as App.Chat.TicketOrder, })}
					defaultValue={args.order} />
				<ArchiveSwitch
					label="Hide closed chats"
					onClick={() => setQuery((val) => ({ ...val, archived: !val.archived, }))}
					selected={!args.archived} />
			</div>
			<div className="search-chips">
				{!!args.subject &&
				<ChatSearchChip
					label="Subject"
					value={args.subject}
					onRemove={() => setQuery((val) => ({ ...val, subject: undefined, }))} />
				}
				{!!args.user_id &&
				<ChatSearchChip
					label="User"
					value={
						// tickets?.[0] ?
						// `${tickets?.[0].first_name} ${tickets?.[0].last_name}` :
						args.user_id
					}
					onRemove={() => setQuery((val) => ({ ...val, user_id: undefined, }))}
					// textLimit={tickets?.[0] ? 0 : 8}
					textLimit={8}
					/>
				}
				{!!args.archived &&
				<ChatSearchChip
					value="Showing closed tickets"
					onRemove={() => setQuery((val) => ({ ...val, archived: !val.archived, }))}
					textLimit={40} />
				}
			</div>
		</>
	);

	const Tickets = useMemo(
		() => tickets.map((ticket, i) => (
			<Ticket
				key={ticket.id}
				index={i}
				ticket={ticket} />
		)),
		[ tickets ]
	);

	return (
		<Container
			title="Conversations"
			className="manage-chats"
			pageMiddle={PageMiddle}
			pageControls={PageControls}>

			{loading.readTicketsMange &&
			<div className="users-list--empty">
				<p>Loading tickets...</p>
			</div>
			}

			{!loading.readTicketsMange && !tickets.length &&
			<div className="users-list--empty">
				<p>No tickets found</p>
			</div>
			}

			{!loading.readTicketsMange &&
			<div
				children={Tickets}
				className="users-list" />
			}

			<ModalTicketCreate
				onHide={setCreateModal.bind(null, false)}
				visible={createModal}
				onCreate={create}
				isLoading={loading.createTicket} />

		</Container>
	);

}
