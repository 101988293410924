import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { App } from '~/types';

import { Store } from '../models';
import { useAppDispatch } from './useAppDispatch';

type UseChatArgs =
	App.Endpoints.ReadTicketsManage[0] |
	App.Endpoints.ReadTickets[0]

export const useChats = (id?: string, args?: UseChatArgs) => {

	const [
		auth,
		tickets,
		ticketsQA,
		ticketsById,
		loading,
	] = useSelector(
		({ auth, admin, loading }: Store.Root) => [
			auth.account,
			admin.tickets,
			admin.ticketsQA,
			admin.ticketsById,
			loading.effects.admin
		] as const
	);

	const { admin } = useAppDispatch();

	const { pathname } = useLocation();

	useEffect(() => {

		if (id) {
			return;
		}

		if (auth?.user_type === 4 && (args && 'order' in args)) {
			admin.readTicketsMange(args);
		} else if (auth?.user_type === 2 && (args && !('order' in args))) {
			admin.readTickets(args);
		}

	}, [ id, auth, admin, args ]);

	useEffect(() => {

		id && admin.readTicket(id);

	}, [ admin, id ]);

	const ticket = useMemo(
		() => {

			if (!id) {
				return false as const;
			}

			return ticketsById?.[id];

		},
		[ id, ticketsById ]
	);

	const setChatRead = useCallback(
		async () => {

			if (!id || !auth || !ticketsById?.[id]) {
				return false;
			}

			const ticket = ticketsById[id];
			const isUserView = /^\/manage\//.test(pathname);

			const inboxMessages = ticket.messages.filter(
				(message) => !message.is_read && (
					isUserView ?
						message.sent_by === 'user' :
						message.user_id !== ticket.user_id
				)
			);

			const lastInboxMessage = inboxMessages?.[inboxMessages.length - 1];

			if (!lastInboxMessage) {
				return;
			}

			await admin.setMessageStatus({
				id: lastInboxMessage.id,
				reference_id: id,
			});

		},
		[ id, ticketsById, auth, admin, pathname ]
	);

	return {
		tickets,
		ticket,
		ticketsQA,
		setChatRead,
		...admin,
		loading,
	};

}
