import cn from 'classnames';
import React, { useMemo } from 'react';
import { mediaLink } from '~/utils';

export interface PhotoWrapProps {
	source?: string | File,
	margin?: number,
	onClick?: () => void,
}

export const PhotoWrap: React.FC<PhotoWrapProps> = (props) => {

	const { source, onClick } = props;

	// const onLoad = useCallback(
	// 	(e: NativeSyntheticEvent<ImageLoadEventData>) => {

	// 		setLoading(false);

	// 		const { width, height } = e.nativeEvent.source;

	// 		setAspectRatio(width / height);

	// 	},
	// 	[]
	// );

	const uri = useMemo(
		() => {

			if (!source) {
				return null;
			}

			return mediaLink(source);

		},
		[ source ]
	);

	if (!uri) {
		return null;
	}

	return (
		<div
			onClick={onClick}
			className={cn('app--photo-wrap', { clickable: !!onClick })}>
			<img src={uri} alt='' />
		</div>
	);

}
