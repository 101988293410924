export namespace Chat {

	export type TicketFieldOrder =
		'created_at' |
		'updated_at'

	export type TicketOrder =
		'new-messages-first' |
		'new-tickets-first'

	export type TicketFieldSearch =
		'subject' |
		'user_id' |
		'ticket_id'

	/* FIXME: Refactor
	 */
	export enum TicketStatus {
		new = 'new',
		open = 'open',
		closed = 'closed',
		waiting_response = 'waiting_response',
	}

	export const TICKET_STATUS = [
		{ value: TicketStatus.new, label: 'New', },
		{ value: TicketStatus.open, label: 'Open', },
		{ value: TicketStatus.closed, label: 'Closed', },
		{ value: TicketStatus.waiting_response, label: 'Waiting response', },
	]

	export const TICKET_STATUS_TEXT = {
		[TicketStatus.new]: 'New',
		[TicketStatus.open]: 'Open',
		[TicketStatus.closed]: 'Closed',
		[TicketStatus.waiting_response]: 'Waiting response',
	}
	/* -
	 */

	export interface User {
		id: string,
		email: string,
		phone: string,
		blocked: boolean,
		tenants: string,
		username: string,
		user_type: number,
		last_name: string,
		first_name: string,
		created_at: number,
		updated_at: number,
		profile_photo: string,
		verification_status: boolean,
	}

	export interface Ticket {
		id: string,
		badge: number,
		tenant: string,
		status: Chat.TicketStatus,
		subject: string,
		user_id: string,
		comment: string,
		last_name: string,
		messages: Message[],
		first_name: string,
		created_by: string,
		created_at: number,
		updated_at: number,
		user_profiles: User,
		profile_photo: string,
		last_message_at: number,
	}

	export interface TicketStripped extends Omit<Ticket, 'messages' | 'user_profiles'> {}

	export interface Message {
		id: number,
		text: string,
		assets: string,
		user_id: string,
		sent_by: 'user' | 'admin',
		is_read: boolean,
		sent_id?: string,
		email_sent: boolean,
		created_at: number,
		updated_at: number,
		reference_id: string,
	}

	export interface Asset {
		id: string,
		url: string,
		user_id: string,
		created_at: number,
	}

	export interface Dimensions {
		width: number,
		height: number,
	}

	export type AssetType =
		'image' |
		'video' |
		'application' |
		'audio'

	export interface AssetMeta {
		id: string,
		size: number,
		name: string,
		mime: string,
		type: AssetType,
		extension: string,
		dimensions?: Dimensions,
	}

	export interface QA {
		q: string,
		a: string,
	}

	export type LivePayload =
		{
			type: 'message',
			object: Message,
		} |
		{
			type: 'ticket',
			object: Ticket,
		} |
		{
			type: 'message-read',
			object: Message,
		} |
		{
			type: 'ticket-status-changed',
			object: Ticket,
		}

}
