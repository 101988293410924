import { SquareIcon } from './SquareIcon';

import { IAccount, socialLinksArray } from '~/services';
import { useMemo } from 'react';
import { prependProtocol } from './SocialLink';

interface AthleteLinksProps {
	account: IAccount
}

export const AthleteLinks: React.FC<AthleteLinksProps> = (props) => {

	const { account } = props;

	const links = useMemo(
		() => {

			return socialLinksArray.filter(([ , , key ]) => !!account[key]);

		},
		[ account ]
	);

	if (!links.length) {
		return null;
	}

	return (
		<div className="app--athlete-links">
			{links.map(([ , icon, key ], i) => (
			<SquareIcon
				key={i}
				icon={icon}
				onClick={() => window.open(prependProtocol(account[key]), '_blank')?.focus()} />
			))}
		</div>
	);

}
