import { ChangeEventHandler, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { asArray, getURLSource, mediaLink, normalizePhoto } from '~/utils';

import { Icon } from './Icon';
import { Modal } from './Modal';
import { Crop } from './Crop';
import { Loader } from './Loader';

interface PhotoButtonProps {
	photo?: string | File | File[],
	error?: string,
	isCrop?: boolean,
	isAthlete?: boolean,
	onPhotoChange?: (file: File[]) => void
}

type CropState = {
	url: string,
	ratio: number,
	width: number,
	height: number
}

export const PhotoButton: React.FC<PhotoButtonProps> = (props) => {

	const { photo, error, isCrop, isAthlete, onPhotoChange } = props;

	const [ crop, setCrop ] = useState<CropState | null>(null);

	const [ loading, setLoading ] = useState(false);

	const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
		async ({ target }) => {

			if (!target.files || !target.files[0]) {
				return;
			}

			setLoading(true);

			const file = await normalizePhoto(target.files[0], 1);

			if (isCrop) {

				try {
					setCrop(
						await getURLSource(file)
					);
				} catch (e) {
					alert(e);
				} finally {
					setLoading(false);
				}

				return;

			}

			setLoading(false);

			onPhotoChange && onPhotoChange([ file ]);

		},
		[ isCrop, onPhotoChange ]
	);

	const onCropComplete = useCallback(
		(crops: File[]) => {

			onPhotoChange && onPhotoChange(crops);

			setCrop(null);

		},
		[ onPhotoChange ]
	);

	const fileKey = useMemo(
		() => Math.random().toString(36),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ crop ]
	);

	return (
		<div className={cn('app--photo-button', { error: !!error })}>
			<div className="trigger">
				<div className="photo-container">
					<Loader loading={loading} />
					{photo ? (
					<img
						alt=""
						src={mediaLink(asArray(photo)[0], 'S')}
						className="icon-style app--photo-button-img" />
					) : (
					<div className="icon-style app--photo-button-preview">
						<Icon name="userBig" />
					</div>
					)}
				</div>
				<p className="label">{error ? error : `${photo ? 'Change' : 'Upload'} profile photo`}</p>
				<input
					key={fileKey}
					type="file"
					accept="image/jpeg,image/png"
					onChange={onChange} />
			</div>
			<Modal
				title="Crop your photo"
				visible={!!crop}
				className="crop--modal">
				{crop && (
				<Crop
					src={crop.url}
					ratio={crop.ratio}
					isAthlete={isAthlete}
					onComplete={onCropComplete}
					onCancel={() => setCrop(null)} />
				)}
			</Modal>
		</div>
	);

}
