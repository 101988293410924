import { useAuth } from '~/store';
import { mediaLink } from '~/utils';
import { Legend, LegendProps, SquareIcon } from '~/components';

import {
	IAccount,
	IRequest,
	REQUEST_TYPE,
	REQUEST_STATUS
} from '~/services';

interface RequestPersonProps extends Omit<LegendProps, 'label'> {
	request: IRequest,
	profile: IAccount
}

export const RequestPerson: React.FC<RequestPersonProps> = (props) => {

	const { request, profile, ...args } = props;

	const { account } = useAuth();

	const { type, status } = request;

	return (
		<Legend
			{...args}
			label={`Requested ${account?.user_type !== 2 ? 'from' : 'by'}`}>
			<div className="requested--by">
				<img src={mediaLink(profile.profile_photo, 'S')} alt={`${profile.first_name} ${profile.last_name}`} />
				<div className="content">
					<h5 children={`${profile.first_name} ${profile.last_name}`} />
					<p children={REQUEST_STATUS[status][0]} />
				</div>
				<SquareIcon
					icon={REQUEST_TYPE[type][2]} />
			</div>
		</Legend>
	);

}
