import { pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PageContent, PageTitle } from '~/containers';
import { Button, Collapsable, Legend, MapLocation, MapWrap, QuantitySelector } from '../../components';
import { Athlete, Location, ILocation, isAthlete, RequestType, handleError, ServiceType, LocationType, REQUEST_TYPE, parseProducts } from '../../services';
import { signUpData, SignUpData } from '../../services/signup';
import { useAuth, useData } from '../../store';
import { PartialRecord } from '../../types';

type ServicesForm = Pick<SignUpData, 'appearance' | 'services'> & {
	locations: ILocation[],
}

const initialServices: ServicesForm = {
	...pick(signUpData, 'appearance', 'services'),
	locations: [],
};

export const Services: React.FC = () => {

	const { goBack } = useHistory();

	const { account, retrieve } = useAuth();

	const [ data, setData ] = useState<ServicesForm>(initialServices);

	const { services, locations, appearance } = data;

	const { iap_apple_products } = useData();

	const {
		personal_appearance,
		speaking_engagement,
		training_session,
		endorsement,
		video_chat,
		shoutout,
		camp
	} = services;

	const [ loading, setLoading ] = useState(false);

	const [ map, setMap ] = useState<RequestType | undefined>();

	useEffect(() => {

		if (!isAthlete(account)) {
			return;
		}

		const { athlete_services } = account.athlete_info;

		const appearance = Athlete.parseAppearances(athlete_services.appearance_type);

		setData((data) => ({
			...data,
			appearance,
			services: {
				speaking_engagement: {
					rate: athlete_services.speaking_engagement_rate,
					enabled: athlete_services.speaking_engagement_enabled,
				},
				personal_appearance: {
					rate: athlete_services.personal_appearance_rate,
					enabled: athlete_services.personal_appearance_enabled,
				},
				training_session: {
					rate: athlete_services.training_session_rate,
					enabled: athlete_services.training_session_enabled,
				},
				endorsement: {
					rate: athlete_services.endorsement_rate,
					enabled: athlete_services.endorsement_enabled,
				},
				shoutout: {
					rate: athlete_services.shoutout_rate,
					enabled: athlete_services.shoutout_enabled,
				},
				video_chat: {
					rate: athlete_services.video_chat_rate,
					enabled: athlete_services.video_chat_enabled,
				},
				camp: {
					rate: athlete_services.camp_rate,
					enabled: athlete_services.camp_enabled,
				},
			},
		}));

	}, [ account ]);

	useEffect(() => {

		const getLocations = async () => {

			setLoading(true);

			try {

				const { locations } = await Location.read().promise;

				setLoading(false);

				setData((val) => ({ ...val, locations }));

			} catch (e) {

				setLoading(false);

				handleError(e);

			}

		}

		getLocations();

	}, []);

	const toggleService = useCallback((key: ServiceType) => {

		setData((data) => ({
			...data,
			services: {
				...data.services,
				[key]: {
					...data.services[key],
					enabled: !data.services[key].enabled
				},
			},
		}));

	}, []);

	const changeRate = useCallback((key: ServiceType, rate: number) => {

		setData((data) => ({
			...data,
			services: {
				...data.services,
				[key]: {
					...data.services[key],
					rate,
				},
			},
		}));

	}, []);

	const isValid = useMemo(
		() => {
			return Object.values(services)
				.map(({ enabled }) => enabled)
				.includes(true)
		},
		[ services ]
	);

	const [ locationState, setLocationState ] = useState<{
		creating?: boolean,
		deleting?: string,
	}>({});

	const createLocation = useCallback(
		async (input: LocationType) => {

			try {

				setLocationState((state) => ({
					...state,
					creating: true,
				}));

				const { location } = await Location.create(input).promise;

				setData((val) => ({
					...val,
					locations: [ ...val.locations, location ]
				}));

			} catch (e) {

				handleError(e);

			} finally {

				setLocationState((state) => ({
					...state,
					creating: false,
				}));

			}

		},
		[ setData ]
	);

	const removeLocation = useCallback(
		async (location: ILocation) => {

			try {

				setLocationState((state) => ({
					...state,
					deleting: location.id,
				}));

				await Location.delete(location.id).promise;

				setData((val) => ({
					...val,
					locations: val.locations.filter(({ id }) => id !== location.id),
				}));

			} catch (e) {

				handleError(e);

			} finally {

				setLocationState((state) => ({
					...state,
					deleting: undefined,
				}));

			}

		},
		[]
	);

	const Locations = useMemo(
		() => {

			const _locations: PartialRecord<RequestType, JSX.Element[]> = {};

			locations.forEach((item, i) => {

				if (!_locations[item.location_type]) {
					_locations[item.location_type] = [];
				}

				_locations[item.location_type]?.push(
					<MapLocation
						{...item}
						key={item.id}
						loading={locationState.deleting === item.id}
						onRemove={() => removeLocation(item)} />
				);

			});

			return _locations;

		},
		[ locations, locationState, removeLocation ]
	);

	const submit = async () => {

		try {

			setLoading(true);

			const appearance_type = Athlete.stringifyAppearances(appearance);

			await Athlete.services.update({
				appearance_type,
				camp_rate: camp.rate,
				camp_enabled: camp.enabled,
				shoutout_rate: shoutout.rate,
				shoutout_enabled: shoutout.enabled,
				video_chat_rate: video_chat.rate,
				video_chat_enabled: video_chat.enabled,
				endorsement_rate: endorsement.rate,
				endorsement_enabled: endorsement.enabled,
				training_session_rate: training_session.rate,
				training_session_enabled: training_session.enabled,
				personal_appearance_rate: personal_appearance.rate,
				personal_appearance_enabled: personal_appearance.enabled,
				speaking_engagement_rate: speaking_engagement.rate,
				speaking_engagement_enabled: speaking_engagement.enabled,
			}).promise;

			await retrieve();

			goBack();

		} catch (e) {

			setLoading(false);

			handleError(e);

		}

	}

	if (!account) {
		return null;
	}

	return (
		<>
			<PageTitle
				title="Services"
				onBack={goBack} />
			<PageContent
				pageContentClassName="page--settings">
				<Legend label="Services Offered">
					<Collapsable
						title="In person appearance"
						selected={personal_appearance.enabled}
						setSelected={() => toggleService('personal_appearance')}>
						<QuantitySelector
							{...REQUEST_TYPE.personal_appearance[4]}
							prefix="$"
							amount={personal_appearance.rate}
							onChange={(val) => changeRate('personal_appearance', val)} />
						<Legend label="Travel Parameters">
							{Locations.personal_appearance}
							<Button
								variant="secondary"
								onClick={() => setMap('personal_appearance')}
								loading={locationState.creating}
								label={`Add${Locations.personal_appearance?.length ? ' more' : ''} locations`} />
						</Legend>
					</Collapsable>
					<Collapsable
						title="Shoutouts"
						selected={shoutout.enabled}
						setSelected={() => toggleService('shoutout')}>
						<QuantitySelector
							{...REQUEST_TYPE.shoutout[4]}
							array={parseProducts(iap_apple_products).shoutout?.amount.usd || []}
							prefix="$"
							amount={shoutout.rate}
							onChange={(val) => changeRate('shoutout', val)} />
					</Collapsable>
					<Collapsable
						title="Video Chats"
						selected={video_chat.enabled}
						setSelected={() => toggleService('video_chat')}>
						<QuantitySelector
							{...REQUEST_TYPE.video_chat[4]}
							prefix="$"
							amount={video_chat.rate}
							onChange={(val) => changeRate('video_chat', val)} />
					</Collapsable>
					<Collapsable
						title="Endorsement"
						selected={endorsement.enabled}
						setSelected={() => toggleService('endorsement')}>
						<QuantitySelector
							{...REQUEST_TYPE.endorsement[4]}
							prefix="$"
							amount={endorsement.rate}
							onChange={(val) => changeRate('endorsement', val)} />
					</Collapsable>
					<Collapsable
						title="Camp"
						selected={camp.enabled}
						setSelected={() => toggleService('camp')}>
						<QuantitySelector
							{...REQUEST_TYPE.camp[4]}
							prefix="$"
							amount={camp.rate}
							label="per hour"
							onChange={(val) => changeRate('camp', val)} />
						<Legend label="Travel Parameters">
							{Locations.camp}
							<Button
								variant="secondary"
								onClick={() => setMap('camp')}
								loading={locationState.creating}
								label={`Add${Locations.camp?.length ? ' more' : ''} locations`} />
						</Legend>
					</Collapsable>
					<Collapsable
						title="Training Session"
						selected={training_session.enabled}
						setSelected={() => toggleService('training_session')}>
						<QuantitySelector
							{...REQUEST_TYPE.training_session[4]}
							prefix="$"
							label="per hour"
							amount={training_session.rate}
							onChange={(val) => changeRate('training_session', val)} />
						<Legend label="Travel Parameters">
							{Locations.training_session}
							<Button
								variant="secondary"
								onClick={() => setMap('training_session')}
								loading={locationState.creating}
								label={`Add${Locations.training_session?.length ? ' more' : ''} locations`} />
						</Legend>
					</Collapsable>
					<Collapsable
						title="Speaking Engagements"
						selected={speaking_engagement.enabled}
						setSelected={() => toggleService('speaking_engagement')}>
						<QuantitySelector
							{...REQUEST_TYPE.speaking_engagement[4]}
							prefix="$"
							amount={speaking_engagement.rate}
							onChange={(val) => changeRate('speaking_engagement', val)} />
						<Legend label="Travel Parameters">
							{Locations.speaking_engagement}
							<Button
								variant="secondary"
								onClick={() => setMap('speaking_engagement')}
								loading={locationState.creating}
								label={`Add${Locations.speaking_engagement?.length ? ' more' : ''} locations`} />
						</Legend>
					</Collapsable>
				</Legend>
				<Button
					loading={loading}
					disabled={!isValid}
					disabledDeep
					onClick={submit}
					variant="primary"
					label="Save services" />
				<p
					className="message"
					children="You have to choose at least one type of service" />
				<MapWrap
					onClose={() => setMap(undefined)}
					isVisible={!!map}
					locationType={map}
					onLocationCreate={(location) => {
						createLocation(location)
						setMap(undefined);
					}} />
			</PageContent>
		</>
	);

}
