import { useEffect, useRef, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { App } from '~/types';
import { PageParam } from '~/utils';
import { useSentPayouts } from '~/store';
import { Pages, SquareIcon } from '~/components';

export const SentPayouts: React.FC = () => {

	const wrapRef = useRef<HTMLDivElement>(null);

	const [ page, setPage ] = useQueryParam('page', PageParam);

	const [ payout, setPayout ] = useState<App.Manage.Payout | null>(null);

	const { sentPayouts } = useSentPayouts(page);

	useEffect(() => {

		wrapRef.current?.scrollTo(0, 0);

	}, [ sentPayouts ]);

	if (payout) {
		return (
			<div className="manage-content payouts--page">
				<div className="page-header">
					<div className="page-header-flex-wrap">
						<h1><SquareIcon onClick={() => setPayout(null)} icon="arrowBack" /> Payout</h1>
						<p className="amount">${payout.total_amount}</p>
					</div>
				</div>
				<div className="page-body">
					<table>
						<thead>
							<tr>
								<td>Name</td>
								<td>Mobile</td>
								<td>Email</td>
								<td>Date</td>
								<td>Amount</td>
							</tr>
						</thead>
						<tbody>
							{payout.items_view?.map((athlete) => (
							<tr key={athlete.id}>
								<td>{athlete.athlete_name}</td>
								<td>{athlete.receiver}</td>
								<td>{athlete.athlete_email}</td>
								<td>{dayjs(athlete.created_at * 1000).format('MMM D, YYYY h:mm A')}</td>
								<td>${athlete.amount}</td>
							</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={4} style={{ textAlign: 'right', paddingRight: 10 }}>Total amount</td>
								<td>${payout.total_amount}</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		);
	}

	return (
		<div ref={wrapRef} className="manage-content">
			<div className="page-header payouts--header">
				<h1>Sent Payouts</h1>
				<Link
					to="/manage/payouts"
					children="Go to pending payouts" />
			</div>
			<div className="users-list">
				{sentPayouts.payouts_list.map((payout) => (
				<div
					key={payout.id}
					onClick={() => setPayout(payout)}
					className="request-list--item">
					<SquareIcon icon="description" />
					<div className="request-item--content">
						<h4>{payout.id}</h4>
						<p>PayPal ID: <b className="paypal-id">{payout.pay_pal_payout_id || 'Unknown'}</b></p>
						<p>{dayjs(payout.created_at * 1000).format('MMM D, YYYY h:mm A')}</p>
					</div>
					<div className="request-item--amount">
						${payout.total_amount}
					</div>
				</div>
				))}
			</div>
			<div className="page-footer">
				<Pages
					forcePage={page}
					pageCount={sentPayouts.total_pages}
					initialPage={page}
					onPageChange={({ selected }) => setPage(selected)} />
			</div>
		</div>
	);

}
