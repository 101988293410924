import { Button, Modal, ModalProps } from '~/components';
import { App } from '~/types';

export interface ModalTicketAvailabilityProps extends ModalProps {
	data?: App.Endpoints.CreateTicket[1],
	onConfirm: (data: App.Endpoints.CreateTicket[1]) => void,
}

export const ModalTicketAvailability: React.FC<ModalTicketAvailabilityProps> = (props) => {

	const { data, onConfirm, ...args } = props;

	if (!data) {
		return null;
	}

	return (
		<Modal
			icon="ticketShield"
			title="Support Availability"
			className="modal_support-availability form-modal"
			{...args}>
			<>
				<p className="availability-message">{data.availability_text}</p>
				<div className="actions">
					<Button
						label="I understand"
						onClick={onConfirm.bind(null, data)}
						variant="primary" />
				</div>
			</>
		</Modal>
	);

}
