import { PropsWithChildren, ReactElement } from 'react';
import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';

// Experimental
import { usePaginated } from '~/hooks';

interface PageContentProps<T> {
	data?: T[],
	loading?: boolean,
	loadingComponent?: ReactElement,
	renderItem?: (item: T, key: number) => ReactElement,
	pageContentHeader?: React.ReactNode,
	pageContentHeaderClassName?: string
	pageContentClassName?: string,
	pageEmptyComponent?: ReactElement
}

// Experimental
interface PageListProps<T> {
	data: T[],
	renderItem: (item: T, key: number) => ReactElement,
}

// Experimental
// eslint-disable-next-line
const PageList = <T,>(props: PageListProps<T>) => {

	const { data: inputData, renderItem } = props;

	const { data, hasMore, getMore } = usePaginated(inputData);

	return (
		<InfiniteScroll
			next={getMore}
			loader="Loading..."
			hasMore={hasMore}
			dataLength={data.length}>
			<div>
				{data.map(renderItem)}
			</div>
		</InfiniteScroll>
	);
}

export const PageContent = <T,>(props: PropsWithChildren<PageContentProps<T>>) => {

	const {
		data,
		loading,
		loadingComponent,
		renderItem,
		pageContentClassName,
		pageContentHeader,
		pageContentHeaderClassName,
		pageEmptyComponent,
		children
	} = props;

	const empty = !loading && !data?.length && !children;

	return (
		<div className={cn('app--page-content', { empty, })}>

			{pageContentHeader &&
			<div
				children={pageContentHeader}
				className={cn(
					'app--page-content-header',
					pageContentHeaderClassName,
				)} />
			}

			{empty &&
			<div
				children={pageEmptyComponent}
				className={cn('app--page-content-empty')} />
			}

			{loading && loadingComponent ? (
			<div
				children={loadingComponent}
				className={cn('app--page-content-inner')} />
			) : (
			// Move class names here
			<div className={cn('app--page-content-inner', pageContentClassName)}>
				{(!data || !renderItem) ? children : data.map(renderItem)}
				{/* Experimental */}
				{/* {(!data || !renderItem) ? children : <PageList data={data} renderItem={renderItem} />} */}
			</div>
			)}

		</div>
	);

}
