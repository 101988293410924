import chroma from 'chroma-js';
import { TenantConfig } from './types';
import {
	parseColor,
	getFontLink,
	createHeadLink,
	createHeadMeta,
	formatCamelCase,
	getMaxContrast,
} from './utils';

const publicUrl = process.env.PUBLIC_URL;
const tenantDomain = process.env.REACT_APP_TENANT_DOMAIN;

export const getCurrentTenant = (tenants: TenantConfig[]): [TenantConfig, number] => {

	let apiURLKey: number = 0;
	const hostname = tenantDomain || window.location.hostname;
	const tenant = tenants.find(
		({ domain }) => domain.some(
			(name, i) => {

				const isMatched = typeof name === 'string' ?
					name === hostname :
					name.test(hostname);

				apiURLKey = i;
				return isMatched;

			}
		)
	) || tenants[0];

	const {
		skin = [],
		logo,
		font,
		title,
		appId,
		colors,
		description,
	} = tenant;

	const bodyStyles = document.documentElement.style;
	const bodyClasses = document.querySelector('html')!.classList;

	skin.forEach((className) => {
		bodyClasses.add(`skin-${formatCamelCase(className)}`);
	});

	Object.entries(colors)
		.forEach(([ name, color ]) => {

			const _name = formatCamelCase(name);

			if (Array.isArray(color)) {

				bodyClasses.add(`has-${_name}-color`);
				bodyClasses.remove(`bl-${_name}`);

				const target = _name.match(/primary|secondary/i)?.[0];
				const maxContrast = getMaxContrast(color);

				if (maxContrast < 7) {
					bodyClasses.add(`bl-btn-${target}`);
				}

				return void color.forEach((_color, key) => {

					parseColor(_color).forEach(([ param, value ]) => {
						bodyStyles.setProperty(`--color-${_name + key}-${param}`, value);
					});

				});

			}

			if (['primary','secondary'].includes(_name)) {
				const contrast = chroma.contrast(color, 'white');
				if (contrast < 3.9) {
					bodyClasses.add(`bl-${_name}`);
					if (_name === 'primary' && !colors.buttonPrimary) {
						bodyClasses.add(`bl-btn-primary`);
					}
					if (_name === 'secondary' && !colors.buttonSecondary) {
						bodyClasses.add(`bl-btn-secondary`);
					}
				}
			}

			parseColor(color).forEach(([ param, value ]) => {
				bodyStyles.setProperty(`--color-${_name}-${param}`, value);
			});

		});

	if (font) {
		bodyStyles.setProperty('--font-family', font.fontFamily);
		const fontLink = getFontLink(font);
		createHeadLink({ rel: 'stylesheet', type: 'text/css', href: fontLink });
	}

	createHeadMeta('theme-color', colors.primary);
	createHeadMeta('description', description);
	createHeadMeta('apple-mobile-web-app-title', title);
	createHeadMeta('apple-mobile-web-app-status-bar-style', 'black-translucent');

	if (logo) {
		const { folder, faviconName } = logo;
		const baseUrl = `${publicUrl}/logo/${folder}/`;
		createHeadLink({ rel: 'apple-touch-icon', href: `${baseUrl}apple-touch-icon.png` });
		createHeadLink({ rel: 'apple-touch-icon', href: `${baseUrl}apple-touch-icon-180x180.png` });
		faviconName &&
			createHeadLink({ rel: 'icon', type: 'image/x-icon', href: `${baseUrl}${faviconName}` });
	}

	if (appId) {
		createHeadMeta('apple-itunes-app', `app-id=${appId}`);
	}

	window.document.title = title;

	return [ tenant, apiURLKey ];

}
