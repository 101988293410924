import { Button, Modal, ModalProps } from '~/components';
import { App } from '~/types';

export interface ModalTicketQAProps extends ModalProps {
	data: App.Chat.QA[],
	onCreate?: () => void,
}

export const ModalTicketQA: React.FC<ModalTicketQAProps> = (props) => {

	const { data, onCreate, ...args } = props;

	return (
		<Modal
			icon="ticketShield"
			title="Q/A"
			className="modal_qa form-modal"
			{...args}>
			<>
				{data.map((qa, i) => (
				<div key={i} className="qa__item">
					<h4>{qa.q}</h4>
					<p>{qa.a}</p>
				</div>
				))}
				<div className="actions">
					<Button
						label="Start conversation"
						variant="primary"
						onClick={onCreate} />
					<Button
						type="button"
						label="Cancel"
						onClick={args.onHide} />
				</div>
			</>
		</Modal>
	);

}
