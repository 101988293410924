import { useData } from '~/store';

export const AnalyticsSessions: React.FC = () => {

	const { grafana_sessions_url } = useData();

	return (
		<div className="manage-content frame">
			{!!grafana_sessions_url &&
			<iframe src={grafana_sessions_url} title="Grafana Sessions Statistics" />
			}
		</div>
	);

}
